import { Navigate, RouteObject } from 'react-router-dom';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import ReferFriendPage from '@pages/Client/ReferFriend/pages/ReferFriend';
import TrackReferralPage from '@pages/Client/ReferFriend/pages/TrackReferral';

export const referFriendRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="refer-friend" /> },
  {
    path: 'refer-friend',
    element: (
      <SecureRoute>
        <ReferFriendPage />
      </SecureRoute>
    )
  },
  {
    path: 'track-referral',
    element: (
      <SecureRoute>
        <TrackReferralPage />
      </SecureRoute>
    )
  }
];
