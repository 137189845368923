import { getHotelUsers } from '@common/api/account';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useGetHotelUsers = () => {
  const query = useQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_USERS],
    queryFn: getHotelUsers
  });

  return {
    query,
    hotelUsers: query.data
  };
};
