import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { Fragment, useEffect, useState } from 'react';
import { Returns } from 'use-lilius';
import { Typography } from '@common/components/foundations/Typography';
import { PricingSettings } from '@common/api/hotel/types';
import { GetCachedRoomPricesResponse } from '@common/api/roomPrices/types';
import { PricingCellContent } from '@pages/Client/Calendar/pages/Pricing/components/PricingCellContent';
import { useViewStore } from '@common/store/view';
import { useTranslation } from 'react-i18next';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { AdjustmentType, CALENDAR_PAGES } from '@pages/Client/Calendar/constants';
import { getWeek } from 'date-fns';
import { cn } from '@common/utils/cn';
import { TableView } from '@pages/Client/Calendar/components/BigCalendar/TableView';
import { isEmpty, isNumber } from 'lodash-es';
import { useSidebarStore } from '@common/store/sidebar';
import { DateCell } from '@pages/Client/Calendar/components/BigCalendar/DateCell';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { useMinStayVisualiser } from '@pages/Client/Calendar/hooks/useMinStayVisualiser';
import { ActionIcon, Box, Flex, ScrollArea, SimpleGrid, Tooltip } from '@mantine/core';
import { useDate } from '@common/hooks/useDate';

dayjs.extend(isBetween);

const isDateBetween = (date: Date, start: Date, end: Date): boolean => {
  return dayjs(date)
    .startOf('day')
    .isBetween(dayjs(start).startOf('day'), dayjs(end).startOf('day'), null, '[]');
};

export interface CalendarViewProps {
  liliusInstance: Returns;
  isLoading?: boolean;

  surgeEvent?: (date: Date) => {
    isSurgeEvent: boolean;
    hasSurgePrice: boolean;
  };
  topRightCellContent?: (date: Date) => React.ReactNode;
  dateCellClassName?: (date: Date) => string;
  dateCellContent?: (date: Date) => React.ReactNode;

  onCellClick?: (d: Date) => void;
  onMultipleCellsSelect?: (startDate: Date, endDate: Date) => void;

  context?: 'pricing' | 'occupancy' | 'pickup' | 'revenue';

  pricingSettings?: PricingSettings;
  selectedRoomId?: number;
  roomPrices?: GetCachedRoomPricesResponse;

  tooltipExtraContent?: (date: Date) => {
    title: string;
    breakdownContent: { leftText: React.ReactNode; rightText: React.ReactNode }[];
  }[];

  getPricingCellContent?: (
    date: Date
  ) => React.ComponentProps<typeof PricingCellContent>['content'];
}

export const CalendarView = (props: CalendarViewProps) => {
  const { liliusInstance: lilius, onMultipleCellsSelect, onCellClick } = props;
  const { setSidebarOpen } = useSidebarStore();
  const { t } = useTranslation();
  const {
    isTableView,
    weekIndex: weekIndexFromStore,
    setIsTableView,
    setWeekIndex,
    adjustment,
    setMinStayDays
  } = useCalendarPageStore();
  const { trackEvent } = useEventTracking();
  const { minStaysVisualiser } = useMinStayVisualiser();
  const { getNowInHotelTimezone, convertToHotelTimezone } = useDate({ isHotelAccount: true });

  useEffect(() => {
    if (adjustment !== AdjustmentType.MinStay) return;

    setMinStayDays(minStaysVisualiser(props.selectedRoomId));
  }, [adjustment, props.selectedRoomId, isTableView]);

  const { view } = useViewStore();
  const { features } = useFeaturesStore();
  const { calendar } = lilius;

  const [startCell, setStartCell] = useState<Date | null>(null); // Since you are using dates as strings
  const [endCell, setEndCell] = useState<Date | null>(null);

  const onMouseLeave = () => {
    if (startCell && endCell) {
      setStartCell(null);
      setEndCell(null);
    }
  };

  const onMouseDown = (date: Date) => {
    setMinStayDays({});
    if (!isPastDate(date)) {
      setStartCell(date);
      setEndCell(date);
    }
  };

  const onMouseEnter = (date: Date) => {
    if (!isPastDate(date) && startCell) {
      setEndCell(date);
    }
  };

  const onMouseUp = () => {
    if (startCell && endCell && !isPastDate(startCell) && !isPastDate(endCell)) {
      let startDate: Date = startCell;
      let endDate: Date = endCell;

      // Swap dates if startCell is after endCell
      if (convertToHotelTimezone(startCell).isAfter(convertToHotelTimezone(endCell))) {
        startDate = endCell;
        endDate = startCell;
      }

      // Check if start and end dates are the same (single day selection)
      if (convertToHotelTimezone(startDate).isSame(convertToHotelTimezone(endDate), 'day')) {
        // Handle single day selection logic here
        lilius.toggle(startDate, true);
        onCellClick?.(startDate);

        // If you have any other actions for single day selection, trigger them here.
      } else {
        // Inform parent about the range selection
        onMultipleCellsSelect?.(startDate, endDate);
      }
    }
    // Clear selection
    setStartCell(null);
    setEndCell(null);
  };

  const isSelected = (date: Date) => {
    if (startCell && endCell) {
      return isDateBetween(date, startCell, endCell);
    }
    return false;
  };

  const isPastDate = (date: Date): boolean => {
    const now = getNowInHotelTimezone().startOf('day');
    const compareDate = convertToHotelTimezone(date).startOf('day');
    return compareDate.isBefore(now);
  };

  return (
    <ScrollArea offsetScrollbars="x" mt="md" w="100%">
      <Box miw="920px">
        <Flex gap="xs">
          {(view === 'admin' || features?.includes(Feature.UploadAllRuns)) &&
          !isTableView &&
          props.context &&
          [CALENDAR_PAGES.PRICING].includes(props.context) ? (
            <SimpleGrid spacing={0} pt="xl" className="place-items-center">
              {calendar[0].map((week, weekIndex) => (
                <Tooltip
                  key={`weekNumber-${weekIndex}`}
                  openDelay={300}
                  label={t('View Week')}
                  position="top">
                  <ActionIcon
                    variant="white"
                    fz="sm"
                    onClick={() => {
                      setIsTableView(true);
                      setWeekIndex(weekIndex);
                      setSidebarOpen(false);
                      trackEvent('WeekViewOpenedFromWeekNumber');
                    }}>
                    {getWeek(week[weekIndex])}
                  </ActionIcon>
                </Tooltip>
              ))}
            </SimpleGrid>
          ) : null}
          <Box w="100%" className="w-full">
            <SimpleGrid spacing={0} cols={7}>
              {isTableView
                ? null
                : calendar[0][0].map((day) => {
                    return (
                      <Typography key={`week-weekdays-${day}`} className="ml-4">
                        {dayjs.weekdaysMin()[dayjs(day).get('day')]}
                      </Typography>
                    );
                  })}
            </SimpleGrid>

            {(view === 'admin' || features?.includes(Feature.UploadAllRuns)) &&
            isTableView &&
            isNumber(weekIndexFromStore) &&
            props.context &&
            [CALENDAR_PAGES.PRICING].includes(props.context) ? (
              <TableView
                key={`table-view-week-${weekIndexFromStore ?? 0}`}
                weeksInCalendar={calendar[0]}
                week={calendar[0][weekIndexFromStore]}
              />
            ) : (
              <SimpleGrid spacing={0} cols={7} onMouseLeave={onMouseLeave}>
                {!isEmpty(calendar)
                  ? calendar[0].map((week, weekIndex) => (
                      <Fragment key={`calendarView-${week}-${weekIndex}`}>
                        {week.map((day, dayIndex) => (
                          <div
                            className={cn('flex flex-col')}
                            key={`datecell-weekday-${week}-${day}`}>
                            <DateCell
                              {...props}
                              day={day}
                              isLoading={props.isLoading}
                              selectedRoomId={props.selectedRoomId}
                              onMouseDown={() => onMouseDown(day)}
                              onMouseUp={onMouseUp}
                              onMouseEnter={() => onMouseEnter(day)}
                              weekIndex={weekIndex}
                              calendarLength={calendar[0].length}
                              dayIndex={dayIndex}
                              selected={isSelected(day)}
                            />
                          </div>
                        ))}
                      </Fragment>
                    ))
                  : null}
              </SimpleGrid>
            )}
          </Box>
        </Flex>
      </Box>
    </ScrollArea>
  );
};
