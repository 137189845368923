import { map, sortBy, startCase } from 'lodash-es';

export const pmsLookup: Record<number, string> = {
  1: 'Apaleo',
  2: 'Mews',
  3: 'Booking Factory',
  4: 'Little Hotelier',
  5: 'SiteMinder',
  7: 'Clock System',
  10: 'Hotel Spider(Deprecated)',
  15: 'Protel',
  14: 'Cover',
  20: 'Channex',
  21: 'Globeres',
  22: 'Guestline',
  23: 'Bocco',
  24: 'seekom',
  25: 'Beds24(Deprecated)',
  26: 'Booking Automation Partner',
  27: 'RMS',
  28: 'Web Hotelier',
  29: 'kognitiv',
  30: 'Channex Wubook',
  34: 'Vertical Booking',
  35: 'cultswitch',
  37: 'SiteMinder RMS API',
  38: 'Semper',
  39: 'MappingMaster',
  40: 'Ibelsa',
  41: 'RoomRaccoon',
  42: 'Guesty',
  44: 'Reconline',
  45: 'D-edge',
  46: 'eviivo',
  47: 'Ghix',
  48: 'OneHotel',
  49: 'HLS',
  50: 'Cubilis',
  51: 'Roomcloud',
  52: 'Freetobook',
  53: 'Profitroom',
  54: 'Zimmersoftware',
  57: 'Noovy',
  58: 'Dirs21',
  59: 'Hotsoft',
  60: 'Stayntouch',
  61: 'OperaCloud',
  62: 'Hotelavailabilities',
  63: 'RateTiger',
  64: 'SiteMinder New',
  65: 'SiteMinder RMS API New',
  66: 'Thais',
  68: 'Resly',
  69: 'Wincloud',
  70: 'Cloudbeds',
  71: 'SabeeApp',
  72: 'Newbook',
  73: 'STAAH',
  74: 'Anand System',
  76: 'Rezcontrol',
  77: 'RoomMaster Anywhere',
  78: 'Hotline',
  79: '3RPMS',
  80: 'CASABLANCA',
  82: 'Lobbi',
  83: 'Hotel Spider',
  84: 'Beds24',
  85: 'WebRezPro',
  86: 'HS/3'
};

export const pmsProviderListForOccupancyBasedPricing = [
  10, 15, 29, 40, 23, 44, 47, 28, 53, 39, 45, 50, 58, 60, 61, 35, 63, 69, 78, 77, 80, 83, 85, 86, 59
];

export const pmsProviderListForDerivedRates = [1, 54, 58, 40, 60, 63, 46, 73, 83, 2, 59];

export const pushPmsList = [
  15, 34, 22, 41, 45, 46, 48, 49, 50, 52, 53, 54, 57, 58, 56, 63, 67, 69, 73, 74, 76, 77, 78, 80,
  83, 85, 86
];

export const pmsHasRoomType = [15, 29, 41, 22, 34, 45, 53, 69, 64, 80, 77, 86];
export const pmsHasRegionField = [4, 5, 15, 22, 27, 60, 61, 64, 65, 66, 72];

export const pmsHasRoomRate = [15, 41, 22, 34, 45, 69, 64, 85, 77, 86];
export const pmsHasUpdateRate = [45, 22, 78];
export const pmsHasInvTypeCode = [15, 45, 77, 86];
export const pmsHasOutExtraColumns = [15, 45, 64, 77, 86, 22];
export const pmsHasRoomTypesColumn = [15, 64, 77, 86];
export const pmsHasRequestFullSync = [52, 46, 71, 73, 79];

export const pmsList = Object.entries(pmsLookup)
  .map(([key, value]) => ({
    label: `${value}${import.meta.env.DEV ? ` - ${key}` : ''}`,
    value: parseInt(key)
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export const pmsListSelect = sortBy(
  map(pmsLookup, (value, key) => ({
    value: String(key),
    label: startCase(value)
  })),
  ['label'],
  ['asc']
);
