import { referFriend, referFriendList } from '@common/api/hotel';
import { ReferFriendPayload } from '@common/api/hotel/types';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useReferFriend = () => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: ReferFriendPayload) => {
      return referFriend(data);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully refer friend');
    },
    onError: () => {
      addNotification('fail', 'Failed to send friend referral');
    }
  });

  return {
    referFriend: mutate,
    isLoading
  };
};

export const useReferFriendList = () => {
  const { hotelAuthToken } = useHotelStore();

  const { data, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return referFriendList(hotelAuthToken as string);
    }
  });

  return {
    referFriendList: data,
    isLoading
  };
};
