import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdminAccessFeatures, Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { useEffect } from 'react';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

interface SecureRouteProps {
  children: React.ReactNode;
  feature?: number | number[];
}

export const SecureRoute: React.FC<SecureRouteProps> = ({ children, feature }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hotelDetails } = useHotelDetails();
  const { features } = useFeaturesStore();
  const { view } = useViewStore();
  const { id } = useParams();
  const { hasReportingFeature } = useFeaturesStore();
  const subdomain = window.location.hostname.split('.')[0];
  const isIframe = subdomain.includes('iframe');

  const isFreeTrialExpired = hotelDetails?.state === 3;
  const isUnsubscribe = hotelDetails?.state === 7;
  const isCancelled = hotelDetails?.state === 2;
  const isSubscriptionPaused = hotelDetails?.state === 9;

  useEffect(() => {
    if (
      view === 'client' &&
      (isFreeTrialExpired || isUnsubscribe || isCancelled || isSubscriptionPaused)
    ) {
      navigate(`/client/account/${id}/payment-settings`);
    }
  }, [view, isFreeTrialExpired, isUnsubscribe, isCancelled, isSubscriptionPaused, navigate, id]);

  if (isIframe && location.pathname.includes('profile-settings')) {
    return <Navigate to={`/client/account/${id}/hotel-settings/`} />;
  }

  // Check if feature 19 is present and view is 'client', then no access.
  if (view === 'client' && features?.includes(19)) {
    return <Navigate to={`/client/no-access/${id}`} />;
  }

  // Do not render dashboard for admin view if dashboard is not enabled
  if (location.pathname.includes('dashboard') && view === 'admin' && !hasReportingFeature()) {
    return null;
  }

  // If there are no required featureFlags, render the component.
  if (!feature) {
    return <>{children}</>;
  }

  // Make sure featureFlag is an array.
  const featureFlagArray = Array.isArray(feature) ? feature : [feature];

  // Check if any required feature is in the featureFlags array.
  const hasAccess = featureFlagArray.some((flag) => features?.includes(flag));

  // Check if any feature has admin access
  const hasAdminAccess = featureFlagArray.some((feature) =>
    AdminAccessFeatures.has(feature as Feature)
  );

  if (view === 'admin' && (hasAccess || hasAdminAccess)) {
    return <>{children}</>;
  }

  return hasAccess ? <>{children}</> : null;
};
