import { pmsHasInvTypeCode } from '@common/constants/pmsList';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { useHotelStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, NumberInput, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { useCreatePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useCreateRoomTypeStore } from '@pages/Client/Account/Integrations/store/createRoomType';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
};

function createSchema(pmsProviderId: number) {
  const occupancySchema = z
    .union([
      z.string({
        invalid_type_error: 'Please enter a number'
      }),
      z.number({
        invalid_type_error: 'Please enter a number'
      })
    ])
    .refine((val) => Number(val) > 0, {
      message: 'Value should be greater than 0'
    });

  const schema = z.object({
    name: z.string().trim().min(1, { message: 'Required' }),
    room_id: z.string().trim().min(1, { message: 'Required' }),
    max_occupancy:
      pmsProviderId === PmsProvider.CASABLANCA ? occupancySchema : occupancySchema.optional(),
    min_occupancy:
      pmsProviderId === PmsProvider.CASABLANCA ? occupancySchema : occupancySchema.optional(),
    default_occupancy:
      pmsProviderId === PmsProvider.GUESTLINE ? occupancySchema : occupancySchema.optional()
  });

  return schema.refine(
    (data) => {
      if (data.min_occupancy && data.max_occupancy) {
        return Number(data.min_occupancy) <= Number(data.max_occupancy);
      }
      return true;
    },
    {
      message: 'Cannot higher than max_occupancy',
      path: ['min_occupancy']
    }
  );
}

export const AddNewRoomTypeModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { hotelAuthToken } = useHotelStore();
  const { setCreateLoading } = useCreateRoomTypeStore();
  const { createRoomType, isLoading } = useCreatePmsRoomType();
  const roomApartmentSpaceName = hotelDetails?.room_apartment_space_name;
  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;
  const schema = createSchema(pmsProviderId);
  const asteriskSymbol = pmsProviderId === PmsProvider.CASABLANCA;

  const roomName = pmsHasInvTypeCode.includes(pmsProviderId)
    ? t('InvTypeCode')
    : t(`${roomApartmentSpaceName} Id`);

  const { handleSubmit, control, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (isLoading) {
      setCreateLoading(true);
    } else {
      setCreateLoading(false);
    }
  }, [isLoading]);

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      token: hotelAuthToken
    };
    await createRoomType(payload);
    reset();
    onClose();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={t(`Create New ${hotelDetails?.room_apartment_space_name} Type`)}
      size="auto"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px="md" pb="lg" gap="md">
          <SimpleGrid cols={2}>
            <Controller
              name="room_id"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={t(`Please enter ${roomName}`) as string}
                  error={error?.message}
                  variant="filled"
                  label={t(`${roomName}`)}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter name')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Room Type Name')}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                />
              )}
            />
          </SimpleGrid>
          {pmsProviderId === PmsProvider.GUESTLINE ? null : (
            <SimpleGrid cols={2}>
              <Controller
                name="min_occupancy"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <NumberInput
                    placeholder={`${t('Please enter min occupancy')}`}
                    error={error?.message}
                    variant="filled"
                    label={t('Min Occupancy')}
                    value={value}
                    onChange={onChange}
                    withAsterisk={asteriskSymbol}
                    hideControls
                  />
                )}
              />
              <Controller
                name="max_occupancy"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <NumberInput
                    placeholder={`${t('Please enter max occupancy')}`}
                    error={error?.message}
                    variant="filled"
                    label={t('Max Occupancy')}
                    value={value}
                    onChange={onChange}
                    withAsterisk={asteriskSymbol}
                    hideControls
                  />
                )}
              />
            </SimpleGrid>
          )}
          {pmsProviderId === PmsProvider.GUESTLINE ? (
            <Controller
              name="default_occupancy"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <NumberInput
                  placeholder={`${t('Please enter default occupancy')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Default Occupancy')}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                  hideControls
                />
              )}
            />
          ) : null}
        </Stack>
        <ModalFooter>
          <Button onClick={handleClose} variant="subtle">
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isLoading}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
