import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useFormSubmitStore } from '@pages/Client/PricingStrategy/MinStay/store/formSubmitStore';
import { useEffect } from 'react';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useBanners } from '@common/store/banner';
import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';

export const MinStay = () => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const triggerSubmit = useFormSubmitStore((state) => state.triggerSubmit);
  const { features, hasMinStayFeature } = useFeaturesStore();
  const { createBanner, removeBanner } = useBanners();

  const isMews = hotelDetails?.pms_provider === PmsProvider.MEWS;

  useEffect(() => {
    if (!hotelDetails) return;
    if (
      features?.includes(Feature.LimitedMinimumStayRestrictions) &&
      !features?.includes(Feature.MinimumStayRestrictions)
    ) {
      navigate(`/client/min-stay/${hotelDetails.id}/orphan-gap-correction`, { replace: true });
    }
  }, [features, hotelDetails, pathname, navigate]);

  useEffect(() => {
    if (isMews && hasMinStayFeature()) {
      createBanner({
        id: 'min-stay-mews-announcement',
        description: (
          <div>
            <span>
              <strong>{t('Please note:')}</strong>{' '}
            </span>
            {t(
              'Our integration with Mews does not allow us to overwrite the minimum stay restrictions that you have set in Mews. This means that Orphan Gap correction and other automation features will not work on these restrictions. To fully benefit from Minimum Stay Restrictions in RoomPriceGenie, we recommend removing any existing restrictions in Mews and setting all minimum stay restrictions directly through RoomPriceGenie.'
            )}
          </div>
        ),
        type: 'info',
        showAction: false
      });
      return () => removeBanner('min-stay-mews-announcement');
    }
  }, [isMews, hotelDetails, hasMinStayFeature]);

  const subMenuLink = [
    ...(features?.includes(Feature.MinimumStayRestrictions)
      ? [
          {
            id: 'automatic-removal',
            label: t('Automatic Removal'),
            to: 'removal'
          }
        ]
      : []),
    ...(hasMinStayFeature()
      ? [
          {
            id: 'orphan-gap-correction',
            label: t('Orphan Gap Correction'),
            to: 'orphan-gap-correction'
          }
        ]
      : [])
  ];

  return (
    <Page
      header={
        <Header
          title={t('Minimum Stay Restrictions')}
          actions={
            <Button disabled={pathname.includes('removal')} onClick={() => triggerSubmit()}>
              {t('Save')}
            </Button>
          }
          tabs={subMenuLink}
        />
      }>
      <Outlet />
    </Page>
  );
};
