import { hotelTracktime } from '@common/api/account';
import { setAuthToken as setAxiosAuthToken } from '@common/api/axiosInstance';
import { visitorIdentification } from '@common/api/hotel';
import { Badge } from '@common/components/atoms/Badge';
import { Icon } from '@common/components/foundations/icons';
import ApplicationShell from '@common/components/organisms/ApplicationShell';
import { useAuthStore, useHotelStore } from '@common/store/auth';
import { useBanners } from '@common/store/banner';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { getPlanTier } from '@common/utils/getPlanTier';
import { getPlanType } from '@common/utils/getPlanType';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { FC, Suspense, SVGProps, useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { usePusherStore } from '@common/store/pusher';
import { usePaymentInformation } from '@pages/Client/hooks/usePaymentInformation';
import { FullscreenLoading } from '@pages/Client/components/FullscreenLoading';
import { useAppCuesScript } from '@pages/Client/hooks/useAppCues';
import { useChatBot } from '@pages/Client/hooks/useChatBot';
import { useGetAccountHotel } from '@pages/Client/hooks/useGetAccountHotel';
import { useGetCurrentUTCTime } from '@pages/Client/hooks/useGetCurrentUTCTime';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { Drawer } from '@common/components/molecules/Drawer/Drawer';
import { PriceDrawerContent } from '@pages/Client/Calendar/components/PriceDrawerContent/PriceDrawerContent';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { usePriceDrawerStore } from '@common/store/priceDrawer';

import { HotelState } from '@pages/Client/hooks/useSubscription';
import { DATE_FORMAT_WITH_DAY } from '@common/constants/date';
import { localeDateFormat } from '@pages/Client/Calendar/utils/localeDateFormat';
import { formatDateWithoutTime } from '@common/utils/formatDateWithoutTime';
import { useUploadPMSPriceModalState } from '@pages/Client/Calendar/components/BulkEdit/store/useUploadPMSPriceModalState';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { isEmpty, isNil, map, replace, size, take } from 'lodash-es';
import {
  BulkEditTab,
  useBulkEditsStore
} from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { useSurgeManageModal } from '@common/store/surgeModal';
import { useTrackingProperties } from '@common/hooks/useEventTracking';
import { isDevelopment } from '@common/utils/env';
import { useDocumentTitle, useDocumentVisibility } from '@mantine/hooks';
import { Box, Flex, Button } from '@mantine/core';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { usePostHog } from 'posthog-js/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { TrackingQueryKeys } from '@common/types/query-keys';
import userflow from 'userflow.js';
import { pmsLookup } from '@common/constants/pmsList';
import { IconFileCode } from '@tabler/icons-react';
import { useAIChatBot } from '@pages/Client/hooks/useAIChatBot';
import { useUserLocale } from '@common/hooks/useUserLocale';
import { NavUserflowIds } from '@common/types/userflow-ids';
import { ldIdentify } from '@common/helpers/ld-identify';

interface RefreshParams {
  openToNewThread?: boolean;
  callback?: () => void;
}

declare global {
  interface Window {
    Appcues?: {
      show(): unknown;
      page(): unknown;
      identify: (accountId: string, properties: object) => void;
    };
    HubSpotConversations?: {
      widget?: {
        open(): void;
        refresh(params?: RefreshParams): void;
        load(): void;
        remove(): void;
        status(): {
          loaded: boolean;
        };
      };
    };
  }
}

hotjar.initialize(import.meta.env.VITE_HOTJAR_ID, import.meta.env.VITE_HOTJAR_VERSION);

export function ClientLayout() {
  const { id: clientId } = useParams();
  const surgeEventsDisplayCutOff = 5;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { user: userData, setHotel, hotel, setHotelIframeId } = useAuthStore();
  const { setHotelAuthToken } = useHotelStore();
  const {
    setDefaultRoomId,
    defaultRoomId,
    drawerState,
    setDrawerState,
    viewingDate,
    setViewingDate
  } = usePriceDrawerStore();
  const { liliusInstance } = useCalendarPageStore();
  const { preparePusher } = usePusherStore();
  const posthog = usePostHog();
  const { features, setFeatures, hasReportingFeature, hasDashboardFeature } = useFeaturesStore();
  const { data, isLoading: isLoadingHotelAccount } = useGetAccountHotel();
  const { locale } = useUserLocale();

  const { setView, view } = useViewStore();
  const { isLoading: isLoadingCurrentUTCTime } = useGetCurrentUTCTime();
  const [isHotelAccountLoaded, setIsHotelAccountLoaded] = useState(false);
  const { profileProperties } = useTrackingProperties(isHotelAccountLoaded);
  const { isDefaultUpselling } = useUpselling(isHotelAccountLoaded);
  const { paymentInfo } = usePaymentInformation(isHotelAccountLoaded);
  const { toggleIsInterMediate } = useUploadPMSPriceModalState();
  const { surgeEvents, surgeDays } = useSurgeEvents(isHotelAccountLoaded);
  const { setBulkEditTab, setBulkEditDrawerState } = useBulkEditsStore();
  const { hasSurgeEvents, setHasSurgeEvents } = useSurgeManageModal();

  const ldClient = useLDClient();

  const documentState = useDocumentVisibility();
  useDocumentTitle(
    documentState === 'hidden' && data?.hotel?.name
      ? `${data?.hotel?.name} - RoomPriceGenie`
      : 'Revenue you can count on - RoomPriceGenie'
  );

  const { createBanner, removeBanner } = useBanners();

  const { mutate: visitorIdentificationMutation } = useMutation({
    mutationKey: [TrackingQueryKeys.VISITOR_IDENTIFICATION],
    mutationFn: visitorIdentification
  });
  const { mutate: hotelTracktimeMutation } = useMutation({
    mutationKey: [TrackingQueryKeys.HOTEL_TRACK_TIME],
    mutationFn: hotelTracktime
  });
  const subdomain = window.location.hostname.split('.')[0];
  const isIframe = subdomain.includes('iframe');
  const isFreeTrialExpired = data?.hotel.state === 3;
  const isProBadge = data?.hotel.state === 0;
  const isLimitedBadge = data?.hotel.state === 0;
  const isUnsubscribe = data?.hotel?.state === 7;
  const isCancelled = data?.hotel?.state === 2;
  const isSubscriptionPaused = data?.hotel?.state === 9;
  const isPartnerUser = userData?.sub_account_type === 2;
  const isSalesUser = userData?.sub_account_type === 1;
  const isShowPartnerAdminMenu = isPartnerUser && view !== 'client';

  const [isHoteliersInspiration, setIsHoteliersInspiration] = useState(false);

  const tagManagerArgs = {
    dataLayer: {
      userId: clientId,
      page: 'client'
    },
    dataLayerName: 'PageDataLayer'
  };

  TagManager.dataLayer(tagManagerArgs);

  function getReminderSubscriptionMessage(status: string, type: string) {
    const messages = {
      not_paid: t(
        'Your account has an overdue invoice and is at risk of being locked. Please check your open invoices or update your payment method to setup an automated payment process. If you need help, please contact our support via the chat.'
      ),
      payment_due_card: t(
        'We are sorry but we could not charge your credit card. Please update your Payment Method and contact us via the chat in case you need support.'
      ),
      payment_due_direct_debit: t(
        'We are sorry but we could not process your payment with the direct debit payment method you set up. Please update your Payment Method and contact us via the chat in case you need support.'
      ),
      payment_due_other: t(
        'We are sorry but it looks like you have an overdue invoice. Please check your open invoices or update your payment method to setup an automated payment process. If you need help, please contact our support.'
      )
    };

    switch (status) {
      case 'not_paid':
        return messages.not_paid;
      case 'payment_due':
        if (type === 'card') {
          return messages.payment_due_card;
        } else if (type === 'direct_debit') {
          return messages.payment_due_direct_debit;
        } else {
          return messages.payment_due_other;
        }
      default:
        return '';
    }
  }

  useEffect(() => {
    if (paymentInfo?.payment_info) {
      const statusPayment = paymentInfo?.payment_info?.invoice.status;
      const typePayment = paymentInfo?.payment_info?.payment_method.type;
      const directDebit = typePayment === 'direct_debit';
      if ((statusPayment === 'payment_due' && !directDebit) || statusPayment === 'not_paid') {
        createBanner({
          id: 'payment-reminder',
          title: t('Payment Failed') as string,
          actionTitle: t('Go to Subscription') as string,
          description: getReminderSubscriptionMessage(statusPayment, typePayment),
          type: 'warning',
          onAction: () => {
            navigate(`/client/account/${clientId}/payment-settings`);
          }
        });
      }
    }
  }, [paymentInfo, t]);

  useEffect(() => {
    if (clientId || !hotel) return;

    navigate(`/client/calendar/${hotel}`);
  }, []);

  useAppCuesScript();
  useChatBot();
  useAIChatBot({
    additionalData: {
      accountId: userData?.id,
      firstName: userData?.first_name,
      lastName: userData?.last_name,
      email: userData?.email,
      location: data?.hotel?.country?.name,
      planTier: getPlanTier(data?.hotel?.plan ? data?.hotel?.plan : 0),
      partnerSubscription: features?.includes(Feature.PartnerSubscriptionPage),
      provider: data?.hotel?.pms_provider ? pmsLookup[data.hotel.pms_provider] : '-'
    },
    // To wait for all data to be available to send
    enabled: !!userData && !!data
  });

  const userEmail = userData?.email || 'Loading...';

  useEffect(() => {
    if (!features) return;

    if (features.includes(Feature.DashboardOnly) || (isIframe && hasDashboardFeature())) {
      navigate(`/client/dashboard/${clientId}`);
    }
  }, [features, isIframe, hasDashboardFeature]);

  // Appcues
  const appCuesIdentity = {
    // User Data
    accountId: userData?.id,
    firstName: userData?.first_name,
    lastName: userData?.last_name,
    email: userEmail,
    companyName: clientId,
    language: userData?.language,
    // Hotel Data
    freeTrialEndDate: dayjs(data?.hotel.free_trial_end).unix(),
    account_type: userData?.is_staff ? 'admin' : 'member',
    auto_upload: data?.hotel?.is_update_to_pms,
    freeTrialEnd: dayjs(data?.hotel.free_trial_end).diff(dayjs(), 'days'),
    location: data?.hotel?.country?.name,
    planTier: getPlanTier(data?.hotel?.plan ? data?.hotel?.plan : 0),
    PlanType: getPlanType(data?.hotel?.state ? data?.hotel?.state : 0),
    partnerSubscription: features?.includes(10)
  };

  useEffect(() => {
    if (!userData) return;

    visitorIdentificationMutation({
      email: userData.email,
      firstName: userData.first_name,
      lastName: userData.last_name
    });

    if (import.meta.env.DEV) {
      console.log('<ClientLayout>: Set language...', userData?.language);
    }
    if (userData.language) {
      i18n.changeLanguage(userData.language);
      dayjs.locale(userData.language?.toLowerCase());
    }

    if (userData.is_staff || userData.sub_account_type === 2) {
      setView('admin');
    } else {
      setView('client');
    }
  }, [userData]);

  useEffect(() => {
    if (!data?.hotel) return;

    if (window.Appcues) {
      window.Appcues.page();
    }
    if (window.Appcues) {
      window.Appcues.show();
    }
    if (window.Appcues) {
      window.Appcues.identify(`${appCuesIdentity.accountId}`, appCuesIdentity);
    }

    if (hotjar.initialized()) {
      hotjar.identify('user_id', { ...appCuesIdentity });
    }

    ldIdentify(ldClient, {
      hotel: {
        key: data?.hotel.id.toString() || 'hotel',
        id: data?.hotel.id,
        pms_provider: data?.hotel.pms_provider,
        user_email: userData?.email
      },
      user: {
        key: userData?.id.toString() || 'user',
        ...userData
      }
    });

    CurrencyFormatter.init({
      locale: 'en-US',
      currency: data?.hotel.currency.abbreviation,
      currencySymbol: data?.hotel.currency.symbol
    });

    setIsHoteliersInspiration(data?.hotel.reseller?.id === 9);

    (async () => {
      if (!data?.token) return;

      if (isDevelopment()) {
        console.log('<ClientLayout>: Set hotel auth token...', data.token);
      }

      preparePusher(data.hotel.id);
      hotelTracktimeMutation(data);
      Sentry.setTag('rpg_hotel_id', data.hotel.id.toString());
      dayjs.tz.setDefault(data.hotel.timezone);
      setHotelAuthToken(data.token);
      setAxiosAuthToken(data.token);
      setHotelIframeId(data.enc_hotel_id);
      setIsHotelAccountLoaded(true);
    })();
  }, [data]);

  useEffect(() => {
    if (!isHotelAccountLoaded || !profileProperties) return;
    if (import.meta.env.DEV) {
      console.log('<ClientLayout>: Set profile properties...', profileProperties);
    }
    posthog?.identify(userData?.id.toString(), profileProperties);
    posthog?.group('hotel', data?.hotel?.id.toString() ?? '');
  }, [isHotelAccountLoaded, profileProperties]);

  const {
    pricingSettings,
    pricingSettingsQuery: { isLoading: isLoadingPricingSettings }
  } = usePricingSettings(isHotelAccountLoaded);
  const { hotelRooms } = useHotelRoomsList(isHotelAccountLoaded);

  useEffect(() => {
    if (!pricingSettings) return;

    if (import.meta.env.DEV) {
      console.log(
        '<ClientLayout>: Set features...',
        pricingSettings?.features?.map((feature) => Feature[feature]).join(', ') || ''
      );
    }
    setFeatures(pricingSettings?.features || []);
  }, [pricingSettings]);

  useEffect(() => {
    if (!userData?.id || !data?.hotel?.id || !isHotelAccountLoaded) return;

    // User Data
    const userflowIdentity = {
      email: userData?.email,
      firstName: userData?.first_name,
      lastName: userData?.last_name,
      language: userData?.language,
      locale_code: locale.code
    };
    // Company Data
    const companyData = {
      company_id: data?.hotel?.id,
      name: data?.hotel?.name,
      auto_upload: data?.hotel?.is_update_to_pms,
      signed_up_at: dayjs(data?.hotel?.free_trial_end).isValid()
        ? dayjs(data?.hotel?.free_trial_end)?.toISOString()
        : '',
      location: data?.hotel?.country?.name,
      planTier: getPlanTier(data?.hotel?.plan ? data?.hotel?.plan : 0),
      planType: getPlanType(data?.hotel?.state ? data?.hotel?.state : 0),
      ffPartnerSubscription: features?.includes(Feature.PartnerSubscriptionPage),
      ffEighteenMonthsPricing: features?.includes(Feature.EighteenMonthsPricing),
      ffYieldingTags: features?.includes(Feature.YieldTag),
      ffGroupBookingsSegmentation: features?.includes(Feature.Segmentation),
      ffBusinessInsightsPerformanceAnalyticsReporting: features?.includes(
        Feature.ProfessionalDashboard
      ),
      ffMarketInsightsMI: features?.includes(Feature.ProMarketInsights),
      ffMinStayRestrictionsAutomation: features?.includes(Feature.MinimumStayRestrictions),
      provider: data?.hotel?.pms_provider ? pmsLookup[data.hotel.pms_provider] : '-',
      numberOfRooms: pricingSettings?.hotel?.number_of_rooms
    };

    // Identify User
    userflow.identify(userData.id.toString(), userflowIdentity);
    if (isDevelopment()) console.log('Identifying user with Userflow:', userflowIdentity);

    // Group User to Company
    userflow.group(data.hotel.id.toString(), companyData, {
      membership: {
        role: userData?.is_staff ? 'admin' : 'member'
      }
    });
    if (isDevelopment()) console.log('Grouping user to company with Userflow:', companyData);
  }, [isHotelAccountLoaded, userData, data, clientId, features, pricingSettings]);

  // Set default selected hotel room effect
  useEffect(() => {
    if (!defaultRoomId && hotelRooms) {
      if (import.meta.env.DEV) {
        console.log('Setting default selected hotel room...');
      }

      const refRoom = hotelRooms.find((room) => room.is_reference_room);
      setDefaultRoomId(refRoom?.id);
    }
  }, [hotelRooms]);

  const handleClosePriceDrawer = useCallback(() => {
    toggleIsInterMediate(false);
    setDrawerState(false);
  }, []);

  const handleSelectHotel = async (hotelId: number) => {
    window.location.href = `/client/calendar/${hotelId}`;
    setHotel(hotelId);
  };

  const isHideMenu = isUnsubscribe || isFreeTrialExpired || isSubscriptionPaused || isCancelled;

  const isSurgeEvents =
    surgeEvents?.results.some((surgeEvent) => surgeEvent.active) &&
    features?.includes(Feature.SurgeProtection);

  useEffect(() => {
    if (!isNil(isSurgeEvents) && isSurgeEvents !== hasSurgeEvents) {
      setHasSurgeEvents(isSurgeEvents);
    }
    if (isNil(isSurgeEvents) && hasSurgeEvents) {
      setHasSurgeEvents(false);
    }
  }, [isSurgeEvents, hasSurgeEvents]);

  const { cachePriceQuery } = useRoomPrices(isHotelAccountLoaded);
  const { isLoading: isCachePriceLoading } = cachePriceQuery;

  const onBulkSurgeEdit = () => {
    navigate(`/client/calendar/${clientId}/pricing`);
    setBulkEditTab(BulkEditTab.SURGES);
    setBulkEditDrawerState(true);
  };

  useEffect(() => {
    if (surgeEvents && isSurgeEvents && !isCachePriceLoading) {
      createBanner({
        id: 'surge-events',
        title: t('Surge Events') as string,
        showAction: false,
        description: surgeDays.some((surgeDay) => surgeDay.hasSurgePrices)
          ? t(
              'Temporary high protection prices have been set for the highlighted dates. Click on the date to fix the price:'
            )
          : surgeDays.every((surgeDay) => surgeDay.hasSurgePrices)
          ? t(
              'Temporary high protection prices have been set for the following dates. Click on the date to fix the price:'
            )
          : t('More than expected bookings detected. Click on the date to check your prices:'),
        components: (
          <Flex wrap="wrap" mt="xs" w="100%" align="center" rowGap="sm" columnGap="xs">
            {!isEmpty(surgeDays) ? (
              <>
                {map(take(surgeDays, surgeEventsDisplayCutOff), (surgeDay) => (
                  <Box key={`surgeDay-button-${surgeDay.date}`}>
                    <Button
                      variant="outline"
                      fw={surgeDay.hasSurgePrices ? 'bold' : 'normal'}
                      td={surgeDay.hasSurgePrices ? 'underline' : 'none'}
                      onClick={() => {
                        setViewingDate(dayjs(surgeDay.date).toDate());
                        setDrawerState(true);
                        liliusInstance?.setViewing(dayjs(surgeDay.date).toDate());
                      }}>
                      {data?.hotel?.prefered_date_format
                        ? `${dayjs(surgeDay.date).format('ddd')}, `
                        : null}
                      {localeDateFormat(
                        dayjs(surgeDay.date).toDate(),
                        dayjs(surgeDay.date).format(
                          formatDateWithoutTime(
                            data?.hotel?.prefered_date_format ?? DATE_FORMAT_WITH_DAY
                          )
                        )
                      )}
                    </Button>
                  </Box>
                ))}
                {size(surgeDays) > surgeEventsDisplayCutOff ? (
                  <Button
                    variant="subtle"
                    component={Link}
                    to={replace('/client/surge-event/:id/logs', ':id', String(clientId))}>
                    {t(`+${size(surgeDays) - surgeEventsDisplayCutOff} ${t('more')}`)}
                  </Button>
                ) : null}
              </>
            ) : null}
          </Flex>
        ),
        type: 'surge',
        showClose: true,
        extraAction: () => (
          <>
            {size(surgeDays) > surgeEventsDisplayCutOff ? (
              <>
                <Button
                  variant="outline"
                  loading={isCachePriceLoading}
                  disabled={!isHotelAccountLoaded || isCachePriceLoading}
                  onClick={onBulkSurgeEdit}>
                  {t('Bulk Dismiss')}
                </Button>
              </>
            ) : null}
          </>
        )
      });
    }
  }, [isSurgeEvents, pricingSettings, data, surgeEvents, liliusInstance, isCachePriceLoading]);

  useEffect(() => {
    if (!features?.includes(Feature.SurgeProtection)) {
      removeBanner('surge-events');
    }
  }, [features]);

  const dashboardMenu =
    hasReportingFeature() && !(isHideMenu && view === 'client')
      ? [
          {
            title: !hasDashboardFeature() ? t('Benchmarking') : t('Reporting'),
            path: `/client/dashboard/${clientId}`,
            icon: Icon.ChartBarThree,
            badge:
              isProBadge || isLimitedBadge ? (
                <Badge variant="contained">
                  {isProBadge && features?.includes(Feature.ProfessionalDashboard)
                    ? 'PRO'
                    : isLimitedBadge && !features?.includes(Feature.ProfessionalDashboard)
                    ? t('LIMITED')
                    : null}
                </Badge>
              ) : null,
            feature: [
              Feature.ProfessionalDashboard,
              Feature.DashboardOnly,
              Feature.LimitedDashboard,
              Feature.ProMarketInsights,
              Feature.LimitedMarketInsights
            ],
            userflowId: NavUserflowIds.REPORTING_MENU_ITEM
          }
        ]
      : [];

  const navigation = [
    ...(hasDashboardFeature() ? dashboardMenu : []),
    ...(!features?.includes(31)
      ? isHideMenu && view === 'client'
        ? []
        : [
            {
              title: t('Calendar'),
              path: `/client/calendar/${clientId}`,
              highlight: isSurgeEvents,
              highlightColor: 'bg-darkGreen bg-opacity-100 hover:bg-darkGreen hover:bg-opacity-80',
              badge: isSurgeEvents ? <Icon.Ripple /> : null,
              icon: Icon.Calendar,
              userflowId: NavUserflowIds.CALENDAR_MENU_ITEM
            },
            {
              title: t('Chart'),
              path: `/client/chart/${clientId}`,
              icon: Icon.ChartLineUp,
              userflowId: NavUserflowIds.CHART_MENU_ITEM
            },
            ...(features?.includes(19) && view === 'client'
              ? []
              : [
                  {
                    title: t('Pricing Strategy'),
                    icon: Icon.Settings,
                    subNavigation: [
                      {
                        title: t(`${data?.hotel?.room_apartment_space_name}s Setup`),
                        path: `/client/room-setup/${clientId}`
                      },
                      {
                        title: t('Day-of-the-Week Adjustments'),
                        path: `/client/day-of-the-week/${clientId}`
                      },
                      {
                        title: t('Monthly Adjustments'),
                        path: `/client/monthly-settings/${clientId}`
                      },
                      {
                        title: t('Occupancy Strategy'),
                        path: `/client/occupancy/${clientId}`
                      },
                      {
                        title: t('Min Stay Restrictions'),
                        path: `/client/min-stay/${clientId}`,
                        feature: [
                          Feature.MinimumStayRestrictions,
                          Feature.LimitedMinimumStayRestrictions
                        ]
                      },
                      {
                        title: t('After 365 Days'),
                        path: `/client/after-year-setting/${clientId}`,
                        feature: 23
                      },
                      {
                        title: t('Yielding Tags'),
                        path: `/client/yielding-tags/${clientId}`,
                        badge:
                          isProBadge && features?.includes(9) ? (
                            <Badge variant="contained">PRO</Badge>
                          ) : null,
                        feature: 9
                      },
                      {
                        title: t('Lead Time'),
                        path: `/client/lead-time-adjustments/${clientId}`,
                        feature: 7
                      },
                      {
                        title: t('Surge Protection'),
                        path: `/client/surge-event/${clientId}`,
                        feature: 38
                      },
                      {
                        title: t('BAR Levels'),
                        path: `/client/rate-settings/${clientId}`,
                        feature: 13
                      },
                      ...(view === 'admin'
                        ? [
                            {
                              title: t('Market Intelligence'),
                              path: `/client/competitors/${clientId}`,
                              badge: <Badge variant="contained">Admin</Badge>
                            }
                          ]
                        : []),
                      {
                        title: t('Segmentation'),
                        path: `/client/segmentation/${clientId}`,
                        badge:
                          isProBadge && features?.includes(32) ? (
                            <Badge variant="contained">PRO</Badge>
                          ) : null,
                        feature: 32
                      }
                    ],
                    userflowId: NavUserflowIds.PRICING_STRATEGY_MENU_ITEM
                  }
                ]),
            ...(!hasDashboardFeature() ? dashboardMenu : [])
          ]
      : []),
    ...(view === 'admin' || isShowPartnerAdminMenu
      ? [
          {
            title: t('Admin'),
            path: '#',
            icon: Icon.AllDone,
            subNavigation: [
              {
                title: t('Deal'),
                path: `/client/deal/${clientId}`
              },
              {
                title: t('Customer Health'),
                path: `/client/customer-health/${clientId}`
              },
              {
                title: t('Notes'),
                path: `/client/notes/${clientId}`
              },
              {
                title: t('Features'),
                path: `/client/features/${clientId}`
              },
              ...(isShowPartnerAdminMenu
                ? []
                : [
                    ...(view === 'admin' && (!isSalesUser || !isPartnerUser)
                      ? [
                          {
                            title: t('Request IFrame URL'),
                            path: `/client/request-iframe-url/${clientId}`
                          }
                        ]
                      : []),
                    {
                      title: t('Change Password'),
                      path: `/client/change-password/${clientId}`
                    },
                    {
                      title: t('Delete Property'),
                      path: `/client/delete-property/${clientId}`
                    }
                  ])
            ]
          }
        ]
      : []),
    ...(view === 'admin' && !isShowPartnerAdminMenu
      ? [
          {
            title: t('Logs'),
            path: '#',
            icon: IconFileCode as FC<SVGProps<SVGSVGElement>>,
            subNavigation: [
              {
                title: t('Inbound API'),
                path: `/client/inbound-api-log/${clientId}`
              },
              {
                title: t('Outbound API'),
                path: `/client/outbound-api-log/${clientId}`
              },
              {
                title: t('Reservation'),
                path: `/client/reservation-api-log/${clientId}`
              },
              {
                title: t('Pricing'),
                path: `/client/run-algo/${clientId}`
              },
              {
                title: t('Settings'),
                path: `/client/settings-log/${clientId}`
              },
              {
                title: t('Users'),
                path: `/client/users-log/${clientId}`
              }
            ]
          }
        ]
      : [])
  ];

  const secondaryNavigation =
    features?.includes(19) && view === 'client'
      ? []
      : [
          // ...(isIframe
          //   ? []
          //   : [
          //       {
          //         title: t('Refer a Friend'),
          //         path: `/client/community/${clientId}`,
          //         icon: Icon.UserSwitch
          //       }
          //     ]),
          { title: t('Help'), path: `/client/help/${clientId}`, icon: Icon.HelpOutlined }
        ];

  const userProfile =
    features?.includes(19) && view === 'client'
      ? {
          email: t('Logout'),
          path: `/client/logout`,
          icon: Icon.SignOut,
          tooltip: t('Logout')
        }
      : {
          email: userEmail,
          path: `/client/account/${clientId}/profile-settings`
        };

  const subscribeButton =
    data?.hotel.state === HotelState.FREE_TRIAL
      ? {
          title: t('Subscribe'),
          daysLeft: `${Math.ceil(
            dayjs(data?.hotel?.free_trial_end).diff(dayjs(), 'day', true)
          )} ${t('days left in free trial')}`,
          path: `/client/account/${clientId}/payment-settings`,
          icon: Icon.Verified,
          highlight: true
        }
      : isDefaultUpselling
      ? {
          title: t('Upgrade'),
          icon: Icon.Spark,
          highlight: true,
          upselling: true
        }
      : undefined;

  if (isLoadingHotelAccount || isLoadingPricingSettings) {
    return <FullscreenLoading />;
  }

  return (
    <Suspense fallback={<FullscreenLoading />}>
      <ApplicationShell
        view={view}
        setView={setView}
        isIframe={isIframe}
        isHoteliersInspiration={isHoteliersInspiration}
        isAdmin={userData?.is_staff || userData?.sub_account_type === 2}
        selectedHotel={data?.hotel}
        selectHotel={(hotelId) => handleSelectHotel(hotelId)}
        hotel_list={data?.hotel_list || []}
        navigation={navigation}
        secondaryNavigation={secondaryNavigation}
        userProfile={userProfile}
        isLoading={isLoadingHotelAccount || isLoadingPricingSettings || isLoadingCurrentUTCTime}
        subscribeButton={subscribeButton}>
        <Outlet />
        {/* Price Drawer */}
        <Drawer isOpen={drawerState} onClose={setDrawerState}>
          <PriceDrawerContent viewingDate={viewingDate} onClose={handleClosePriceDrawer} />
        </Drawer>
      </ApplicationShell>
    </Suspense>
  );
}
