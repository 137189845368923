import { useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { KPILarge } from '@common/components/molecules/KPI';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { Feature, useFeaturesStore } from '@common/store/features';

export const useOccupancyKpiContent = (dateKey: string) => {
  const { t } = useTranslation();
  const { roomPrices } = useRoomPrices();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const spaceName = hotelDetails?.room_apartment_space_name ?? 'Room';

  const property = useMemo(() => {
    return roomPrices?.prices.data?.[dateKey]?.property;
  }, [roomPrices, dateKey]);

  if (!property) {
    return;
  }

  const roomsSold = Math.max(0, property.number_of_rooms - property.inventory_remaining);
  const expectedRoomSold = property.expectedroomssold_adjusted
    ? Math.round(property.expectedroomssold_adjusted)
    : property.expectedroomssold_adjusted;

  const content: ComponentProps<typeof KPILarge>['content'] = {
    basePrice: '',
    basePriceValue: '',
    prices: [
      {
        title: `${t(`${spaceName}s Sold`)}`,
        value: `${roomsSold}`
      },
      {
        title: t(`Expected ${spaceName}s Sold`),
        value: `${expectedRoomSold}`
      }
    ],
    extra:
      features?.includes(Feature.Segmentation) && property?.segmentation_bookings
        ? Object.entries(property.segmentation_bookings).map(([segmentName, number]) => ({
            title: t(`${segmentName} ${spaceName}s Sold`),
            value: `${number}`
          }))
        : undefined
  };

  return content;
};
