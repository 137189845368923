import { Navigate, RouteObject } from 'react-router-dom';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import TargetOccupancyPage from '@pages/Client/PricingStrategy/OccupancyStrategy/pages/TargetOccupancy';
import MedianBookingWindowPage from '@pages/Client/PricingStrategy/OccupancyStrategy/pages/MedianBookingWindow';
import AggressivenessPage from '@pages/Client/PricingStrategy/OccupancyStrategy/pages/Aggressiveness';
import CloseOutSalesPage from '@pages/Client/PricingStrategy/OccupancyStrategy/pages/CloseOutSales';

export const occupancyRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="target-occupancy" /> },
  {
    path: 'target-occupancy',
    element: (
      <SecureRoute>
        <TargetOccupancyPage />
      </SecureRoute>
    )
  },
  {
    path: 'median-booking-window',
    element: (
      <SecureRoute>
        <MedianBookingWindowPage />
      </SecureRoute>
    )
  },
  {
    path: 'aggressiveness',
    element: (
      <SecureRoute feature={12}>
        <AggressivenessPage />
      </SecureRoute>
    )
  },
  {
    path: 'close-out-sales',
    element: (
      <SecureRoute>
        <CloseOutSalesPage />
      </SecureRoute>
    )
  }
];
