import { create } from 'zustand';

type FormSubmitStore = {
  submitHandler: null | (() => void);
  setSubmitHandler: (handler: () => void) => void;
  triggerSubmit: () => void;
};

export const useFormSubmitStore = create<FormSubmitStore>((set) => ({
  submitHandler: null,
  setSubmitHandler: (handler) => set({ submitHandler: handler }),
  triggerSubmit: () => {
    const { submitHandler } = useFormSubmitStore.getState();
    if (submitHandler) {
      submitHandler();
    }
  }
}));
