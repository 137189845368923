import { router } from '@common/routes';
import { QueryClientProvider } from '@tanstack/react-query';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getEnvVar, isDevelopment } from '@common/utils/env';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { MantineProvider } from '@mantine/core';
import { theme, resolver, variantColorResolver } from '@common/mantine/theme';

import DayJSUtc from 'dayjs/plugin/utc';
import DayJSTimezone from 'dayjs/plugin/timezone';
import DayJSIsBetween from 'dayjs/plugin/isBetween';
import DayJSLocaleData from 'dayjs/plugin/localeData';
import DayJSIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import DayJSWeekOfYear from 'dayjs/plugin/weekOfYear';
import DayJSLocalizedFormat from 'dayjs/plugin/localizedFormat';

import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import { RouterProvider } from 'react-router-dom';
import userflow from 'userflow.js';
import { ClerkAppProvider } from '@common/providers/clerk-provider';
import { queryClient } from '@common/setup/query-client';

// Setup userflow
userflow.init(getEnvVar('VITE_USERFLOW_TOKEN'));

// Setup i18n
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    backend: {
      loadPath: `https://api.locize.app/${getEnvVar('VITE_I18N_PROJECT_ID')}/${getEnvVar(
        'VITE_I18N_VERSION'
      )}/{{lng}}/translations`,
      crossDomain: true,
      withCredentials: false
    },
    debug: false,
    interpolation: {
      escapeValue: false
    }
  })
  .then(() => {
    dayjs.locale(i18n.language); // set initial locale in dayjs
  });

// Setup posthog
posthog.init(getEnvVar('VITE_POSTHOG_TOKEN'), {
  api_host: 'https://eu.posthog.com',
  loaded: (posthog) => {
    if (isDevelopment()) posthog.debug();
  }
});

// Setup dayjs
dayjs.extend(DayJSUtc);
dayjs.extend(DayJSTimezone);
dayjs.extend(DayJSIsBetween);
dayjs.extend(DayJSLocaleData);
dayjs.extend(DayJSIsSameOrAfter);
dayjs.extend(DayJSWeekOfYear);
dayjs.extend(DayJSLocalizedFormat);

export function QueryProvider({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export function App() {
  return (
    <MantineProvider
      theme={{ ...theme, variantColorResolver }}
      cssVariablesResolver={resolver}
      defaultColorScheme="light">
      <PostHogProvider client={posthog}>
        <QueryProvider>
          <ClerkAppProvider>
            <RouterProvider router={router} />
          </ClerkAppProvider>
        </QueryProvider>
      </PostHogProvider>
    </MantineProvider>
  );
}
