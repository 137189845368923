import { SSOProperty } from '@common/api/hotel/types';
import { Button } from '@common/components/atoms/Button';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { LoadingCircle } from '@common/components/foundations/icons/icons/LoadingCircle';
import { cn } from '@common/utils/cn';
import { useGetProperties } from '@pages/Auth/SSO/hooks/useGetProperties';
import { useLoginProperties } from '@pages/Auth/SSO/hooks/useLoginProperties';
import { useRegisterProperties } from '@pages/Auth/SSO/hooks/useRegisterProperties';
import { useSwitchProperties } from '@pages/Auth/SSO/hooks/useSwitchProperties';
import { useGetSSOToken } from '@pages/Auth/SSO/store/ssoToken';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function Properties() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { setSsoToken } = useGetSSOToken();
  const { isLoading, hotelProperties, error: isErrorGetProperties } = useGetProperties();
  const { isLoginPropertiesLoading, loginProperties } = useLoginProperties();
  const { isRegisterPropertiesLoading, registerProperties } = useRegisterProperties();
  const { isSwitchPropertiesLoading, switchProperties } = useSwitchProperties();
  const [propertiesCodeClick, setPropertiesCodeClick] = useState<string>('');
  const navigate = useNavigate();

  const hasSwitchId = hotelProperties?.data?.hotel_id;

  useEffect(() => {
    if (token) {
      setSsoToken(token);
    }
  }, [token, setSsoToken]);

  const handlePropertiesCodeClick = (property: SSOProperty) => {
    setPropertiesCodeClick(property.code);
    const { code, exist } = property;
    if (!hasSwitchId) {
      if (!isLoginPropertiesLoading && !isRegisterPropertiesLoading) {
        exist ? loginProperties(code) : registerProperties(code);
      }
    } else {
      if (!isSwitchPropertiesLoading) {
        switchProperties(code);
      }
    }
  };

  return (
    <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full 2xl:w-3/4">
        <div>
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="mt-6">
            <Typography element="h2" variant="h4" className="font-medium" color="darkGrey">
              Hi, there!
            </Typography>
            <Typography element="h6" variant="paragraph-1" className="mt-2" color="darkGrey">
              {isLoading ? (
                <Skeleton className="h-3 w-1/2" />
              ) : (
                'We noticed that you have multiple properties. Please select the property you want to set up now. If you want to set up more properties, please use the SSO button again and set them up one by one. Thank you very much!'
              )}
            </Typography>
            <div className="mt-4 max-h-screen space-y-3 overflow-y-auto">
              {isLoading
                ? Array.from({ length: 4 }).map((_, index) => (
                    <Skeleton className="h-[66px]" key={index} />
                  ))
                : hotelProperties?.data?.properties.map((property) => (
                    <div
                      key={property.code}
                      aria-disabled={
                        isLoginPropertiesLoading ||
                        isRegisterPropertiesLoading ||
                        isSwitchPropertiesLoading
                      }
                      onClick={() => handlePropertiesCodeClick(property)}
                      className={cn(
                        'rounded-xl border border-indigo border-opacity-40 bg-white px-4 py-2 hover:cursor-pointer hover:bg-indigo-reduced hover:bg-opacity-20',
                        {
                          'opacity-50 hover:cursor-not-allowed':
                            isLoginPropertiesLoading ||
                            isRegisterPropertiesLoading ||
                            isSwitchPropertiesLoading
                        }
                      )}>
                      <div className="flex w-full items-center justify-between">
                        <div className="-space-y-1">
                          <Typography element="h6" variant="paragraph-1" color="darkGrey">
                            {property.name}
                          </Typography>
                          <Typography
                            className="pt-1"
                            element="p"
                            variant="paragraph-3"
                            color="copyTextGrey">
                            {property.desc}
                          </Typography>
                        </div>
                        <div>
                          {(isLoginPropertiesLoading || isRegisterPropertiesLoading) &&
                          propertiesCodeClick === property.code ? (
                            <LoadingCircle />
                          ) : (
                            <div className="flex items-center gap-1">
                              <Typography element="h6" variant="meta-1" color="darkGrey">
                                {hasSwitchId ? 'Switch' : property.exist ? 'Login' : 'Create'}
                              </Typography>
                              <Icon.ArrowLongRight className="ml-2 h-4 w-4" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
            <div>
              {isErrorGetProperties ? (
                <Button type="button" onClick={() => navigate('/sso')} intent="outline">
                  Back To Login
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
