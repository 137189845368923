import { z } from 'zod';
import {
  After365DailyAveragesSchema,
  CountrySchema,
  CurrencySchema,
  DatesSchema,
  PricingSettingsSchema,
  ResellerSchema,
  ResponsiblePersonSchema
} from '@pages/Client/Calendar/utils/validateBaseSettings';
import { PriceDataDetails } from '@common/api/roomPrices/types';
import { User } from '@common/api/auth';
import { Client } from '@common/api/account/types';

export interface GetHotelImportantEventsResponse {
  data: Record<string, HotelEvent[]>;
}

export interface HotelEvent {
  title: string;
  category: string;
  labels: string[];
  rank: string;
  local_rank: string;
  deleted_reason?: any;
  duration: string;
  start_date: string;
  end_date: string;
  state: string;
  weighted_local_rank: string;
}

export interface Room {
  id: number;
  hotel: number;
  name: string;
  max_occupancy: number;
  extra_child: number;
  pms_room: number;
  pms_rate: number;
  is_reference_room: boolean;
  occupancy_adjustment: null | number;
  occupancy_adjustment_is_absolute: boolean;
  configurations: {
    price_type: string;
  };
  breakfast_per_person: number;
  breakfast: boolean;
  default_occupancy: number;
  extra: string;
  competitors:
    | null
    | {
        id: number;
        index: number;
        pricediff: number | null;
        weight: number;
        competitor: number;
      }[];
  discount_per_person: number;
  pms_price_applicable_occupancy: null | number;
  use_prices_from: string;
  adjustment_to_reference_room: number;
  property_code: null | string;
  number_of_rooms: number;
  ignore_upload_count: boolean;
}

export type GetHotelRoomListResponse = Room[];

export type GetNoteRequest = {
  end_date: string;
  event_types: string;
  start_date: string;
  token: string;
};

type PredictedEventSpendIndustries = {
  accommodation: number;
  hospitality: number;
  transportation: number;
};

export type HotelRoomEvent = {
  category: string;
  deleted_reason: string | null;
  duration: string;
  end_date: string;
  labels: string[];
  local_rank: string;
  rank: string;
  start_date: string;
  state: string;
  title: string;
  weighted_local_rank: string;
  attendances: number;
  predicted_event_spend_industries: PredictedEventSpendIndustries;
};

export type HotelRoomEventResponse = {
  data: Record<string, HotelRoomEvent[]>;
  hotel_id: number;
  updated_at: string;
};

export interface HotelRoomNote {
  end_date: string;
  id: number;
  name: string;
  start_date: string;
}

export type HotelRoomNoteResponse = Record<string, HotelRoomNote[]>;

export interface Hotel {
  id: number;
  lat: string;
  lng: string;
  event_types: number;
  currency: Currency;
  address: string;
  region: string;
  postcode: string;
  country: Country;
  name: string;
  pms_sync: number;
  pms: number;
  website?: string;
  review: number;
  notes?: any;
  client: number;
  subscription_id: string;
  subscription_started: string;
  free_trial_end: string;
  using_override_bill: boolean;
  monthly_bill: number;
  expiry_date: string;
  next_charge: string;
  show_free_trial_date_in_expired: boolean;
  pms_provider: number;
  email: string;
  is_locked: boolean;
  state: number;
  is_update_to_pms: boolean;
  update_to_pms_type?: any;
  update_to_pms_skip_type?: any;
  timezone: string;
  last_login: string;
  last_update_price_pms: string;
  last_run_pricing: string;
  last_update_price_pms_result: string;
  last_update_price_pms_status: boolean;
  last_inventory_price_get: string;
  booking_suite_hotel_id?: any;
  last_run_pricing_type: number;
  booking_suite_hotel_connection: boolean;
  responsible_person: ResponsiblePerson;
  reseller?: Reseller;
  is_send_email: boolean;
  starts_monday: boolean;
  average_login_per_day: number;
  booking_ref_url: string;
  property_type: string;
  date_subscribtion_created_or_cancelled: string;
  business_leisure_ratio?: any;
  plan: number;
  subscription_type: number;
  include_in_hourly_execution: boolean;
  ota_subscription_id: number;
  ota_insights_otas: string;
  prefered_date_format: string;
  is_seasonal: boolean;
  room_apartment_space: number;
  room_apartment_space_name: string;
  is_client_view: boolean;
  close_out_hour: number;
  close_out_days: number;
  is_channel_manager: boolean;
  event_radius: number;
  token: string;
  is_pricing_turn_on: boolean;
  enable_health_check_hubspot_ticket: boolean;
  date_health_check_hubspot_ticket_snoozed: string | null;
  number_of_rooms?: number;
  occupancy?: {
    [key: string]: number;
  };
  reporting?: null | {
    [key: string]: {
      [key: string]: number;
    };
  };
}

export type Country = z.infer<typeof CountrySchema>;

export type Currency = z.infer<typeof CurrencySchema>;

export type ResponsiblePerson = z.infer<typeof ResponsiblePersonSchema>;

export interface HotelRoomList {
  id: number;
  hotel: number;
  name: string;
  max_occupancy: number;
  pms_room: number;
  pms_rate?: number;
  is_reference_room: boolean;
  occupancy_adjustment?: any;
  occupancy_adjustment_is_absolute: boolean;
  breakfast_per_person: number;
  breakfast: boolean;
  default_occupancy: number;
  extra: string;
  competitors?: Competitor[];
  discount_per_person: number;
  pms_price_applicable_occupancy?: any;
  use_prices_from: string;
  adjustment_to_reference_room: number;
  property_code?: any;
  number_of_rooms: number;
}

export interface Competitor {
  id: number;
  index: number;
  pricediff: number;
  weight: number;
  competitor: number;
}

export type PricingDatesSchema = z.infer<typeof DatesSchema>;

export type PricingSettings = z.infer<typeof PricingSettingsSchema>;

export type RevenueBudgetType = Record<string, number>;

export interface RevenueBudget {
  revenue_budget_gross_excl_services: RevenueBudgetType;
  revenue_budget_net_excl_services: RevenueBudgetType;
  revenue_budget_gross_incl_services: RevenueBudgetType;
  revenue_budget_net_incl_services: RevenueBudgetType;
}

export type After365DailyAverages = z.infer<typeof After365DailyAveragesSchema>;

export interface HotelClientListPagination {
  count: number;
  current: number;
  next: string;
  previous?: any;
  results: Hotel[];
}

export type Reseller = z.infer<typeof ResellerSchema>;

export interface Pricing {
  pricing: {
    [key: string]: {
      yearly: {
        price: number;
        price_per_month: number;
      };
      monthly: {
        price: number;
        price_per_year: number;
      };
      currency: {
        name: string;
        symbol: string;
      };
    };
  };
}

export interface ConnectChargebeePayload {
  subscription_id: string;
}

export interface HotelState {
  state: number;
}
export interface FreeTrial {
  free_trial_end: string | Date;
}

export interface HotelPmsList {
  [key: string]: any;
  id: number;
  provider: number;
  hotel_code: any;
  username: string;
  password: string;
  extra: any;
  token: string;
  channel_password: string | null;
  region: number | null;
  pms_url: string | null;
  primary: boolean;
  currency_id: number;
  occupancy_percent_derivation: boolean;
  use_v2_integration: boolean;
  configurations: Record<string, any>;
}

export interface HotelPmsDataMap {
  is_independent_rate: boolean;
  mapped_data: MappedData[];
}

export interface MappedData {
  id: number;
  room_id: string;
  name: string;
  max_occupancy: number;
  min_occupancy: number;
  number_of_rooms: number;
  applicable_occupancy: ApplicableOccupancy[] | null;
  connected_room: ConnectedRoom;
  extra?: any;
  property_code?: any;
  rates: Rate[];
}

export interface Rate {
  id: number;
  rate_id: string;
  name: string;
  pms_room: string;
  other_rate?: any;
  default_occupancy: number;
}

export interface ApplicableOccupancy {
  id: number;
  applicable_guest: number;
}

export interface UpdateRoomRequest {
  extra: string | null;
  name: string;
  pms_price_applicable_occupancy_id: number | null;
  pms_rate_id: number | null;
  pms_room_id: number | null;
  discount_per_person: number;
  use_prices_from: string;
  default_occupancy: number;
  configurations: {
    price_type?: string;
  };
  extra_child: number | null;
  ignore_upload_count: boolean;
}

export interface AddRoomRequest {
  max_occupancy: number;
  name: string;
  pms_price_applicable_occupancy: number | null;
  pms_rate: '' | number;
  pms_room: number | null;
  discount_per_person: number;
  default_occupancy: number;
  configurations: {
    price_type?: string;
  };
  ignore_upload_count: boolean;
  extra: string | null;
}

interface ConnectedRoom {
  id: number;
  name: string;
}

export interface CheckoutSubscription {
  is_yearly: number;
  subscription_plan: number;
}
export interface linkChargeebeeSubscription {
  hosted_page_token: string;
  subscription_plan: number;
}

export interface HotelCompetitor {
  id: number;
  imputed_average: number;
  imputed_pricediff: number;
  index: number;
  pricediff: number;
  weight: number;
  competitor: {
    avg_price: number;
    avg_price_roomtypes: number;
    is_active: number;
    last_updated_at: number;
    max_price: number;
    min_price: number;
    number_of_price: number;
    number_of_price_4_week: number;
    system_generated: number;
    hotel: Pick<Hotel, 'address' | 'id' | 'lat' | 'lng' | 'name'>;
  };
}

interface ClientMap {
  id: number;
  name: string;
  state: number;
  is_client_access_disabled: boolean;
  address: string;
  lat: string;
  lng: string;
}

interface ClientCompetitor {
  id: number;
  index: number;
  competitor: MapCompetitor;
  pricediff: number;
  weight: number;
  imputed_average: number;
  imputed_pricediff: number;
}

interface MapCompetitor {
  hotel: HotelMapCompetitor;
  is_active: boolean;
  max_price: number;
  min_price: number;
  avg_price: number;
  number_of_price: number;
  system_generated: boolean;
  avg_price_roomtypes: number;
  last_updated_at: string;
  number_of_price_4_week: number;
}

export interface HotelMapCompetitor {
  id: number;
  name: string;
  address: string;
  lat: string;
  lng: string;
}

export interface HotelCompetitorListResponse {
  count: number;
  current: number;
  nearby_client_and_competitor: {
    client_list: ClientMap[];
    client_competitor_list: ClientCompetitor[];
  };
  next: number | null;
  previous: number | null;
  results: HotelCompetitor[];
}

export interface HotelPricePerRateResponse {
  id: number;
  derivation: number;
  is_absolute: boolean;
  is_product_derivation: boolean;
  hotel: number;
  pms_room: number | null;
  pms_rate: number | null;
}

export interface HotelPricePerRateRequest extends Omit<HotelPricePerRateResponse, 'id'> {
  id: number | undefined;
}

export type HotelPricePerOccupancyRequest = Omit<HotelPricePerOccupancyResponse, 'id'>;

export interface OutboundApiLogs {
  id: number;
  request_type: string;
  created: string;
  hotel_id: string;
  unique_log_id: string;
  is_auto_program: boolean;
}

export interface HotelOutboundApiLogsPagination {
  count: number;
  current: number;
  next: string;
  // previous?: any;
  results: OutboundApiLogs[];
}

export interface InboundAndReservationApiLogs {
  id: number;
  url: string;
  request_type: string;
  reservation_data: null;
  created: string;
  correlation_id: string;
  soapaction: string;
  pms_type: number;
  protel_type: number;
  hotel_id: string;
  is_processed: boolean;
  date_list: string[];
  room_type_list: string[];
  rate_type_list: string[];
}

export interface HotelInboundApiLogsPagination {
  count: number;
  current: number;
  next: string;
  // previous?: any;
  results: InboundAndReservationApiLogs[];
}

export interface HotelReservationApiLogsPagination {
  count: number;
  current: number;
  next: string;
  // previous?: any;
  results: InboundAndReservationApiLogs[];
}

export interface DownloadOutboundApiLogs {
  id?: string | number;
  request_type: number;
  unique_log_id: string;
}

export interface ReferFriendPayload {
  friend_first_name: string;
  friend_last_name: string;
  friend_email: string;
  first_name: string;
  last_name: string;
  friend_property_name: string;
  message: string;
}

export interface CalculateAveragePriceResponse {
  success: boolean;
  data: CalculateAveragePriceData;
  is_final_data: boolean;
}

interface CalculateAveragePriceData {
  daily_price: DailyPrice;
  average_price: AveragePrice;
  weekday_avg_price: WeekdayAvgPrice;
}

interface WeekdayAvgPrice {
  [key: number]: {
    weekday: number;
    price_average: number;
  }[];
}

interface AveragePrice {
  [key: number]: {
    avg_price: number;
  };
}

interface DailyPrice {
  [key: string]: Record<string, number>;
}

export interface HotelPricePerOccupancyResponse {
  id: number;
  occupancy: number;
  derivation: number;
  room: number;
}
export interface UserData {
  firstname: string;
  lastname: string;
  email: string;
}

export interface TicketData {
  subject: string;
  content: string;
  hs_ticket_category: string;
  hs_ticket_priority: string;
}

export interface ReportIssuePayload {
  contact: UserData;
  ticket: TicketData;
}

export type TicketType = 'upsell_request';

export interface CreateHSTicketPayload {
  ticket_type: TicketType;
}

export interface CompetitorSetupResponse {
  complete_percentage: number;
  total_competitor: number;
  completed_competitor: number;
  is_top_20: boolean;
}

export interface GetTopTwentyCompetitor {
  distance: number;
}

export interface ApplyIndividualAdjustmentResponse {
  [date: string]: Record<number, PriceDataDetails>;
}

interface AmalgamationImputedPriceData {
  [date: string]: {
    [key: string]: {
      imputed: string;
      cleaned: string;
      raw: string;
    };
  };
}

interface SimilarityScore {
  unique_value_score: number;
  pearson_corr_score: number;
}

interface SimilarityScores {
  [key: string]: SimilarityScore;
}

interface ImputationStatistics {
  [key: string]: string;
}

interface MissingPricesScores {
  [key: string]: {
    pricing_completeness_score: number;
    missing_prices_near_future_score: number;
    missing_prices_pattern_score: number;
  };
}

interface MissingSimilarityTestScoresResult {
  [key: string]: number;
}

export interface CompetitorScore {
  data: {
    amalgamation_imputed_price: AmalgamationImputedPriceData;
    missing_similarity_testscores_result: MissingSimilarityTestScoresResult;
    similarity_scores: SimilarityScores;
    imputation_statics: ImputationStatistics;
    missing_prices_scores: MissingPricesScores;
  };
}

export interface UpdateCompetitor {
  competitors: string;
  token: string | null | undefined;
}
export interface ScrapeCompetitor {
  top_20: boolean;
}
export interface ScoreCompetitor {
  room_id: string;
  do_sorting: boolean;
}
export interface BookingScrape {
  hotel_url: string;
}

export interface SearchHotelCompetitor {
  id: number;
  address: string;
  name: string;
  competitor_data: any; // Update this with the correct type if available
  distance: number;
}

export interface SearchHotelCompetitorListResponse {
  count: number;
  current: number;
  next: null | string;
  previous: null | string;
  results: SearchHotelCompetitor[];
}

interface FullAirDnaData {
  airdna_data: Record<string, AirDnaData>;
  date_range: DateRange;
  area_info: AreaInfo;
  request_info: RequestInfo;
}

interface AirDnaData {
  merged_percentiles: number;
  merged_median_price_booked: number;
  count_available: number;
  count_booked: number;
  airdna_daily_base_price: number;
  airdna_exp_occupancy: number;
  airdna_demand_vs_expected: number;
  airdna_price_adjustment: number;
  airdna_new_price_for_compset: number;
}

interface DateRange {
  start_date: string;
  end_date: string;
}

interface AreaInfo {
  geom: Geom;
  last_month_change_in_available_properties_percent: number;
  available_properties_today: number;
}

interface Geom {
  name: Location;
  code: Location;
  id: Location;
}

interface Location {
  country: string;
  state: string;
  city: string;
}

interface RequestInfo {
  room_types: string[];
  bedrooms: number[];
  property_types: string[];
  accommodates: number[];
  start_year: number;
  start_month: number;
  start_day: number;
  number_of_months: number;
  percentiles: string;
  currency: string;
  concise: any[];
  start_date: string;
  city_id: number;
  req_type: string;
}

export interface FutureDailyResponse {
  full_air_dna_data: FullAirDnaData;
  city_name: string;
  city_id: number;
  todays_total_rooms: number;
}

export interface SearchMarketCode {
  term: string;
}

export interface SaveMarketData {
  name: string;
  type: string;
  country: {
    code: string;
    name: string;
  };
  state: {
    code: string;
    name: string;
  };
  city: {
    id: number;
    code: string;
    name: string;
  };
  region: {
    id: number;
    code: string;
    name: string;
    type: string;
  };
}

export interface CreateHotelPayload {
  client?: number;
  reference_room_data?: string;
  lat?: number;
  lng?: number;
  currency?: number;
  address?: string;
  region?: string;
  postcode?: string;
  country?: number;
  name?: string;
  booking_ref_url?: string;
  hotels_ref_url?: string;
  expedia_ref_url?: string;
  website?: string;
  review?: number;
  notes?: string;
  number_of_rooms?: string;
  reseller?: number;
  responsible_person?: number;
  timezone?: string;
}

export interface SearchHotelResponse {
  count: number;
  current: number;
  next: string | null;
  previous: string | null;
  results: SearchHotelResult[];
}

export interface SearchHotelResult {
  id: number;
  lat: number;
  lng: number;
  address: string;
  region: string;
  postcode: string;
  country: Country;
  currency: Currency;
  name: string;
  ota_reference: string;
  website: string | null;
  review: number;
  competitor_data: any;
}

export interface CheckBookingUrlPayload {
  booking_ref_url: string;
  is_booking_ref_url: boolean;
}

export interface SyncPmsUpdatePayload {
  Authtoken: string | null | undefined;
  pms_sync: number;
}

export interface RegisterPmsPayload {
  channel_password?: string | null;
  id?: number | null;
  password?: string | null;
  pms?: string | null;
  pms_url?: string | null;
  primary: boolean;
  hotel_code?: string;
  region?: string | null;
  token?: string | null;
  username?: string | null;
  provider: number;
  Authtoken: string;
  integration?: number;
  extra?: string;
  room_apartment_space_name: string;
  use_v2_integration?: boolean;
  currency_id?: number | undefined;
  configurations?: Record<string, any>;
}

export interface SabeeAppPayload {
  username: string;
}

export interface RoomTypePmsList {
  id: number;
  room_id: string;
  name: string;
  max_occupancy: number;
  min_occupancy: number;
  number_of_rooms: number;
  applicable_occupancy: null | string;
  connected_room: null | string;
  extra: null | any;
  property_code: null | string;
}

export interface RoomRatePmsList {
  id: number;
  rate_id: string;
  name: string;
  pms_room: null | any;
  description: null | any;
  checkin: string;
  checkout: string;
  room_rate: any[];
  other_rate: null | any;
}

export interface CreateRoomTypePayload {
  token: string | null;
  room_id: string;
  name: string;
  min_occupancy: string;
  max_occupancy: string;
}

export interface RoomsRateListData {
  id: any;
  pms_room: any;
  rate_id: any;
}
export interface GuestLineUpdatePayload {
  checkin: Date | null;
  checkout: Date | null;
  description: string;
  id?: number | string | null;
  is_for_all_room_type: boolean;
  name: string;
  other_rate: string;
  pms_id: number | string | null;
  rate_id: string | null;
  room_rate: RoomsRateListData[] | undefined;
  token: string | null;
  extra: string;
}

export interface UpdateRoomTypePayload {
  token: string | null;
  room_id: string;
  name?: string;
  max_occupancy?: string | number;
  min_occupancy?: string | number;
  extra?: any | null;
  isOccupancyChanged?: boolean;
  default_occupancy?: number;
}

export interface CreateRatePlanPayload {
  token: string | null;
  name: string;
  description: string;
  rate_id: string;
  room_types: any[];
}

interface RoomRateData {
  id: string;
  pms_room: string;
  rate_id: string;
}

export interface UpdateRatePayload {
  token: string | null;
  name: string;
  description: string;
  other_rate: null;
  checkin: null;
  checkout: null;
  room_rate: RoomRateData[];
  rate_id: string;
  is_for_all_room_type: boolean;
  pms_id: number;
  id: number;
}

export interface ProfitReportResponse {
  success: boolean;
  message: string;
}

export interface EnableIframePayload {
  iframe_token: string;
  iframe_language: string | undefined;
  enable?: boolean;
}

export interface EnableIframeResponse {
  metadata: Record<string, any>;
  data: {
    enabled: boolean;
    language?: string | null;
    success: boolean;
    message: string;
  };
}

export interface HotelScrape {
  id: number;
  name: string;
  address: string;
  lat: number;
  lng: number;
}

export interface ScrapeResult {
  id: number;
  hotel: HotelScrape;
  is_active: boolean;
  max_price: number | null;
  min_price: number | null;
  avg_price: number;
  number_of_price: number;
  system_generated: boolean;
  avg_price_roomtypes: number;
  last_updated_at: string | null;
  number_of_price_4_week: number;
}

export interface ScrapeListResponse {
  count: number;
  current: number;
  next: string | null;
  previous: string | null;
  results: ScrapeResult[];
}

export interface BookingScraperResult {
  id: number;
  url: string;
  created: string;
  scrape_status: number;
}

export interface BookingScraperListResponse {
  count: number;
  current: number;
  next: string | null;
  previous: string | null;
  results: BookingScraperResult[];
}

interface PaymentMethod {
  type: string;
  status: string;
}

interface Invoice {
  status: string; // Can also be further specified if only certain values are allowed
  date: string;
  paid_at: string | null;
  is_error: boolean;
}

interface PaymentInfo {
  payment_method: PaymentMethod;
  payment_due: string;
  invoice: Invoice;
}

export interface SubscriptionInformationResponse {
  subscription_status: string;
  payment_info: PaymentInfo;
}
interface NoteAuthor {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

interface NoteResult {
  id: number;
  author: NoteAuthor;
  modified_at: string;
  created_at: string;
  note: string;
  hotel: number;
}

export interface NotesResponse {
  count: number;
  current: number;
  next: null | string;
  previous: null | string;
  results: NoteResult[];
}

export interface SSOProperty {
  code: string;
  name: string;
  desc: string;
  exist: boolean;
}

export interface SSOPropertiesResponse {
  data: {
    hotel_id: number;
    properties: SSOProperty[];
  };
}

export interface SSOActionPropertiesResponse {
  data: {
    id: number;
    token: string;
    user: User;
  };
}

export type SSOLoginPropertiesResponse = SSOActionPropertiesResponse;
export type SSORegisterPropertiesResponse = SSOActionPropertiesResponse;

export type HotelClientListParams = {
  page: number;
  ordering: string;
  country_filter?: number;
  plan?: number;
  state?: number;
  pms_provider?: number;
  deal_owner_filter?: number;
  deal_owner_type?: 'reseller' | 'sales_team';
  search?: string;
};

export enum AppPlanName {
  STARTER = 'Starter',
  ADVANCED = 'Advanced',
  PROFESSIONAL = 'Professional',
  SMALL_BUSINESS = 'Small Business',
  PRO_LITE = 'Pro Lite'
}

export enum SubscriptionLabel {
  FREE_TRIAL = 'Free Trial',
  SUBSCRIBED = 'Subscribed',
  CANCELLED = 'Cancelled',
  FREE_TRIAL_EXPIRED = 'Free Trial Expired',
  SUSPENDED = 'Suspended',
  NOT_ACTIVE = 'Not Active',
  SUBSCRIBED_P = 'Subscribed(P)',
  UNSUBSCRIBED_P = 'Unsubscribed(P)',
  DEMO_HOTEL = 'Demo Hotel',
  SUBSCRIPTION_PAUSED = 'Subscription Paused',
  SANDBOX = 'Sandbox'
}

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type HotelSubscriptionConfig = Record<
  number,
  { label: SubscriptionLabel; color: string; border: string }
>;

export const clientListSchema = z.object({
  page: z.number(),
  ordering: z.string(),
  country_filter: z.number().optional(),
  pms_provider: z.number().optional(),
  deal_owner_filter: z.number().optional(),
  deal_owner_type: z.enum(['reseller', 'sales_team']).optional(),
  plan: z.number().optional(),
  state: z.number().optional(),
  search: z.string().optional()
});

export const appliedFiltersSchema = z.object({
  country_filter: z.string().optional(),
  pms_provider: z.string().optional(),
  deal_owner_filter: z.string().optional(),
  deal_owner_type: z.enum(['reseller', 'sales_team']).optional(),
  plan: z.string().optional(),
  state: z.string().optional(),
  search: z.string().optional()
});

export type ClientListSchema = z.infer<typeof clientListSchema>;
export type AppliedFiltersSchema = z.infer<typeof appliedFiltersSchema>;

export const clientOrderingLookup: Record<string, string> = {
  id: 'id',
  hotel: 'name',
  email: 'client__email',
  country: 'country__name',
  address: 'address',
  plan: 'id',
  state: 'state',
  end_of_free_trial: 'client__free_trial_end',
  pms_cm: 'pms__provider',
  auto_upload: 'is_update_to_pms',
  last_upload: 'last_update_to_pms',
  last_login: 'hotel_analytic__last_login',
  deal_owner: 'id'
};

export interface HotelClientList {
  id: number;
  hotel: string;
  email: string;
  country: string;
  address: string;
  plans: string;
  state: number;
  end_of_free_trial: string;
  pms_cm: string;
  auto_upload: 'ON' | 'OFF';
  last_upload: string;
  last_login: string;
  deal_owner: string;
}

interface ReservationStatus {
  key: string;
  value: number;
}

export interface ReservationStatusResponse {
  pair_status: string[];
  reservation_status: ReservationStatus[];
}

export interface HotelAccount {
  language: string;
  hotel: Hotel;
  is_admin: boolean;
  do_not_call: boolean;
  hotel_list: HotelList[];
  client: Client;
  enc_hotel_id: string;
}

export interface HotelList {
  id: number;
  name: string;
  state: number;
  is_client_access_disabled: boolean;
}
