import { Navigate, RouteObject } from 'react-router-dom';
import { accountRoutes } from '@pages/Client/Account/routes';
import { calendarRoutes } from '@pages/Client/Calendar/routes';
import { dashboardRoutes } from '@pages/Client/Dashboard/routes';
import HealthReportLayout from '@pages/Client/HealthReport';
import { healthReportRoutes } from '@pages/Client/HealthReport/routes';
import { marketIntelligenceRoutes } from '@pages/Client/PricingStrategy/MarketIntelligence/routes';
import { occupancyRoutes } from '@pages/Client/PricingStrategy/OccupancyStrategy/routes';
import { referFriendRoutes } from '@pages/Client/ReferFriend/routes';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import { surgeProtectionRoutes } from '@pages/Client/PricingStrategy/SurgeProtection/routes';
import { minStayRoutes } from '@pages/Client/PricingStrategy/MinStay/routes';
import { Feature } from '@common/store/features';
import { priceChartRoutes } from '@pages/Client/PriceChart/routes';

import CalendarLayout from '@pages/Client/Calendar';
import DashboardLayout from '@pages/Client/Dashboard';
import PriceChartLayout from '@pages/Client/PriceChart';
import AccountLayout from '@pages/Client/Account';
import FeaturesPage from '@pages/Client/Features';
import NotesPage from '@pages/Client/Notes';
import DealsPage from '@pages/Client/Deal';
import UsersLogPage from '@pages/Client/UsersLog';
import SettingsLogPage from '@pages/Client/SettingsLog';
import PricingSchedulePage from '@pages/Client/PricingSchedule';
import OutboundApiLogPage from '@pages/Client/OutboundApiLog';
import InboundApiLogPage from '@pages/Client/InboundApiLog';
import ReservationApiLogPage from '@pages/Client/ReservationApiLog';
import RequestIframeURLPage from '@pages/Client/RequestIframeURL';
import ChangePasswordPage from '@pages/Client/ChangePassword';
import DeletePropertyPage from '@pages/Client/DeleteProperty';
import RoomSetupPage from '@pages/Client/PricingStrategy/RoomSetup';
import ReferFriendPage from '@pages/Client/ReferFriend';
import DayOfTheWeekAdjustmentPage from '@pages/Client/PricingStrategy/DayOfTheWeekAdjustments';
import MonthlyAdjustmentPage from '@pages/Client/PricingStrategy/MonthlyAdjustments';
import HelpPage from '@pages/Client/Help';
import OccupancyStrategyLayout from '@pages/Client/PricingStrategy/OccupancyStrategy';
import After365DaysPage from '@pages/Client/PricingStrategy/After365Days';
import YieldingTagsPage from '@pages/Client/PricingStrategy/YieldingTags';
import LeadTimePage from '@pages/Client/PricingStrategy/LeadTime';
import BarLevelsPage from '@pages/Client/PricingStrategy/BarLevels';
import SegmentationPage from '@pages/Client/PricingStrategy/Segmentation';
import MarketIntelligenceLayout from '@pages/Client/PricingStrategy/MarketIntelligence';
import SurgeProtectionLayout from '@pages/Client/PricingStrategy/SurgeProtection';
import NoAccessPage from '@pages/Client/NoAccess';
import Logout from '@pages/Client/Logout';
import MinStayPage from '@pages/Client/PricingStrategy/MinStay';
import { Error404 as Client404 } from '@pages/Client/Error/Error404';
import { ProtectedClientLayout } from '@pages/Client/ProtectedClientLayout';

export const clientRoutes: RouteObject[] = [
  {
    path: '/client',
    element: <ProtectedClientLayout />,
    children: [
      { index: true, element: <Navigate to="/client/calendar/:id" /> },
      {
        path: 'calendar/:id',
        element: <SecureRoute children={<CalendarLayout />} />,
        children: calendarRoutes
      },
      {
        path: 'dashboard/:id',
        element: (
          <SecureRoute
            children={<DashboardLayout />}
            feature={[
              Feature.DashboardOnly,
              Feature.LimitedDashboard,
              Feature.ProfessionalDashboard,
              Feature.LimitedMarketInsights,
              Feature.ProMarketInsights
            ]}
          />
        ),
        children: dashboardRoutes
      },
      {
        path: 'features/:id',
        element: <SecureRoute children={<FeaturesPage />} />
      },
      {
        path: 'chart/:id',
        element: <SecureRoute children={<PriceChartLayout />} />,
        children: priceChartRoutes
      },
      {
        path: 'deal/:id',
        element: <SecureRoute children={<DealsPage />} />
      },
      {
        path: 'customer-health/:id',
        element: <SecureRoute children={<HealthReportLayout />} />,
        children: healthReportRoutes
      },
      {
        path: 'notes/:id',
        element: <SecureRoute children={<NotesPage />} />
      },
      {
        path: 'users-log/:id',
        element: <SecureRoute children={<UsersLogPage />} />
      },
      {
        path: 'settings-log/:id',
        element: <SecureRoute children={<SettingsLogPage />} />
      },
      {
        path: 'run-algo/:id',
        element: <SecureRoute children={<PricingSchedulePage />} />
      },
      {
        path: 'outbound-api-log/:id',
        element: <SecureRoute children={<OutboundApiLogPage />} />
      },
      {
        path: 'inbound-api-log/:id',
        element: <SecureRoute children={<InboundApiLogPage />} />
      },
      {
        path: 'reservation-api-log/:id',
        element: <ReservationApiLogPage />
      },
      {
        path: 'request-iframe-url/:id',
        element: <SecureRoute children={<RequestIframeURLPage />} />
      },
      {
        path: 'change-password/:id',
        element: <SecureRoute children={<ChangePasswordPage />} />
      },
      {
        path: 'delete-property/:id',
        element: <SecureRoute children={<DeletePropertyPage />} />
      },
      {
        path: 'community/:id',
        element: <SecureRoute children={<ReferFriendPage />} />,
        children: referFriendRoutes
      },
      { path: 'help/:id', element: <HelpPage /> },
      {
        path: 'day-of-the-week/:id',
        element: <SecureRoute children={<DayOfTheWeekAdjustmentPage />} />
      },
      {
        path: 'monthly-settings/:id',
        element: <SecureRoute children={<MonthlyAdjustmentPage />} />
      },
      {
        path: 'account/:id',
        element: <SecureRoute children={<AccountLayout />} />,
        children: accountRoutes
      },
      {
        path: 'room-setup/:id',
        element: <SecureRoute children={<RoomSetupPage />} />
      },
      {
        path: 'occupancy/:id',
        element: <SecureRoute children={<OccupancyStrategyLayout />} />,
        children: occupancyRoutes
      },
      {
        path: 'after-year-setting/:id',
        element: (
          <SecureRoute children={<After365DaysPage />} feature={Feature.EighteenMonthsPricing} />
        )
      },
      {
        path: 'yielding-tags/:id',
        element: <SecureRoute children={<YieldingTagsPage />} feature={Feature.YieldTag} />
      },
      {
        path: 'lead-time-adjustments/:id',
        element: <SecureRoute children={<LeadTimePage />} feature={Feature.LeadTimeAdjustment} />
      },
      {
        path: 'surge-event/:id',
        element: (
          <SecureRoute feature={Feature.SurgeProtection} children={<SurgeProtectionLayout />} />
        ),
        children: surgeProtectionRoutes
      },
      {
        path: 'min-stay/:id',
        element: (
          <SecureRoute
            children={<MinStayPage />}
            feature={[Feature.MinimumStayRestrictions, Feature.LimitedMinimumStayRestrictions]}
          />
        ),
        children: minStayRoutes
      },
      {
        path: 'rate-settings/:id',
        element: <SecureRoute children={<BarLevelsPage />} feature={Feature.BarLevels} />
      },
      {
        path: 'competitors/:id',
        element: <SecureRoute children={<MarketIntelligenceLayout />} />,
        children: marketIntelligenceRoutes
      },
      {
        path: 'segmentation/:id',
        element: <SecureRoute children={<SegmentationPage />} feature={Feature.Segmentation} />
      },
      { path: 'no-access/:id', element: <NoAccessPage /> },
      { path: 'logout', element: <Logout /> },
      { path: '404', element: <Client404 /> },
      { path: '*', element: <Navigate to="/client/404" replace /> }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/client/404" replace />
  }
];
