import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Input } from '@common/components/atoms/Input';
import { Button } from '@common/components/atoms/Button';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { useRecoveryPassword } from '@pages/Auth/ResetPassword/hooks/useRecoveryPassword';

const schema = z.object({
  email: z.string().email({ message: 'Please enter a valid email address.' })
});

export function ResetPassword() {
  const { mutate: recoveryPassword } = useRecoveryPassword();
  const { handleSubmit, control, getFieldState, watch } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });
  const navigate = useNavigate();
  const { email } = watch();

  const handleSendResetPassword = () => {
    recoveryPassword({ email });
  };

  return (
    <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full 2xl:w-3/4">
        <div>
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
          <Typography element="h2" variant="h4" className="font-medium" color="darkGrey">
            Need a new password?
          </Typography>
          <Typography element="p" variant="meta-1" className="mt-2" color="darkGrey">
            Please provide your email address and we&apos;ll send you instructions on how to change
            your password.
          </Typography>
        </div>

        <div className="mt-8">
          <div className="mt-6">
            <form onSubmit={handleSubmit(handleSendResetPassword)}>
              <div className="space-y-6">
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        type="email"
                        label="Email*"
                        placeholder="Email"
                        value={value}
                        error={invalid}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={error ? <Icon.WarningOutline className="h-3 w-3" /> : null}
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                        showClearButton={false}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="mt-8 flex flex-col space-y-4">
                <Button
                  intent="primary"
                  size="large"
                  type="submit"
                  disabled={getFieldState('email').invalid}>
                  Reset Password
                </Button>
                <Button
                  type="button"
                  intent="outline"
                  size="large"
                  onClick={() => navigate('/login')}>
                  Go back to Login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
