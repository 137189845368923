import { MantineColorsTuple } from '@mantine/core';

const indigo: MantineColorsTuple = [
  '#eeebff',
  '#d8d3ff',
  '#ada4f9',
  '#8072f3',
  '#5a47ee',
  '#422ceb',
  '#341deb',
  '#2711d2',
  '#1f0ebc',
  '#140aa7'
];

const green: MantineColorsTuple = [
  '#ecfef8',
  '#d8fbf1',
  '#abf9e0',
  '#7cf6cf',
  '#5bf4c0',
  '#49f3b7',
  '#3ff2b2',
  '#33d89c',
  '#26bf8a',
  '#08a675'
];

const red: MantineColorsTuple = [
  '#ffebe9',
  '#fdd7d4',
  '#f3aea7',
  '#eb8078',
  '#e45b50',
  '#e04337',
  '#df3629',
  '#c7281c',
  '#b22018',
  '#9c1511'
];

const orange: MantineColorsTuple = [
  '#ffede3',
  '#ffd9cc',
  '#ffb39a',
  '#ff8964',
  '#fe6737',
  '#fe5019',
  '#ff4409',
  '#e33500',
  '#cb2d00',
  '#b12100'
];

const gold: MantineColorsTuple = [
  '#fff6e2',
  '#fcedce',
  '#f6daa3',
  '#efc573',
  '#eab44a',
  '#e7a930',
  '#e6a31f',
  '#cd8e11',
  '#b67e08',
  '#9e6c00'
];

const gray: MantineColorsTuple = [
  '#F4F4F4', // lightest gray
  '#E8E8E8', // lighter gray
  '#D1D1D1', // light gray
  '#BABABA', // medium light gray
  '#A3A3A3', // above neutral gray
  '#949494', // specified gray
  '#858585', // slightly darker gray
  '#767676', // medium dark gray
  '#676767', // darker gray
  '#585858' // dark gray (balanced darkness)
];

const lightGray: MantineColorsTuple = [
  '#fcfcfc', // 50
  '#efefef', // 100
  '#dcdcdc', // 200
  '#bdbdbd', // 300
  '#989898', // 400
  '#7c7c7c', // 500
  '#656565', // 600
  '#525252', // 700
  '#464646', // 800
  '#3d3d3d', // 900
  '#292929' // 950
];

export { indigo, green, red, orange, gold, gray, lightGray };
