import { Checkbox } from '@mantine/core';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { Icon } from '@common/components/foundations/icons';
import { pushPmsList } from '@common/constants/pmsList';
import { useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDiagnosticsStore } from '@pages/Client/Calendar/store/diagnostics';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

enum PricingOptionValue {
  ThreeMonths = 3,
  SixMonths = 4,
  OneYear = 5,
  OneHalfYear = 6
}

/**
 * Note: These values are hard-coded to send to API
 */
const OPTIONS = [
  {
    label: '3 Months',
    value: PricingOptionValue.ThreeMonths
  },
  {
    label: '6 Months',
    value: PricingOptionValue.SixMonths
  },
  {
    label: '12 Months',
    value: PricingOptionValue.OneYear
  },
  {
    label: '18 Months',
    value: PricingOptionValue.OneHalfYear
  }
] as const;

export type PricingOptions = (typeof OPTIONS)[number]['value'];

type RefHandler = {
  runPricingOption: PricingOptions;
  diagnostics: boolean;
  getLatestPMS: boolean;
};

export const RunPricing = forwardRef<RefHandler>((_, ref) => {
  const { t } = useTranslation();
  const { view } = useViewStore();
  const { features } = useFeaturesStore();
  const [runPricingOption, setRunPricingOption] = useState<PricingOptions>(OPTIONS[0].value);
  const [diagnostics, setDiagnosticsOption] = useState(false);
  const [getLatestPMS, setPMSOption] = useState(false);
  const { hotelDetails, pmsChannelManagerText } = useHotelDetails();
  const { setIsDiagnosticsEnabled } = useDiagnosticsStore();

  const allowGetLatestPMS = useMemo(() => {
    if (!hotelDetails) return false;

    return !pushPmsList.includes(hotelDetails.pms_provider);
  }, [hotelDetails]);

  useEffect(() => {
    if (view === 'client' && allowGetLatestPMS) {
      setPMSOption(true);
    }
  }, [view, allowGetLatestPMS]);

  useEffect(() => {
    if (diagnostics) {
      setIsDiagnosticsEnabled(diagnostics);
    }
  }, [diagnostics, setIsDiagnosticsEnabled]);

  useImperativeHandle(ref, () => ({
    runPricingOption,
    diagnostics,
    getLatestPMS
  }));

  return (
    <div>
      <Icon.GenieCircle />
      <h1 className="mt-3 text-h6 font-medium">{t('Run Pricing')}</h1>

      <div className="mt-3 text-paragraph-3 text-grey">
        {t(
          'Your prices are optimized multiple times a day based on your latest PMS data. Use this manual pricing run if you want to refresh the data.'
        )}
      </div>

      <div className="mt-4 flex items-center gap-x-4 text-paragraph-3">
        {view === 'admin' ? (
          <Checkbox
            label={t('Diagnostics')}
            checked={diagnostics}
            onChange={(e) => setDiagnosticsOption(e.target.checked)}
          />
        ) : null}

        {view === 'admin' && allowGetLatestPMS && (
          <Checkbox
            label={t(`Get latest ${pmsChannelManagerText} data`)}
            checked={getLatestPMS}
            onChange={(e) => setPMSOption(e.target.checked)}
          />
        )}
      </div>

      <RadioGroup
        className="mt-5"
        type="block"
        onChange={(val) => setRunPricingOption(val as PricingOptions)}
        value={runPricingOption}
        options={[
          ...OPTIONS.filter(
            (option) =>
              !(option.value === PricingOptionValue.OneHalfYear && !features?.includes(23))
          ).map((option) => ({ label: t(option.label), value: option.value }))
        ]}
      />
    </div>
  );
});
