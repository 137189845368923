import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const ReferFriendPage = () => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    formState: { errors },
    getValues
  } = useFormContext();
  console.log(errors);
  return (
    <div className="rounded-sm bg-white p-6">
      <Typography>
        {t(
          'As a growing company, we really appreciate your support. And the referrals we get from happy customers are one of the most fulfilling ways we keep growing. They make us smile a lot!'
        )}{' '}
        <br />
        {t(
          'All you need to do is fill in the form here, with details of someone you think would appreciate RoomPriceGenie, and we will contact them with your message.'
        )}
        <br />
        {t(
          'As a small sign of our gratitude, we would like to offer you a $50 Amazon voucher for anyone you refer that has a trial with us, plus one month free if they subscribe.'
        )}
        <br />
        {t('Thank you in advance for the smiles.')}{' '}
      </Typography>
      <div className="mt-4 grid grid-cols-1 gap-4 py-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
        <div className="flex flex-col gap-4">
          <Controller
            name="friend_first_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                background="grey"
                name="friend_first_name"
                type="text"
                value={value}
                label={`${t("Friend's First Name")} *`}
                placeholder={`${t("Please enter Friend's first name")}`}
                onChange={onChange}
                onClear={() => reset({ friend_first_name: '' })}
                error={!!error}
                helperMessage={
                  errors && (
                    <div className="flex items-center gap-2 text-error">
                      {errors.friend_first_name ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {errors.friend_first_name
                          ? errors?.friend_first_name?.message?.toString()
                          : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
          <Controller
            name="friend_email"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                background="grey"
                label={`${t("Friend's Email")} *`}
                name="friend_email"
                type="text"
                placeholder={`${t("Please enter Friend's email")}`}
                value={value}
                onChange={onChange}
                onClear={() => reset({ friend_email: '' })}
                error={!!error}
                helperMessage={
                  errors && (
                    <div className="flex items-center gap-2 text-error">
                      {errors.friend_email ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {errors.friend_email ? errors?.friend_email?.message?.toString() : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
          <Controller
            name="first_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                background="grey"
                label={`${t('Your First Name')} *`}
                name="first_name"
                type="text"
                placeholder={`${t('Please enter your first name')}`}
                value={value}
                onChange={onChange}
                onClear={() => reset({ first_name: '' })}
                error={!!error}
                helperMessage={
                  errors && (
                    <div className="flex items-center gap-2 text-error">
                      {errors.first_name ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {errors.first_name ? errors?.first_name?.message?.toString() : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Controller
            name="friend_last_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                background="grey"
                label={`${t("Friend's Last Name")} *`}
                name="friend_last_name"
                type="text"
                placeholder={`${t("Please enter Friend's last name")}`}
                value={value}
                onChange={onChange}
                onClear={() => reset({ friend_last_name: '' })}
                error={!!error}
                helperMessage={
                  errors && (
                    <div className="flex items-center gap-2 text-error">
                      {errors.friend_last_name ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {errors.friend_last_name
                          ? errors?.friend_last_name?.message?.toString()
                          : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
          <Controller
            name="friend_property_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                background="grey"
                label={(t("Friend's Property Name") as string) + '*'}
                name="friend_property_name"
                type="text"
                placeholder={`${t("Please enter Friend's Property Name")}`}
                value={value}
                onChange={onChange}
                onClear={() => reset({ friend_property_name: '' })}
                error={!!error}
                helperMessage={
                  errors && (
                    <div className="flex items-center gap-2 text-error">
                      {errors.friend_property_name ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {errors.friend_property_name
                          ? errors?.friend_property_name?.message?.toString()
                          : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                background="grey"
                label={`${t('Your Last Name')}*`}
                name="last_name"
                type="text"
                placeholder={`${t('Please enter your last name')}`}
                value={value}
                onChange={onChange}
                onClear={() => reset({ last_name: '' })}
                error={!!error}
                helperMessage={
                  errors && (
                    <div className="flex items-center gap-2 text-error">
                      {errors.last_name ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {errors.last_name ? errors?.last_name?.message?.toString() : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
        </div>
      </div>
      <div className="mb-2 w-full">
        <Typography element="p" variant="meta-1" className="mb-1 text-grey">
          {t('Your Personal Message')} *
        </Typography>
        <Controller
          name="message"
          control={control}
          defaultValue={getValues('message')}
          render={({ field: { onChange, value } }) => (
            <textarea
              rows={4}
              name="message"
              onChange={onChange}
              value={value}
              className="text-gray-900 placeholder:text-gray-400 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-grey ring-opacity-30  focus:ring-[2px] focus:ring-indigo  focus:ring-opacity-30 sm:text-sm sm:leading-6"
            />
          )}
        />
        {errors.message && (
          <div className="flex items-center gap-2 text-error">
            {errors.message ? <Icon.WarningOutline className="w-4" /> : ''}
            <Typography element="span" color="error" variant="meta-2">
              {errors.message ? errors?.message?.message?.toString() : ''}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
