import { isEmpty, map, filter, kebabCase } from 'lodash-es';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  ADJUSTMENT,
  ADJUSTMENT_DB
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { useBulkAdjustmentOptions } from '@pages/Client/Calendar/hooks/useBulkAdjustmentOptions';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useTranslation } from 'react-i18next';
import { Card, Checkbox, Stack, Text } from '@mantine/core';
import { BulkEditUserflowIds } from '@common/types/userflow-ids';

type RemovableAdjustments = Exclude<ADJUSTMENT, ADJUSTMENT.ADJUSTMENT_TYPE>;
type FieldListType = { id: string; label: string; value: boolean; meta: ADJUSTMENT_DB[] };

const findOptionAndIndex = (
  field: FieldListType,
  fields: Record<'id', string>[],
  options: any[]
) => {
  const originalIndex = fields.findIndex((f) => f.id === field.id);
  const option = options[originalIndex];

  if (!option) {
    console.warn(`No matching option found for field with id: ${field.id}`);
    return null;
  }

  return { option, originalIndex };
};

export function AdjustmentsCheckboxes() {
  const { t } = useTranslation();
  const fieldName = 'adjustments';
  const { control, register } = useFormContext();
  const { hotelDetails } = useHotelDetails();
  const { adjustmentOptionsWithMinStaySkipRule } = useBulkAdjustmentOptions();
  const { fields, replace } = useFieldArray({ name: fieldName, control: control });

  const removeConfig: Record<RemovableAdjustments, ADJUSTMENT_DB[]> = {
    [ADJUSTMENT.AGGRESSIVENESS]: [ADJUSTMENT_DB.AGGRESSIVENESS_KEY],
    [ADJUSTMENT.CLOSED]: [ADJUSTMENT_DB.CLOSED_KEY],
    [ADJUSTMENT.DERIVATION]: [ADJUSTMENT_DB.DERIVATION_KEY],
    [ADJUSTMENT.FIX_PRICES]: [ADJUSTMENT_DB.SKIP_DATE_KEY, ADJUSTMENT_DB.FIX_PRICES_KEY],
    [ADJUSTMENT.MEDIAN]: [ADJUSTMENT_DB.MEDIAN_KEY],
    [ADJUSTMENT.MIN_MAX]: [ADJUSTMENT_DB.MIN_PRICE_KEY, ADJUSTMENT_DB.MAX_PRICE_KEY],
    [ADJUSTMENT.MIN_STAY]: [ADJUSTMENT_DB.MIN_STAY_KEY],
    [ADJUSTMENT.MIN_STAY_SKIP_RULE]: [ADJUSTMENT_DB.IS_SKIP_MIN_STAY_RULE],
    [ADJUSTMENT.OCCUPANCY]: [ADJUSTMENT_DB.OCCUPANCY_KEY],
    [ADJUSTMENT.PERCENT]: [ADJUSTMENT_DB.INDIVIDUAL_ADJUSTMENT_KEY, ADJUSTMENT_DB.PERCENT_KEY]
  };

  useEffect(() => {
    const adjustments = map(adjustmentOptionsWithMinStaySkipRule, ({ id, label }) => ({
      label: label,
      value: false,
      meta: removeConfig[id as RemovableAdjustments]
    }));
    if (!isEmpty(adjustments)) replace(adjustments);
  }, [hotelDetails]);

  return (
    <Stack mt="md">
      {isEmpty(fields) ? <Text size="sm">{t('No adjustments options available')}</Text> : null}

      {map(
        filter(
          fields,
          (field: FieldListType) =>
            !(
              field.meta?.includes(ADJUSTMENT_DB.MIN_STAY_KEY) ||
              field.meta?.includes(ADJUSTMENT_DB.IS_SKIP_MIN_STAY_RULE)
            )
        ),
        (field: FieldListType) => {
          const result = findOptionAndIndex(field, fields, adjustmentOptionsWithMinStaySkipRule);
          if (!result) return null;

          const { option, originalIndex } = result;

          return (
            <div
              data-userflow-id={`${BulkEditUserflowIds.ADJUSTMENT_OPTION_PREFIX}-${kebabCase(
                option.label
              )}`}>
              <Checkbox
                key={field.id}
                label={t(option?.label)}
                {...register(`${fieldName}.${originalIndex}.value`)}
              />
            </div>
          );
        }
      )}

      {adjustmentOptionsWithMinStaySkipRule.some(
        (option) => option.id === ADJUSTMENT.MIN_STAY_SKIP_RULE || option.id === ADJUSTMENT.MIN_STAY
      ) ? (
        <Card bg="gray.0">
          <Text size="xs" fw="bold">
            {t('Minimum Stay Restrictions')}
          </Text>
          <Text size="xs">
            {t(
              "You can adjust the length of 'Minimum Stay Restrictions' using Bulk Edit or by making daily adjustments under the Edit Prices tab. To remove them completely, check 'Min Stay' below."
            )}
          </Text>
          <Stack mt="md">
            {map(
              filter(
                fields,
                (field: FieldListType) =>
                  field.meta?.includes(ADJUSTMENT_DB.MIN_STAY_KEY) ||
                  field.meta?.includes(ADJUSTMENT_DB.IS_SKIP_MIN_STAY_RULE)
              ),
              (field: FieldListType) => {
                const result = findOptionAndIndex(
                  field,
                  fields,
                  adjustmentOptionsWithMinStaySkipRule
                );
                if (!result) return null;

                const { option, originalIndex } = result;

                if (field.meta?.includes(ADJUSTMENT_DB.MIN_STAY_KEY)) {
                  return (
                    <Checkbox
                      key={field.id}
                      label={t('Min Stay')}
                      {...register(`${fieldName}.${originalIndex}.value`)}
                    />
                  );
                }

                return (
                  <Checkbox
                    key={field.id}
                    label={t(option?.label)}
                    {...register(`${fieldName}.${originalIndex}.value`)}
                  />
                );
              }
            )}
          </Stack>
        </Card>
      ) : null}
    </Stack>
  );
}
