import { Badge } from '@common/components/atoms/Badge';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';

import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Colors } from '@common/components/foundations/colorVariants';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { browserTimezone } from '@common/utils/browserTimezone';
import { debounce } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import {
  useDownloadInboundApiLogs,
  useGetInboundApiLogs
} from '@pages/Client/InboundApiLog/hooks/useInboundApiLog';
import React from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { cn } from '@common/utils/cn';

dayjs.extend(utc);
dayjs.extend(timezone);

const schema = z.object({
  page: z.number(),
  search: z.string().optional(),
  request_type: z.number().optional(),
  date: z.string().optional(),
  ordering: z.string().optional()
});

interface HeaderProps {
  id: string;
  desc: boolean;
}

type Params = z.infer<typeof schema>;

const filterEmptyValues = (params: Params): Params => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_key, value]) => value !== '' && value !== undefined && value !== null
    )
  ) as Params;
};

const REQUEST_TYPE = [
  { value: 1, label: 'Reservation', background: 'gold' },
  { value: 2, label: 'OTA_HotelInvBlockNotifRQ', background: 'darkGreen' },
  { value: 3, label: 'Availability (OTA)', background: 'uiRed' },
  { value: 4, label: 'OTA_HotelStatsNotifRQ', background: 'darkGreen' },
  { value: 5, label: 'OTA_HotelAvailNotifRQ', background: 'darkGreen' },
  { value: 6, label: 'Rates (OTA)', background: 'orange' },
  { value: 9, label: 'Rates', background: 'orange' },
  { value: 10, label: 'Pro (OTA)', background: 'indigo' },
  { value: 12, label: 'Availability', background: 'error' },
  { value: 13, label: 'Pro', background: 'uiGreen' },
  { value: 14, label: 'RoomRate Mapping Request', background: 'darkGrey' }
];

export const InboundApiLog = () => {
  useDocumentTitle('Inbound API Log');
  const defaultValues = {
    page: 1,
    search: undefined,
    request_type: undefined,
    date: undefined,
    ordering: '-created'
  };

  const { hotelDetails } = useHotelDetails();

  const { watch, reset, control, getValues, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });
  const { t } = useTranslation();

  const {
    hotelInboundApiLogs,
    query: { isLoading },
    refetchLogs,
    isRefetchLoading
  } = useGetInboundApiLogs(
    filterEmptyValues({
      request_type: watch('request_type'),
      page: watch('page'),
      date: watch('date') ? dayjs(watch('date')).format('YYYY-MM-DD') : undefined,
      search: getValues('search'),
      ordering: watch('ordering')
    })
  );
  const [indexLoading, setIndexLoading] = useState(null);
  const [clickedButtons, setClickedButtons] = useState<string[]>([]);

  const { downloadMetadataInboundLog, isLoading: isDownloadMetaLoading } =
    useDownloadInboundApiLogs();

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, defaultValues, filterEmptyValues]
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
    setValue('page', 1);
  };

  const ROWS_PER_PAGE = 20;

  const startIndex = hotelInboundApiLogs?.current
    ? (hotelInboundApiLogs.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    hotelInboundApiLogs?.current && hotelInboundApiLogs.results
      ? startIndex + (hotelInboundApiLogs?.results?.length || 0) - 1
      : 0;

  const handleNext = () => {
    if (!hotelInboundApiLogs?.next) return;
    const params = new URLSearchParams(hotelInboundApiLogs.next.split('?')[1]);
    const page = params.get('page');
    const date = params.get('date');
    const search = params.get('search');
    const ordering = params.get('ordering');
    const request_type = params.get('request_type');
    if (!page) return;
    reset(
      filterEmptyValues({
        page: Number(page),
        date: date || undefined,
        search: search || undefined,
        request_type: (request_type as unknown as number) || undefined,
        ordering: ordering || undefined
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: previousPage
      })
    );
  };

  const columns = [
    {
      header: 'Received at',
      accessorKey: 'created',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex flex-col">
                    <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                      {dayjs(row?.getValue()).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}
                    </Typography>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <table className="table-auto">
                    <tbody>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Hotel Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(hotelDetails?.timezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Browser Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(browserTimezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Messages Type',
      accessorKey: 'request_type',
      cell: (row: any) => {
        const requestType = row?.getValue() as string;
        const requestTypeInfo = REQUEST_TYPE.find((type) => type.label === requestType);
        return (
          <div className="flex items-center gap-3">
            <Badge background={(requestTypeInfo?.background as Colors) || 'grey'}>
              {requestType}
            </Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Corelation Id',
      accessorKey: 'correlation_id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" variant="paragraph-3" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Dates',
      accessorKey: 'date_list',
      cell: (row: any) => {
        const dateList = row?.getValue() !== null ? (row?.getValue() as string[]) : [];
        return (
          <div className="max-w[100px] flex items-center gap-3 overflow-hidden">
            <div className="flex items-center gap-3">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                      {dateList?.slice(0, 3)?.join(',')}
                      {dateList?.length > 3 && '...'}
                    </Typography>
                  </TooltipTrigger>
                  <TooltipContent side="bottom" sideOffset={10} className="w-full">
                    <span className="max-w-sm">
                      {dateList?.map((value, index, arr) => (
                        <React.Fragment key={index}>
                          {value}
                          {index !== arr.length - 1 && ','}
                          {(index + 1) % 4 === 0 && <br />}
                        </React.Fragment>
                      ))}
                    </span>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Room Types',
      accessorKey: 'room_type_list',
      cell: (row: any) => {
        const rate_plans = row?.getValue() !== null ? (row?.getValue() as string[]) : [];
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" variant="paragraph-3" color="copyTextGrey">
              {rate_plans.join(', ')}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Rate Types',
      accessorKey: 'rate_type_list',
      cell: (row: any) => {
        const rate_types = row?.getValue() !== null ? (row?.getValue() as string[]) : [];

        return (
          <div className="flex items-center gap-3">
            <Typography element="span" variant="paragraph-3" color="copyTextGrey">
              {rate_types.join(', ')}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Download',
      accessorKey: 'url',
      cell: (row: any) => {
        const isHasMetaData = row?.row?.original?.has_metadata as boolean;
        const logId = row?.row?.original?.id as string;
        const { index } = row.row;
        const downloadFile = (url: string) => {
          const fileName = url.substring(url.lastIndexOf('/') + 1);
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.download = fileName;
          link.click();
        };
        return (
          <div className="flex flex-col items-center gap-2">
            <Button
              size="small"
              intent="text"
              className={cn(
                'text-indigo text-opacity-90',
                clickedButtons.includes(`download-${logId}`) ? 'text-opacity-40' : ''
              )}
              onClick={() => {
                downloadFile(row?.getValue() as string);
                setClickedButtons([...clickedButtons, `download-${logId}`]);
              }}>
              <Icon.Download className="h-4 w-4" />
              Download
            </Button>
            {isHasMetaData && (
              <Button
                size="small"
                intent="text"
                className={cn(
                  'text-darkGrey text-opacity-90',
                  clickedButtons.includes(`metadata-${logId}`) ? 'text-opacity-40' : ''
                )}
                isLoading={indexLoading === index && isDownloadMetaLoading}
                onClick={() => {
                  setIndexLoading(index);
                  downloadMetadataInboundLog(logId);
                  setClickedButtons([...clickedButtons, `metadata-${logId}`]);
                }}>
                <Icon.Download className="h-4 w-4" />
                Metadata
              </Button>
            )}
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  return (
    <Page header={<Header title="Inbound Api Logs" />}>
      <Table
        isHover
        isFetching={isLoading}
        skeletonCount={20}
        columns={columns}
        data={!isLoading ? (hotelInboundApiLogs?.results as any) : []}
        next={handleNext}
        isNext={!!hotelInboundApiLogs?.next}
        previous={handlePrevious}
        isPrevious={watch('page') > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={hotelInboundApiLogs?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        manualSorting={true}
        onSortingChange={(value) => {
          const values = value as HeaderProps[];
          if (
            values[0].id === 'room_type_list' ||
            values[0].id === 'date_list' ||
            values[0].id === 'url' ||
            values[0].id === 'rate_type_list'
          )
            return;
          if (watch('ordering') === values[0].id) {
            setValue('ordering', `-${values[0].id}`);
          } else {
            setValue('ordering', values[0].id);
          }
        }}
        sorting={[
          {
            id: 'created',
            desc: watch('ordering') === 'created'
          },
          {
            id: 'request_type',
            desc: watch('ordering') === 'request_type'
          },
          {
            id: 'correlation_id',
            desc: watch('ordering') === 'correlation_id'
          }
        ]}
        actionComponents={
          <div className="flex items-center gap-6">
            <div>
              <Controller
                name="search"
                control={control}
                render={({ field: { value, name, onChange } }) => (
                  <Input
                    placeholder="Search"
                    background="grey"
                    name={name}
                    id={name}
                    type="text"
                    value={value}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      onChange(e);
                    }}
                    onClear={() => {
                      reset(defaultValues);
                    }}
                  />
                )}
              />
            </div>
            <div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Button icon onClick={refetchLogs} className="group relative">
                      {isRefetchLoading ? (
                        <Icon.LoadingCircle className="mr-0 h-6 w-6" />
                      ) : (
                        <Icon.Refresh />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="top" className="bg-white text-darkGrey">
                    Reload
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div>
              <RPGPopover>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <RPGPopover.Button as={Button} type="button" icon className="group relative">
                        <Icon.FilterList />
                      </RPGPopover.Button>
                    </TooltipTrigger>
                    <TooltipContent side="top" className="bg-white text-darkGrey">
                      Filter
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <RPGPopover.Panel>
                  <div className="mb-2 flex items-center gap-3">
                    <Typography className="text-meta-1-medium" color="darkGrey">
                      {t('Filters')}
                    </Typography>
                    <Button type="button" intent="text" onClick={() => reset(defaultValues)}>
                      {t('Reset')}
                    </Button>
                  </div>
                  <div className="grid grid-cols-1 gap-2">
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      Messages Type
                    </Typography>
                    <Controller
                      control={control}
                      name="request_type"
                      render={({ field: { value, name, onChange } }) => (
                        <SelectDropdown
                          fullWidth
                          placeholder="Select Request Type"
                          name={name}
                          key={name}
                          value={value || ''}
                          options={REQUEST_TYPE}
                          onChange={(value) => {
                            onChange(value);
                            onResetAndSet('request_type', value);
                            setValue('page', 1);
                          }}
                        />
                      )}
                    />
                  </div>
                </RPGPopover.Panel>
              </RPGPopover>
            </div>
            <div>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange } }) => (
                  <DatePicker
                    ISOWeek={hotelDetails?.starts_monday}
                    onDateChange={(date) => {
                      onChange(date);
                      setValue('page', 1);
                    }}
                    timezone={hotelDetails?.timezone}
                    closeOnSelect
                  />
                )}
              />
            </div>
          </div>
        }
      />
    </Page>
  );
};
