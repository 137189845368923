import { InviteUser } from '@common/api/account/types';
import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Modal } from '@common/components/molecules/Modal';
import { LANGUAGES } from '@common/constants/language';
import { Checkbox } from '@mantine/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { useInviteUser } from '@pages/Client/Account/User/hooks/useUser';
import { ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { isNil } from 'lodash-es';
import { useGetAccountHotel } from '@pages/Client/hooks/useGetAccountHotel';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};
interface HotelList {
  id: number;
  name: string;
  state: number;
  is_client_access_disabled: boolean;
}
interface HotelAccessObject {
  [key: string]: string;
}

export const InviteModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const schema = z.object({
    email: z
      .string()
      .email({ message: `${t('Please enter a valid email address')}` })
      .nonempty(),
    language: z.string().nonempty({ message: `${t('Please enter a valid email address')}` }),
    property_list: z.array(z.number()).refine((val) => val.length > 0, {
      message: `${t('Please select at least one property')}`
    }),
    user_type: z.string().nonempty()
  });

  const { handleSubmit, control, setValue, reset, formState, watch } = useForm<
    z.infer<typeof schema>
  >({
    resolver: zodResolver(schema),
    defaultValues: {
      user_type: '2'
    }
  });

  const { mutate: inviteUser, isPending: isLoading, isSuccess } = useInviteUser();

  const { data: hotelData } = useGetAccountHotel();

  const hotel_list = hotelData?.hotel_list ?? [];
  const [isAssignToAllProperties, setIsAssignToAllProperties] = useState(false);

  const hotelList = hotel_list.map((item: HotelList) => ({
    label: item.name,
    value: item.id
  }));

  const sortHotelListAlphabetically = (hotelList: { label: string; value: number }[]) => {
    return hotelList.sort((a, b) => a.label.localeCompare(b.label));
  };

  const sortedHotelList = sortHotelListAlphabetically(hotelList);

  const ROLE_OPTIONS = [
    {
      label: t('Admin'),
      value: '3'
    },
    {
      label: t('Manager'),
      value: '1'
    },
    {
      label: t('Staff'),
      value: '2'
    }
  ];

  const reMapHotelAccess = (hotelIds: number[]): HotelAccessObject => {
    const result = hotelIds.reduce((acc, id) => {
      const { label } = sortedHotelList.find((item) => item.value === id) || {};
      return label ? { ...acc, [id.toString()]: label } : acc;
    }, {} as HotelAccessObject);
    return result;
  };

  const handleAssignToAllProperties = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsAssignToAllProperties(isChecked);
    if (isChecked) {
      setValue(
        'property_list',
        hotelList?.map((item) => item.value)
      );
    } else {
      setValue('property_list', []);
    }
  };

  const compareUserId = (allUserId: number[], addUserId: number[]): boolean => {
    return (
      allUserId.every((value) => addUserId.includes(value)) &&
      addUserId.every((value) => allUserId.includes(value))
    );
  };

  const onInvite = (data: any) => {
    const inviteData: InviteUser = {
      ...data,
      property_list: reMapHotelAccess(data.property_list)
    };
    inviteUser(inviteData);
    onClose();
    reset();
  };

  return (
    <Modal
      onClick={handleSubmit(onInvite)}
      isLoading={isLoading}
      onInvalid={!isSuccess}
      disableCloseOnOk={!formState.isValid || !formState.isDirty || !isNil(formState.errors)}
      open={isOpen}
      okText={`${t('Send Invite')}`}
      onClose={onClose}
      size={'xl'}>
      <div className="flex min-w-[400px] flex-col items-start gap-4">
        <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
          {t('Invite User')}
        </Typography>
        <Controller
          name="user_type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              optionsWrapperClassName="grid grid-cols-3 gap-x-4 gap-y-2"
              value={value}
              onChange={onChange}
              options={ROLE_OPTIONS}
            />
          )}
        />
        <div className="flex w-full flex-col items-start justify-between gap-4">
          <div className="w-full items-start">
            <div>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                  <Input
                    disabled={false}
                    type="email"
                    background="grey"
                    tabIndex={1}
                    label={`${t('User Email')}*`}
                    placeholder={`${t('Please enter Email')}`}
                    value={value || ''}
                    error={invalid}
                    helperMessage={
                      invalid && (
                        <InputHelperMessage
                          icon={error ? <Icon.WarningOutline className="w-4 fill-uiRed" /> : null}
                          message={error?.message}
                        />
                      )
                    }
                    onChange={onChange}
                    onClear={() => {
                      setValue('email', '');
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full items-start">
            <Typography element="p" variant="meta-1" className="mb-1 text-grey">
              {t('Language')} *
            </Typography>
            <Controller
              name="language"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <SelectDropdown
                    fullWidth
                    name="language"
                    placeholder="Select Language"
                    options={LANGUAGES}
                    background="grey"
                    value={value}
                    onChange={onChange}
                  />
                  <div className="mt-1">
                    {error && (
                      <div className="ml-2 flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </div>
        </div>
        <div className="w-full">
          <Controller
            name="property_list"
            control={control}
            render={({ field: { value, onChange, name }, fieldState: { error } }) => (
              <>
                <Typography element="p" variant="meta-1" className="text-grey">
                  {t('Property Access')}*
                </Typography>
                <MultiSelect
                  fullWidth
                  isInitialValue
                  background="grey"
                  inputClassName="py-[14px]"
                  name={name}
                  placeholder={`${t('Select Property Access')}`}
                  value={value}
                  onChange={onChange}
                  options={sortedHotelList}
                />
                <div className="mt-1">
                  {error && (
                    <div className="ml-2 flex items-center gap-2 text-error">
                      {error ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {error ? error.message?.toString() : ''}
                      </Typography>
                    </div>
                  )}
                </div>
              </>
            )}
          />
        </div>
        {watch('user_type') === '3' ? (
          <div className="flex items-center gap-1.5 ">
            <Checkbox
              label={t('Assign to All Properties')}
              id="assign-all-properties"
              name="assign-all-properties"
              checked={
                isAssignToAllProperties &&
                compareUserId(
                  watch('property_list'),
                  hotelList?.map((item) => item.value)
                )
              }
              onChange={handleAssignToAllProperties}
            />
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
