// TODO!: remove clerk flags: https://linear.app/roompricegenie/issue/RMD-727/cleanup
export enum Flags {
  AIChatBot = 'ai-chat-bot',
  ClerkSelectiveAuth = 'clerk-selective-auth',
  ShowClerkLogin = 'show-clerk-login',
  ShowClerkComponent = 'show-clerk-components',
  MinStayV2 = 'min-stay-v-2',
  ReportingDashboardV2 = 'reporting-dashboard-v-2',
  TenPercentFixedPricesThreshold = 'ten-percent-fixed-prices-threshold'
}
