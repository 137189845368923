import { Button } from '@common/components/atoms/Button';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import * as z from 'zod';
import { useReferFriend } from './hooks/useReferFriend';

const schema = z.object({
  first_name: z.string().nonempty(),
  last_name: z.string().nonempty(),
  friend_email: z.string().email().nonempty(),
  friend_first_name: z.string().nonempty(),
  friend_last_name: z.string().nonempty(),
  friend_property_name: z.string().nonempty(),
  message: z.string().nonempty()
});

export const ReferFriend = () => {
  const { t } = useTranslation();
  const { referFriend, isLoading } = useReferFriend();
  const methods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      message: `${t(
        'Hi friend, I have been using RoomPriceGenie to automate my room pricing for quite some time already. It has not only saved me time but also increased my revenue. Start a free trial with them and see what they can do for you!'
      )}`
    }
  });

  const subMenuLink = [
    {
      id: 'refer-friend',
      label: t('Refer A Friend'),
      to: 'refer-friend'
    },
    {
      id: 'track-referral',
      label: t('Track Your Referrals'),
      to: 'track-referral'
    }
  ];

  const onSubmit = (data: any) => {
    console.log(data);
    referFriend(data);
  };

  return (
    <FormProvider {...methods}>
      <Page
        header={
          <Header
            title="RoomPriceGenie Referral Program"
            tabs={subMenuLink}
            actions={
              <Button
                intent="primary"
                size="large"
                onClick={methods.handleSubmit(onSubmit)}
                isLoading={isLoading}>
                {t('Invite Friend')}
              </Button>
            }
          />
        }>
        <Outlet />
      </Page>
    </FormProvider>
  );
};
