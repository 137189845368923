import { Checkbox } from '@mantine/core';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { GenieCircle } from '@common/components/foundations/icons/icons/GenieCircle';
import { DateRangePicker } from '@common/components/molecules/DateRangePicker/DateRangePicker';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useFeaturesStore } from '@common/store/features';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { DateRange } from 'react-day-picker';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

type UploadPriceOption = {
  label: string;
  value: number;
  duration: number;
  durationUnit: dayjs.ManipulateType;
};

const UPLOAD_PRICES_OPTIONS: UploadPriceOption[] = [
  {
    label: 'Custom Dates',
    value: 1,
    duration: 0,
    durationUnit: 'second'
  },
  {
    label: 'Next 2 Weeks',
    value: 2,
    duration: 2,
    durationUnit: 'weeks'
  },
  {
    label: 'Next 1 Month',
    value: 3,
    duration: 1,
    durationUnit: 'month'
  },
  {
    label: 'Next 3 Months',
    value: 4,
    duration: 3,
    durationUnit: 'month'
  },
  {
    label: 'Next 6 Months',
    value: 5,
    duration: 6,
    durationUnit: 'month'
  },
  {
    label: 'Next 12 Months',
    value: 6,
    duration: 12,
    durationUnit: 'month'
  },
  {
    label: 'Next 18 Months',
    value: 7,
    duration: 18,
    durationUnit: 'month'
  }
];

interface UploadPricesForm {
  startDate?: Date;
  endDate?: Date;
  uploadPricesOption: number;
  uploadAllPrices: boolean;
}

interface RefHandler {
  form: UseFormReturn<UploadPricesForm>;
}

const schema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  uploadPricesOption: z.number(),
  uploadAllPrices: z.boolean().optional()
});

export const formatToMonthNameForCompare = (date?: Date) => {
  return dayjs(date).format('MMM');
};

export const UploadPrices = forwardRef<RefHandler>((_, ref) => {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const { liliusInstance } = useCalendarPageStore();
  const form = useForm<UploadPricesForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      uploadPricesOption:
        formatToMonthNameForCompare(new Date()) ===
        formatToMonthNameForCompare(liliusInstance?.viewing)
          ? 2
          : 1
    }
  });
  const {
    control,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = form;
  const uploadPricesOption = watch('uploadPricesOption');
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';

  useEffect(() => {
    // Clear default dates on custom option if clicked
    if (uploadPricesOption === 1 && isDirty) {
      setValue('startDate', undefined);
      setValue('endDate', undefined);
    }

    if (uploadPricesOption !== 1) {
      liliusInstance?.setViewing(new Date());
      const start = dayjs().tz().startOf('day');
      const selectingOption = UPLOAD_PRICES_OPTIONS.find(
        (option) => option.value === uploadPricesOption
      );
      setValue('startDate', dayjs(start.format(API_DATE_FORMAT)).toDate());
      setValue(
        'endDate',
        dayjs(
          start
            .add(selectingOption?.duration ?? 0, selectingOption?.durationUnit ?? 'second')
            .format(API_DATE_FORMAT)
        ).toDate()
      );
    }
  }, [uploadPricesOption]);

  useImperativeHandle(ref, () => ({
    form
  }));

  const handleDateRangeChange = (dates?: DateRange) => {
    const { from, to } = dates ?? {};
    if (isNil(from) || isNil(to)) return;
    setValue('startDate', from);
    setValue('endDate', to);
    if (uploadPricesOption !== 1) setValue('uploadPricesOption', 1);
  };

  return (
    <>
      <GenieCircle />
      <h1 className="mt-2 text-h5 font-medium">{t(`Upload Prices to ${channelManagerOrPms}`)}</h1>
      <h3 className="mt-2 text-sm text-copyTextGrey">
        {t('Please select the date range you want to upload:')}
      </h3>
      <div className="mt-4">
        <Controller
          name="uploadPricesOption"
          render={({ field }) => (
            <RadioGroup
              optionsWrapperClassName="grid grid-rows-4 grid-flow-col gap-x-4 gap-y-2"
              type="block"
              options={UPLOAD_PRICES_OPTIONS.filter(
                (option) => !(option.value === 7 && !features?.includes(23))
              ).map((option) => ({
                ...option,
                label: t(option.label)
              }))}
              {...field}
            />
          )}
          control={control}
        />
      </div>
      <div className="mt-8 flex w-full flex-col gap-x-4 md:flex-row">
        <DateRangePicker
          helperMessage={
            errors.startDate || errors.endDate ? (
              <div className="ml-1 text-xs text-error">{t('Please select a valid date range')}</div>
            ) : null
          }
          ISOWeek={hotelDetails?.starts_monday}
          timezone={hotelDetails?.timezone}
          startDate={startDate}
          endDate={endDate}
          onDateChange={(date) => handleDateRangeChange(date)}
        />
      </div>
      <div className="ml-2 mt-2">
        <Controller
          control={control}
          name="uploadAllPrices"
          render={({ field }) => (
            <Checkbox
              label={
                <>
                  <div>{t('Upload all prices')}</div>
                  <div className="text-xs italic text-copyTextGrey">
                    {t('By default only price changes greater than 4% will be uploaded.')}
                  </div>
                  <div className="text-xs italic text-copyTextGrey">
                    {t('Uploading all prices can take a while.')}
                  </div>
                </>
              }
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </div>
    </>
  );
});
