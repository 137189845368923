import { Icon } from '@common/components/foundations/icons';
import { Genie } from '@common/components/foundations/Logo';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';

export const TrackReferral = () => {
  const { t } = useTranslation();

  const steps = [
    { id: '01', name: t('Invitation sent'), status: 'complete' },
    { id: '02', name: t('Setup Call Done'), status: 'current' },
    { id: '03', name: t('Subscribed'), status: 'upcoming' }
  ];
  return (
    <div className="rounded-sm bg-white p-6">
      <div className="mb-6 flex w-full justify-center gap-8">
        <div className="flex max-h-[150px] min-w-[400px] max-w-[400px]  rounded-sm bg-indigo p-4">
          <div className="flex min-h-[100px] w-1/3 items-center justify-center">
            <div className="flex items-center">
              <Genie className="w-12 fill-white" />
            </div>
          </div>
          <div className="mx-2 border-[0.17rem]  border-dashed border-white" />
          <div className="flex min-h-[100px] w-full flex-col items-center justify-center gap-3 p-4 text-center">
            <Typography variant="h5" element="h1" className="font-medium uppercase" color="white">
              {t('Months RoomPriceGenie Coupons')}
            </Typography>
            <Typography variant="h3" element="h1" className="font-medium uppercase" color="white">
              0
            </Typography>
          </div>
        </div>
        <div className="flex max-h-[150px] min-w-[400px] max-w-[400px] rounded-sm bg-darkGrey p-4">
          <div className="flex min-h-[100px] w-1/3 items-center justify-center">
            <div className="flex h-6 items-center pr-2 text-orange">
              <svg className="orange w-20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M48.48,378.73a300.52,300.52,0,0,0,152.89,95.92,262.57,262.57,0,0,0,159.3-17.25,225.52,225.52,0,0,0,66.79-47,6.36,6.36,0,0,0-2-8.53,11.76,11.76,0,0,0-8-.05,401.92,401.92,0,0,1-116.55,39.34,358.13,358.13,0,0,1-127.29-8.83,446.73,446.73,0,0,1-119.1-60.49,5,5,0,0,0-6.06,6.9Z"
                  fill="white"></path>
                <path
                  d="M387.15,388.44a168.11,168.11,0,0,1,48.94-2.23l.67.13a10,10,0,0,1,7.37,12.05A204.71,204.71,0,0,1,429,444.47a2.55,2.55,0,0,0,1.66,3.18,2.51,2.51,0,0,0,2.23-.37A83.31,83.31,0,0,0,464,382.86a12.44,12.44,0,0,0-10.22-13.22A95.75,95.75,0,0,0,384.91,384a2.55,2.55,0,0,0-.57,3.55A2.52,2.52,0,0,0,387.15,388.44Z"
                  fill="white"></path>
                <path
                  d="M304.24,324.92a164,164,0,0,1-28.92,25.3A135.16,135.16,0,0,1,208.63,369a99.49,99.49,0,0,1-57.49-19.85,97.25,97.25,0,0,1-27.36-100.28,112.35,112.35,0,0,1,65.3-69.06,367.67,367.67,0,0,1,104.7-15.55V127A37.82,37.82,0,0,0,261,94.72a59.9,59.9,0,0,0-31.17,4.08,48.89,48.89,0,0,0-27.13,34.67,12,12,0,0,1-12.58,6.72l-50.9-4.5a11.38,11.38,0,0,1-8.38-10.16,103.66,103.66,0,0,1,36.61-63.45A143.86,143.86,0,0,1,257.85,32a146.24,146.24,0,0,1,84.27,27.67,86.82,86.82,0,0,1,30.7,70.22V258.8a84.46,84.46,0,0,0,8,31.28l15.87,23.23a13,13,0,0,1,0,11.23L349.7,364.25a12.5,12.5,0,0,1-12.68-.44A244.84,244.84,0,0,1,304.24,324.92Zm-10.6-116.83a257.68,257.68,0,0,0-44,2.89A63,63,0,0,0,208,242.54a63,63,0,0,0,3.07,54,40.6,40.6,0,0,0,47.11,12.19,78.61,78.61,0,0,0,35.46-55.58V208.09"
                  fill="white"></path>
              </svg>
            </div>
          </div>
          <div className="mx-2 border-[0.17rem] border-dashed border-orange" />
          <div className="flex min-h-[100px] w-full flex-col items-center justify-center gap-3 p-4 text-center">
            <Typography variant="h5" element="h5" className="font-medium uppercase text-orange">
              {t('Amazon Vouchers')}
            </Typography>
            <Typography variant="h2" element="h2" className="font-medium uppercase text-orange">
              $0
            </Typography>
          </div>
        </div>
      </div>

      <Typography>
        {t(
          'As a growing company, we really appreciate your support. And the referrals we get from happy customers are one of the most fulfilling ways we keep growing. They make us smile a lot!'
        )}{' '}
        <br />
        {t(
          'All you need to do is fill in the form here, with details of someone you think would appreciate RoomPriceGenie, and we will contact them with your message.'
        )}
        <br />
        {t(
          'As a small sign of our gratitude, we would like to offer you a $50 Amazon voucher for anyone you refer that has a trial with us, plus one month free if they subscribe.'
        )}
        <br />
        {t('Thank you in advance for the smiles.')}{' '}
      </Typography>
      <div className="mt-6">
        <nav aria-label="Progress">
          <ol
            role="list"
            className="divide-y divide-grey rounded-md border border-grey md:flex md:divide-y-0">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex md:flex-1">
                {step.status === 'complete' ? (
                  <div className="group flex w-full items-center">
                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo group-hover:bg-indigo">
                        <Icon.NewEmail color="white" />
                      </span>
                      <Typography className="ml-4 font-semibold" color="indigo">
                        {step.name}
                      </Typography>
                    </span>
                  </div>
                ) : step.status === 'current' ? (
                  <div className="group flex items-center">
                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                      <span className="group-hover:border-grey-400 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-grey">
                        <span className="text-grey-500 group-hover:text-grey-900">
                          <Icon.CallTalking color="grey" />
                        </span>
                      </span>
                      <Typography className="ml-4 font-semibold" color="copyTextGrey">
                        {step.name}
                      </Typography>
                    </span>
                  </div>
                ) : (
                  <div className="group flex items-center">
                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                      <span className="group-hover:border-grey-400 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-grey">
                        <span className="text-grey-500 group-hover:text-grey-900">
                          <Icon.Verified color="grey" />
                        </span>
                      </span>
                      <Typography className="ml-4 font-semibold" color="copyTextGrey">
                        {step.name}
                      </Typography>
                    </span>
                  </div>
                )}

                {stepIdx !== steps.length - 1 ? (
                  <>
                    <div
                      className="absolute right-0 top-0 hidden h-full w-5 md:block"
                      aria-hidden="true">
                      <svg
                        className="h-full w-full text-grey"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none">
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};
