import { Navigate, RouteObject } from 'react-router-dom';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import MinStayOrphanGapPage from '@pages/Client/PricingStrategy/MinStay/pages/MinStayOrphanGapPage';
import MinStayRemovalPage from '@pages/Client/PricingStrategy/MinStay/pages/MinStayRemovalPage';
import MinStayNewVersionPage from '@pages/Client/PricingStrategy/MinStay/pages/MinStayNewVersionPage';
import { Feature } from '@common/store/features';

export const minStayRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="removal" /> },
  {
    path: 'removal',
    element: (
      <SecureRoute feature={Feature.MinimumStayRestrictions}>
        <MinStayRemovalPage />
      </SecureRoute>
    )
  },
  {
    path: 'orphan-gap-correction',
    element: (
      <SecureRoute
        feature={[Feature.MinimumStayRestrictions, Feature.LimitedMinimumStayRestrictions]}>
        <MinStayOrphanGapPage />
      </SecureRoute>
    )
  },
  {
    path: 'new-version',
    element: (
      <SecureRoute
        feature={[Feature.MinimumStayRestrictions, Feature.LimitedMinimumStayRestrictions]}>
        <MinStayNewVersionPage />
      </SecureRoute>
    )
  }
];
