import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Typography } from '@common/components/foundations/Typography';
import { subscriptionStatus } from '@common/config/app-config';
import { useViewStore } from '@common/store/view';
import { zodResolver } from '@hookform/resolvers/zod';
import { DemoHotelSwitcher } from '@pages/Client/Account/Subscription/components/DemoHotelSwitcher';
import { FreeTrialForm } from '@pages/Client/Account/Subscription/components/FreeTrialForm';
import { useSetHotelState } from '@pages/Client/Account/Subscription/hooks/usePayment';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { HotelState } from '@pages/Client/hooks/useSubscription';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

const schema = z.object({
  state: z.number()
});

const PARTNER_SUBSCRIBE_OPTIONS = [
  {
    label: 'Subscribe to Partner',
    value: HotelState.SUBSCRIBED_PARTNER
  },
  {
    label: 'Unsubscribe ',
    value: HotelState.CANCELED_PARTNER
  },
  {
    label: 'Free Trial',
    value: HotelState.FREE_TRIAL
  }
];

export const PartnerSubscription = () => {
  const { t } = useTranslation();
  const {
    hotelDetails: hotel,
    query: { refetch, isLoading }
  } = useHotelDetails();
  const setPartnerState = useSetHotelState();
  const { view } = useViewStore();
  const isAdmin = view === 'admin';
  const isDemoHotel = hotel?.state === HotelState.DEMO;
  const isUnsubscribe = hotel?.state === HotelState.CANCELED_PARTNER;

  const borderColor = subscriptionStatus[hotel?.state ?? 0].border;
  const { control, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (hotel && !isLoading) {
      reset({ state: hotel.state ? hotel.state : 0 });
    }
  }, [hotel, isLoading]);

  return (
    <div className={`my-2 rounded-sm border bg-white pb-1 ${borderColor}`}>
      <div className="p-6 py-10">
        {hotel?.state === HotelState.SUBSCRIBED_PARTNER ? (
          <div className="mb-4 flex flex-col gap-4">
            <Typography element="h5" variant="h5" color="darkGrey" className="text-h1-bold">
              {t('You are subscribed.')}
            </Typography>
            <Typography>
              {t('If you have any questions, please contact your representative ') + ' '}
              {hotel?.reseller ? hotel.reseller.name : ''}.
            </Typography>
          </div>
        ) : null}

        {hotel?.is_locked &&
        hotel?.state !== HotelState.SUBSCRIBED_PARTNER &&
        hotel?.state !== HotelState.CANCELED_PARTNER &&
        hotel?.state !== HotelState.DEMO ? (
          <div className="mb-4 flex flex-col gap-4">
            <Typography element="h5" variant="h5" color="darkGrey" className="text-h1-bold">
              {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
                ? 'Oops!'
                : t('All Good Things Come to an End...')}
            </Typography>
            <div className="flex max-w-3xl flex-col gap-3">
              <Typography color="uiRed">
                {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
                  ? t(
                      'It seems like there is a problem with your credit card. We tried 3 times but unfortunately it didn`t work. Please update your details if they are incorrect. And please call us if you are having any issues.'
                    )
                  : t(
                      "But this one doesn't have to. Just pop in your credit card details to continue to benefit from our great prices and your increased revenue. And if you choose the monthly payment option, you will have the flexibility to be able to cancel with maximum one month's notice. We're quietly confident that you won't want to though."
                    )}
              </Typography>
              <Typography>
                {hotel?.state == HotelState.SUSPENDED || hotel?.state == HotelState.NOT_ACTIVE
                  ? ' '
                  : t(
                      ' If not, best of luck in the future. And let us know if we can ever be of service'
                    )}
              </Typography>
            </div>
          </div>
        ) : null}
        {hotel?.state === HotelState.CANCELED_PARTNER ? (
          <div className="mb-4 flex flex-col gap-4">
            <Typography element="h5" variant="h5" color="darkGrey" className="text-h1-bold">
              {t('Your Subscription Has Expired')}
            </Typography>
            <Typography color="uiRed">
              {t(
                ' We`re sorry, but your subscription has expired. Please contact your representative at'
              )}{' '}
              {hotel?.reseller ? hotel.reseller.name : ''}.
            </Typography>
          </div>
        ) : null}

        <div className="min-h-36 flex justify-between ">
          {isAdmin ? (
            <>
              <div className="flex items-center gap-4">
                <div>
                  <DemoHotelSwitcher />
                </div>
                {!isLoading ? (
                  <div>
                    <Controller
                      name="state"
                      control={control}
                      defaultValue={hotel?.state}
                      render={({ field }) => (
                        <RadioGroup
                          optionsWrapperClassName="flex gap-6"
                          defaultSelectedOption={field.value}
                          value={field.value}
                          onChange={(value) => {
                            setPartnerState({
                              state: value
                            });
                            refetch();
                          }}
                          options={PARTNER_SUBSCRIBE_OPTIONS}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <Skeleton className="h-10 w-[500px] " />
                )}
              </div>
              <div className="flex items-center ">
                {isDemoHotel || isUnsubscribe ? null : <FreeTrialForm />}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
