import { useMutation } from '@tanstack/react-query';
import { useNotificationsStore } from '@common/store/notifications';
import {
  RecoveryPasswordResponse,
  RecoveryPasswordInput,
  recoveryPassword,
  ErrorResponse
} from '@common/api/auth';

export const useRecoveryPassword = () => {
  const addNotification = useNotificationsStore((state) => state.addNotification);

  return useMutation<RecoveryPasswordResponse, ErrorResponse, RecoveryPasswordInput>({
    mutationFn: recoveryPassword,
    onSuccess: async () => {
      addNotification('success', 'Recovery Password Success!');
    },
    onError: (error) => {
      if (error?.non_field_errors) {
        addNotification('fail', error.non_field_errors[0]);
      } else if (error?.user) {
        addNotification('fail', error.user.message);
      }
    }
  });
};
