import { Navigate, RouteObject } from 'react-router-dom';
import { AdminLayout } from '@pages/Admin/AdminLayout';
import { dashboardErrorTicketsRoutes } from '@pages/Admin/DashboardErrorTickets/routes';
import { errorTicketsRoutes } from '@pages/Admin/ErrorTickets/routes';
import CreatePropertyPage from '@pages/Admin/CreateProperty';
import RpgUserPage from '@pages/Admin/RpgUsers';
import PartnerUsersPage from '@pages/Admin/PartnerUsers';
import ProfileLayout from '@pages/Admin/Profile';
import PersonalInfoPage from '@pages/Admin/Profile/pages/PersonalInfo';
import PasswordPage from '@pages/Admin/Profile/pages/Password';
import LanguagePage from '@pages/Admin/Profile/pages/Language';
import { ErrorTicketsLayout } from '@pages/Admin/ErrorTickets/ErrorTicketsLayout';
import { DashboardErrorTicketsLayout } from '@pages/Admin/DashboardErrorTickets/DashboardErrorTicketsLayout';
import ScrapeListPage from '@pages/Admin/Scrape/ScrapeList';
import NewHotelScraperPage from '@pages/Admin/Scrape/NewHotel';
import ScheduleHistoryPage from '@pages/Admin/Scrape/ScheduleHistory';
import OnDemandSchedulePage from '@pages/Admin/Scrape/OnDemandSchedule';
import { ClientsLayout } from '@pages/Admin/Clients/ClientsLayout';
import { clientsRoutes } from '@pages/Admin/Clients/routes';

export const adminRoutes: RouteObject[] = [
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      { index: true, element: <Navigate to="/admin/clients" /> },
      { path: 'clients', element: <ClientsLayout />, children: clientsRoutes },
      { path: 'client-registration/', element: <CreatePropertyPage /> },
      { path: 'admin-users', element: <RpgUserPage /> },
      { path: 'resellers', element: <PartnerUsersPage /> },
      {
        path: 'profile',
        element: <ProfileLayout />,
        children: [
          { index: true, element: <Navigate to="personal-information" /> },
          {
            path: 'personal-information',
            element: <PersonalInfoPage />
          },
          {
            path: 'password',
            element: <PasswordPage />
          },
          {
            path: 'language',
            element: <LanguagePage />
          }
        ]
      },
      {
        path: 'error-tickets',
        element: <ErrorTicketsLayout />,
        children: errorTicketsRoutes
      },
      {
        path: 'dashboard-error-tickets',
        element: <DashboardErrorTicketsLayout />,
        children: dashboardErrorTicketsRoutes
      },
      { path: 'scrape-list', element: <ScrapeListPage /> },
      { path: 'scraper', element: <NewHotelScraperPage /> },
      { path: 'schedule-history', element: <ScheduleHistoryPage /> },
      { path: 'on-demand', element: <OnDemandSchedulePage /> }
    ]
  }
];
