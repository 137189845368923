type Props = {
  children: React.ReactNode;
};

export const FormHeader: React.FC<Props> = ({ children }) => (
  <div className="sticky top-0 z-10 mb-5 bg-white bg-opacity-70 backdrop-blur">{children}</div>
);

export const FormWrapper: React.FC<Props> = ({ children }) => (
  <div className="h-full px-5 py-10 md:px-10">
    <div className="h-full overflow-auto px-0.5">{children}</div>
  </div>
);

export const FormContent: React.FC<Props> = ({ children }) => (
  <div className="mt-8 flex flex-col gap-y-6 pb-16">{children}</div>
);

export const FormFooter: React.FC<Props> = ({ children }) => (
  <div className="absolute bottom-0 right-0 w-full rounded-bl-xl border-t border-grey-reduced bg-lightGrey p-5 text-right">
    <div className="flex justify-end gap-2">{children}</div>
  </div>
);
