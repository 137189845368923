import { Navigate, RouteObject } from 'react-router-dom';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import { PriceChart } from '@pages/Client/PriceChart/pages/PriceChart';
import { MarketFactor } from '@pages/Client/PriceChart/pages/MarketFactor';

export const priceChartRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="price" replace /> },
  {
    path: 'price',
    element: (
      <SecureRoute>
        <PriceChart />
      </SecureRoute>
    )
  },
  {
    path: 'market-factor',
    element: (
      <SecureRoute>
        <MarketFactor />
      </SecureRoute>
    )
  }
];
