import { Fragment, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import styles from '@common/components/molecules/Drawer/Drawer.module.css';
import { Icon } from '@common/components/foundations/icons';
import { Button } from '@common/components/atoms/Button';
import userflow from 'userflow.js';
import { PriceDrawerUserflowIds } from '@common/types/userflow-ids';

interface DrawerProps extends React.PropsWithChildren {
  isOpen: boolean;
  onClose?: (value: boolean) => void;
  drawerClassName?: string;
  drawerContentClassName?: string;
}

export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  children,
  drawerClassName,
  drawerContentClassName
}) => {
  // To track whether the resource center was open before closing
  const wasResourceCenterOpenRef = useRef(false);

  useEffect(() => {
    userflow.setResourceCenterLauncherHidden(isOpen);
    const resourceCenterState = userflow.getResourceCenterState();

    // If resource center is open and drawer `isOpen` is true, close the resource center
    if (resourceCenterState?.isOpen && isOpen) {
      userflow.closeResourceCenter();
      wasResourceCenterOpenRef.current = true; // Track that the resource center was open
    } else if (!isOpen && wasResourceCenterOpenRef.current) {
      // If `isOpen` is now false and the resource center was open before, reopen it
      userflow.openResourceCenter();
      wasResourceCenterOpenRef.current = false; // Reset tracking
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className={classNames('fixed inset-0 z-50 overflow-hidden', drawerClassName)}
        open={isOpen}
        onClose={(value) => onClose?.(value)}>
        <div className={classNames('absolute inset-0 right-0 overflow-hidden')}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 z-30 bg-darkGrey bg-opacity-70" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition duration-150"
            enterFrom="opacity-0 translate-x-[100px]"
            enterTo="opacity-100 translate-x-0"
            leave="transition duration-150"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-[100px]">
            <div
              data-testid="drawerRightContent"
              data-userflow-id={PriceDrawerUserflowIds.DRAWER_CONTAINER}
              className={classNames(
                'fixed inset-y-0 right-0 z-40 flex',
                {
                  [styles.drawerContentOpened]: isOpen
                },
                drawerContentClassName
              )}>
              <div className="h-full w-full overflow-auto bg-white md:rounded-bl-xl md:rounded-tl-xl">
                <Button
                  type="button"
                  icon
                  className="absolute right-6 top-6 z-20 p-2 text-grey"
                  onClick={() => onClose?.(false)}>
                  <Icon.Clear />
                </Button>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
