import React, { FC, ReactNode, SVGProps } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cn } from '@common/utils/cn';
import { useViewStore } from '@common/store/view';
import { ExportPDFHeader } from '@common/components/molecules/PDFExportHeader/PDFExportHeader';
import { Typography } from '@common/components/foundations/Typography';
import { Badge } from '@common/components/atoms/Badge';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import styles from '@common/components/molecules/Header/Header.module.css';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';

export interface HeaderTab {
  id: string;
  label: string;
  icon?: React.FC<SVGProps<SVGSVGElement>>;
  badge?: ReactNode;
  tooltip?: string | ReactNode;
  to: string;
  isOpenInNewTab?: boolean;
  disabled?: boolean;
}

interface HeaderProps {
  title: string;
  titleAdditionalInfo?: ReactNode;
  tabs?: HeaderTab[];
  tabsRightInfo?: ReactNode;
  actions?: ReactNode;
  additionalInfo?: ReactNode;
  description?: string | ReactNode;
  additionalInfoDescription?: ReactNode;
  exportTitle?: string;
}

export const Header: FC<HeaderProps> = ({
  title,
  titleAdditionalInfo,
  tabs,
  tabsRightInfo,
  actions,
  additionalInfo,
  description,
  additionalInfoDescription,
  exportTitle
}) => {
  const { view } = useViewStore();
  const { t } = useTranslation();
  return (
    <div className="sticky top-0 z-10 bg-appBackground bg-opacity-80 backdrop-blur ">
      <ExportPDFHeader title={exportTitle} className="mx-8" />
      <div className="flex flex-col">
        <div className="mt-3 flex flex-col items-start justify-between gap-y-4 pl-4 pr-4 md:flex-row md:pl-8 md:pr-8 min-[1755px]:pr-0">
          <div className="flex w-full flex-col gap-2">
            <div className="flex w-full flex-1 items-center justify-between space-x-4">
              <div className="flex items-center gap-x-4" data-html2canvas-ignore="true">
                <Typography variant="h5" element="h1" className="font-medium" color="darkGrey">
                  {title}
                </Typography>
                {view === 'admin' ? (
                  <div className="hidden md:block">
                    <Badge>{t('Admin')}</Badge>
                  </div>
                ) : null}
                {titleAdditionalInfo ? <div>{titleAdditionalInfo}</div> : null}
              </div>
              <div>{additionalInfo ? additionalInfo : null}</div>
            </div>
            {additionalInfoDescription ? (
              <div className="mt-3 w-full">{additionalInfoDescription}</div>
            ) : null}
          </div>

          {actions ? (
            <div className="ml-0 flex w-full items-center justify-start gap-x-4 md:ml-6 md:justify-end">
              {actions}
            </div>
          ) : (
            <div className="md:h-10" />
          )}
        </div>

        {description ? (
          <div className="mx-8 my-4 hidden max-w-full overflow-hidden md:block xl:max-w-3xl">
            <Typography variant="paragraph-2" element="p">
              {description}
            </Typography>
          </div>
        ) : null}

        {tabs ? (
          <div className={styles.tabs}>
            <ScrollArea>
              <div className="flex items-center gap-x-6 px-4 md:px-8 min-[1755px]:pr-0">
                {tabs?.map((tab) => (
                  <NavLink
                    data-html2canvas-ignore="true"
                    key={tab.id}
                    to={tab.to}
                    target={tab.isOpenInNewTab ? `_blank` : undefined}
                    onClick={(e) => tab.disabled && e.preventDefault()}>
                    {({ isActive }) => (
                      <div
                        className={cn(
                          styles.tab,
                          'min-h-[48px]',
                          tab.disabled ? 'cursor-not-allowed' : '',
                          isActive && !tab.disabled ? styles.active_tab : styles.inactive_tab
                        )}>
                        {tab.icon ? (
                          <tab.icon className="mr-2 inline-block h-5 w-5 align-middle" />
                        ) : null}
                        <div className="flex items-center gap-x-2">
                          {tab.label}
                          {tab.tooltip ? <TooltipIcon content={tab.tooltip} /> : null}
                          {tab.badge ? <div className="flex-shrink-0">{tab.badge}</div> : null}
                        </div>
                      </div>
                    )}
                  </NavLink>
                ))}
                <div className="flex-1" />
                {tabsRightInfo ? <div>{tabsRightInfo}</div> : null}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </div>
        ) : (
          <div className="mx-4 h-2 md:mx-8" />
        )}
      </div>
    </div>
  );
};
