import { setAuthToken } from '@common/api/axiosInstance';
import {
  addFreeTrialTime,
  changeHotelState,
  changeToTest,
  checkoutSubscription,
  getMembershipPricing,
  linkCheckoutChargeebee
} from '@common/api/hotel';
import {
  CheckoutSubscription,
  FreeTrial,
  HotelState,
  linkChargeebeeSubscription
} from '@common/api/hotel/types';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { urlEncode } from '@pages/Client/Account/Subscription/utils/urlEncode';
import { HotelQueryKeys, MembershipQueryKeys } from '@common/types/query-keys';

export const useMemberShipPricing = () => {
  const { hotelAuthToken } = useHotelStore();

  const { data, isFetching, error, refetch } = useQuery({
    queryKey: [MembershipQueryKeys.MEMBERSHIP_PRICING],
    queryFn: () => {
      setAuthToken(hotelAuthToken);
      return getMembershipPricing();
    },
    enabled: !!hotelAuthToken,
    retry: false,
    refetchOnWindowFocus: 'always'
  });

  return {
    membershipPricing: data?.data.pricing,
    isLoading: isFetching,
    refetch,
    error
  };
};

export const useCheckoutPayment = () => {
  const { hotelAuthToken } = useHotelStore();

  const paymentPortalMutation = async (data: CheckoutSubscription) => {
    setAuthToken(hotelAuthToken);
    const result = await checkoutSubscription(data);
    return result.data;
  };

  const {
    mutate,
    data,
    isPending: isLoading,
    error
  } = useMutation({ mutationFn: paymentPortalMutation });

  return {
    checkout_data: data,
    isLoading,
    error,
    getCheckoutData: mutate
  };
};

export const useLinkChargeebeeSubscription = () => {
  const { hotelAuthToken } = useHotelStore();

  const linkChargebee = async (data: linkChargeebeeSubscription) => {
    setAuthToken(hotelAuthToken);
    const result = await linkCheckoutChargeebee(urlEncode(data));
    return result.data;
  };

  const { mutate } = useMutation({ mutationFn: linkChargebee });

  return {
    getLinkCheckout: async (data: any) => {
      const response = await mutate(data);
      return response;
    }
  };
};

export const useSetToTestMode = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: () => {
      setAuthToken(hotelAuthToken);
      return changeToTest();
    },
    retry: false,
    onSuccess: () => {
      addNotification('success', 'Demo mode set successfully');
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });
    },
    onError: () => {
      addNotification('fail', 'Failed to set Demo mode');
      return [];
    }
  }).mutate;

  return mutate;
};

export const useSetHotelState = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const queryClient = useQueryClient();

  const mutate = useMutation({
    mutationFn: (data: HotelState) => {
      setAuthToken(hotelAuthToken);
      return changeHotelState(data);
    },
    retry: false,
    onError: () => {
      addNotification('fail', 'Failed to update hotel');
      return [];
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === HotelQueryKeys.HOTEL_DETAIL
      });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });

      addNotification('success', 'Hotel update successfully');
    }
  }).mutate;

  return mutate;
};

export const useAddFreeTrial = (isNotification: boolean) => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const queryClient = useQueryClient();

  const getNotifications = () => {
    if (isNotification) {
      addNotification('success', 'Free trial time added successfully');
    }
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: FreeTrial) => {
      setAuthToken(hotelAuthToken);
      return addFreeTrialTime(data);
    },
    retry: false,
    onError: () => {
      addNotification('fail', 'Failed to add free trial time');
      return [];
    },
    onSuccess: () => {
      getNotifications();
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === HotelQueryKeys.HOTEL_DETAIL
      });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });
    }
  });

  return {
    mutate,
    isLoading
  };
};
