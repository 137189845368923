import { DEVELOPER_OPTIONS } from '@common/constants/developer-options';
import { useAuthStore } from '@common/store/auth';
import { getEnvVar } from '@common/utils/env';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, Select, SimpleGrid, Stack, Title } from '@mantine/core';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';
import { TICKET_STATUS } from '@pages/Admin/ErrorTickets/common/constants/ticket-status';
import { useUpdateMultipleTickets } from '@pages/Admin/ErrorTickets/hooks/useUpdateErrorTicket';
import { ModalFooter } from '@common/mantine/components/modal-footer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  ticketsIds: number[];
};

const schema = z.object({
  ids: z.number().array(),
  developer_name: z.string().optional(),
  status: z.string().optional(),
  token: z.string().nullish(),
  note: z.string().optional().nullish()
});

export const UpdateMultipleErrorTicketsModal = ({ isOpen, onClose, ticketsIds }: Props) => {
  const { token } = useAuthStore();
  const { updateMultipleErrorTickets } = useUpdateMultipleTickets();

  const defaultValues = {
    ids: ticketsIds,
    developer_name: 'notassigned',
    status: '1',
    token
  };

  const { control, setValue, handleSubmit } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (ticketsIds.length > 0) {
      setValue('ids', ticketsIds);
    }
  }, [ticketsIds]);

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (formData) => {
    const { ids, developer_name, status, token, note } = formData;
    await updateMultipleErrorTickets({
      ids,
      developer_name: developer_name ?? 'notassigned',
      status: Number(status),
      token: token ?? null,
      note
    });
    onClose();
  };

  return (
    <Modal.Root opened={isOpen} onClose={onClose} size="xl">
      <Modal.Overlay />
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header bg="white">
            <Modal.Title>Edit Multiple Error Ticket</Modal.Title>
            <Modal.CloseButton onClick={onClose} />
          </Modal.Header>
          <Modal.Body>
            <SimpleGrid
              cols={2}
              w={{
                xs: '100%',
                md: '50%'
              }}>
              <Controller
                control={control}
                name="status"
                render={({ field: { value, name, onChange } }) => (
                  <Select
                    label="Ticket Status"
                    placeholder="Select Ticket Status"
                    name={name}
                    key={name}
                    value={value || ''}
                    data={TICKET_STATUS}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name="developer_name"
                render={({ field: { value, name, onChange } }) => (
                  <Select
                    label="Developer"
                    placeholder="Select Developer"
                    name={name}
                    key={name}
                    value={value || ''}
                    data={DEVELOPER_OPTIONS}
                    onChange={onChange}
                    clearable
                  />
                )}
              />
            </SimpleGrid>

            <Stack gap="xs" mt="md">
              <Title order={5}>Note</Title>
              <Controller
                control={control}
                name="note"
                render={({ field: { onChange } }) => (
                  <Editor
                    apiKey={`${getEnvVar('VITE_TINYMCE')}`}
                    init={{
                      height: 320,
                      menubar: false,
                      branding: false,
                      plugins:
                        'preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link codesample table  hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount  textpattern noneditable help charmap emoticons',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | emoticons fullscreen link  codesample '
                    }}
                    onChange={(_event, editor) => {
                      const data = editor.getContent();
                      onChange(data);
                    }}
                  />
                )}
              />
            </Stack>
          </Modal.Body>
          <ModalFooter>
            <Button variant="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </ModalFooter>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
