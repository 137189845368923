import { updateAdminUser } from '@common/api/account';
import { EditAdminUserPayload } from '@common/api/account/types';
import { useNotificationsStore } from '@common/store/notifications';
import { RPGUsersQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface UpdateAdminUser {
  payload: EditAdminUserPayload;
  id: number;
}

export const useUpdateAdminUser = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (data: UpdateAdminUser) => {
      return updateAdminUser(data.payload, data.id);
    },
    onSuccess: () => {
      addNotification('success', 'User update successfully');
      queryClient.invalidateQueries({ queryKey: [RPGUsersQueryKeys.GET_ADMIN_USER_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to update User');
    }
  });

  return {
    updateAdminUser: mutate,
    isLoading,
    isSuccess
  };
};
