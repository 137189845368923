import * as z from 'zod';
import { aIsSmallerThanB } from '@pages/Client/Calendar/components/BulkEdit/helpers/aIsSmallerThanB';
import { isMoreThanThirtyPercent } from '@pages/Client/Calendar/components/BulkEdit/helpers/isMoreThanThirtyPercent';
import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';
import { isNaN } from 'lodash-es';

const baseSchema = z.object({
  id: z.number(),
  adjustment: z.number().or(z.string()).optional(),
  defaultMaxPrice: z.number().or(z.string()).optional(),
  defaultMinPrice: z.number().or(z.string()).optional(),
  averagePrice: z.number().or(z.string()).optional(),
  isAbsolute: z.boolean().default(false),
  isBulkEdit: z.boolean().default(false),
  isReferenceRoom: z.boolean().default(false),
  isPerRoomType: z.boolean().default(false).optional(),
  maxPrice: z.union([z.number(), z.nan(), z.null()]).optional(),
  minPrice: z.union([z.number(), z.nan(), z.null()]).optional(),
  currentSetMaxPrice: z.union([z.number(), z.nan(), z.null()]).optional(),
  currentSetMinPrice: z.union([z.number(), z.nan(), z.null()]).optional()
});

/**
 * Validation Logic for MinMax Schema:
 *
 * - Ensures that both 'minPrice' and 'maxPrice' do not have decimal places.
 *   - If either has decimals, the message 'Value is not allowed.' is shown for the respective field.
 *
 * - Validates that 'minPrice' is more than 30% higher than 'averagePrice'.
 *   - If not, it displays 'Please enter a higher min price.' for 'minPrice'.
 *
 * - Validates that 'maxPrice' is more than 30% higher than 'averagePrice'.
 *   - If not, it displays 'Please enter a higher max price.' for 'maxPrice'.
 *
 * - Checks that 'minPrice' is smaller than 'maxPrice'.
 *   - If 'minPrice' is not smaller, it shows 'Please enter a min price lower than your max price.'
 *   - If 'maxPrice' is not higher, it shows 'Please enter a max price higher than your min price.'
 *
 */

export const minMaxZodOutputSchema = baseSchema
  .refine((val) => validateNoDecimals(val?.minPrice), {
    message: 'Value is not allowed.',
    path: ['minPrice']
  })
  .refine((val) => validateNoDecimals(val?.maxPrice), {
    message: 'Value is not allowed.',
    path: ['maxPrice']
  })
  .refine((val) => isMoreThanThirtyPercent(Number(val?.minPrice), Number(val?.averagePrice)), {
    message: 'Please enter a higher min price.',
    path: ['minPrice']
  })
  .refine((val) => isMoreThanThirtyPercent(Number(val?.maxPrice), Number(val?.averagePrice)), {
    message: 'Please enter a higher max price.',
    path: ['maxPrice']
  })
  .refine((val) => aIsSmallerThanB(Number(val.minPrice), Number(val.maxPrice)), {
    message: 'Please enter a min price lower than your max price.',
    path: ['minPrice']
  })
  .refine((val) => aIsSmallerThanB(Number(val.minPrice), Number(val.maxPrice)), {
    message: 'Please enter a max price higher than your min price.',
    path: ['maxPrice']
  })
  .refine(
    (val) => {
      if (isNaN(val.maxPrice)) {
        return aIsSmallerThanB(Number(val.minPrice), Number(val.currentSetMaxPrice));
      }

      return true;
    },
    {
      message: 'Not all affected min prices are lower than currently set max prices.',
      path: ['minPrice']
    }
  )
  .refine(
    (val) => {
      if (isNaN(val.minPrice)) {
        return aIsSmallerThanB(Number(val.currentSetMinPrice), Number(val.maxPrice));
      }

      return true;
    },
    {
      message: 'Not all affected max prices are higher than currently set min prices.',
      path: ['maxPrice']
    }
  );

export type MinMax = z.infer<typeof baseSchema>;
