import { setAuthToken } from '@common/api/axiosInstance';
import { checkBookingUrl } from '@common/api/hotel';
import { CheckBookingUrlPayload } from '@common/api/hotel/types';
import { useHotelStore } from '@common/store/auth';
import { useCheckBookingUrlStore } from '@pages/Client/Account/Property/store/bookingUrl';
import { useMutation } from '@tanstack/react-query';

export const useCheckBookingUrl = () => {
  const { setValidBookingUrl, setSuggestBookingUrl, setIsSuccessUrl } = useCheckBookingUrlStore();
  const { hotelAuthToken } = useHotelStore();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (competitor: CheckBookingUrlPayload) => {
      setAuthToken(hotelAuthToken);
      return checkBookingUrl(competitor).then((res) => {
        if (res.data.success) {
          setValidBookingUrl(res.data.is_ref_url_valid);
          setSuggestBookingUrl(res.data.booking_ref_url);
          setIsSuccessUrl(true);
        } else {
          setSuggestBookingUrl(res.data.message);
          setValidBookingUrl(false);
          setIsSuccessUrl(false);
        }
      });
    }
  });

  return {
    checkBookingUrl: mutate,
    isLoading,
    isSuccess
  };
};
