import {
  Box,
  Checkbox,
  Group,
  Paper,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  Title
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import useMinstay from '@pages/Client/PricingStrategy/MinStay/hooks/useMinstay';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Controller, useForm } from 'react-hook-form';
import {
  MinstaySchema,
  PartialPricingSettingsSchema
} from '@pages/Client/Calendar/utils/validateBaseSettings';
import { z } from 'zod';
import { Icon } from '@common/components/foundations/icons';
import { zodResolver } from '@hookform/resolvers/zod';
// Keep for later when introducing autosave
// import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useFormSubmitStore } from '@pages/Client/PricingStrategy/MinStay/store/formSubmitStore';
import { MinStay } from '@pages/Client/PricingStrategy/MinStay/types';
import { useEventTracking } from '@common/hooks/useEventTracking';

export const MinStayOrphanGapPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Orphan Gap Correction'));

  const { pricingSettings } = useRoomPrices();
  const { hotelDetails } = useHotelDetails();
  const { savePricingSettings } = useUpdatePricingSettings();
  const { trackEvent } = useEventTracking();

  const { control, handleSubmit, setValue } = useForm<MinStay>({
    resolver: zodResolver(MinstaySchema)
  });

  const { roomTypesOption } = useMinstay();
  const setSubmitHandler = useFormSubmitStore((state) => state.setSubmitHandler);

  const ROOM_TYPES_OPTION = roomTypesOption(pricingSettings?.rooms) || [];

  useEffect(() => {
    if (pricingSettings?.minstay?.enabled_orphan_gap_correction_room_ids) {
      setValue(
        'enabled_orphan_gap_correction_room_ids',
        pricingSettings.minstay.enabled_orphan_gap_correction_room_ids
      );
    }
  }, [pricingSettings]);

  const saveOrphanGap = async (data: MinStay) => {
    const newSettings: z.infer<typeof PartialPricingSettingsSchema> = {
      ...pricingSettings,
      minstay: {
        ...pricingSettings?.minstay,
        enabled_orphan_gap_correction_room_ids: data.enabled_orphan_gap_correction_room_ids || []
      }
    };

    await savePricingSettings(JSON.stringify(newSettings));
    trackEvent('OrphanGapCorrectionApplied', {
      tags: {
        all_rooms: data.enabled_orphan_gap_correction_room_ids?.length === ROOM_TYPES_OPTION.length
      }
    });
  };

  // Keep for later when introducing autosave
  // const debouncedSaveOrphanGap = debounce(handleSubmit(saveOrphanGap), 500);

  useEffect(() => {
    setSubmitHandler(handleSubmit(saveOrphanGap));
  }, [handleSubmit, saveOrphanGap, setSubmitHandler]);

  return (
    <Box>
      <SimpleGrid
        cols={{
          sm: 1,
          md: 2
        }}>
        <Stack
          maw={{
            base: '100%',
            md: '80%'
          }}>
          <Title order={3} fw={400}>
            {t('Orphan Gap Correction')}
          </Title>

          <Text c="gray.9">
            {t(
              `Orphan Gap correction lets you automatically correct impossible minimum stay restrictions so that you never have available {{apartmentOrRoom}}s that cannot be booked.`,
              {
                apartmentOrRoom: hotelDetails?.room_apartment_space_name.toLowerCase()
              }
            )}
          </Text>

          <Text c="gray.9">
            {t(
              `If we see that there are less days available for a {{apartmentOrRoom}} type than are needed to meet the minimum stay restriction in place, then we will automatically reduce the length of the minimum stay restriction to fit the number of consecutive dates available.`,
              {
                apartmentOrRoom: hotelDetails?.room_apartment_space_name.toLowerCase()
              }
            )}
          </Text>
          <Text c="gray.9">
            {t(
              'For example, if you have a minimum stay of 4 nights on a Thursday, but both the Tuesday before and the Saturday after are fully booked then we will reduce the length of the minimum stay restriction to 3 nights.'
            )}
          </Text>
        </Stack>

        <Box>
          <Paper w="100%" p="md">
            <Stack>
              <Controller
                name="enabled_orphan_gap_correction_room_ids"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Checkbox.Group
                    value={value}
                    error={error?.message}
                    onChange={(value) => {
                      onChange(value);
                      // Keep for later when introducing autosave
                      // debouncedSaveOrphanGap();
                    }}
                    label={`${t(`{{apartmentOrRoom}} Types`, {
                      apartmentOrRoom: hotelDetails?.room_apartment_space_name
                    })}`}
                    description={`Choose all ${t(
                      `{{apartmentOrRoom}} types that you want to enable Orphan Gap correction for`,
                      {
                        apartmentOrRoom: hotelDetails?.room_apartment_space_name.toLowerCase()
                      }
                    )}`}>
                    <ScrollArea.Autosize type="always" mah="800px" offsetScrollbars scrollbars="y">
                      <Stack pt="xs" gap="xs">
                        {ROOM_TYPES_OPTION.map((roomType) => (
                          <Checkbox.Card
                            px="md"
                            py="xs"
                            radius="lg"
                            value={roomType.value}
                            key={roomType.value}
                            className="group bg-white hover:bg-[var(--mantine-color-default-hover)] disabled:cursor-not-allowed disabled:opacity-50 data-[checked]:border-[var(--mantine-primary-color-filled)]">
                            <Group wrap="nowrap" align="center" justify="space-between">
                              <Box>
                                <Text c="dark" size="sm">
                                  {roomType.label}
                                </Text>
                              </Box>
                              <div className="hidden shrink-0 text-indigo group-data-[checked]:flex">
                                <Icon.CheckList className="h-5 w-5" />
                              </div>
                            </Group>
                          </Checkbox.Card>
                        ))}
                      </Stack>
                    </ScrollArea.Autosize>
                  </Checkbox.Group>
                )}
              />
            </Stack>
          </Paper>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default MinStayOrphanGapPage;
