import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { TabBar } from '@common/components/molecules/TabsBar';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { CurrentAverageChart } from '@pages/Client/PricingStrategy/RoomSetup/components/CurrentAverageChart';
import {
  editSchema,
  editSchemaWithoutMinMax
} from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import {
  FormContent,
  FormFooter,
  FormHeader,
  FormWrapper
} from '@pages/Client/PricingStrategy/RoomSetup/components/FormLayout';
import { useViewStore } from '@common/store/view';
import {
  saveHotelPricePerOccupancy,
  saveHotelPricePerRate,
  savePricingSettings,
  updateHotelRoom
} from '@common/api/hotel';
import { HotelPricePerOccupancyRequest, HotelPricePerRateRequest } from '@common/api/hotel/types';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotificationsStore } from '@common/store/notifications';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useTranslation } from 'react-i18next';
import { useWarnings } from '@common/store/warnings';
import { useFeaturesStore } from '@common/store/features';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import PMSMappingEdit from '@pages/Client/PricingStrategy/RoomSetup/components/PmsMappingEdit';
import PricingSetup from '@pages/Client/PricingStrategy/RoomSetup/components/PricingSetup';
import OccupancyPricing from '@pages/Client/PricingStrategy/RoomSetup/components/OccupancyPricingEdit';
import DerivedRates from '@pages/Client/PricingStrategy/RoomSetup/components/DerivedRates';
import { HotelQueryKeys, PricingQueryKeys, RoomsQueryKeys } from '@common/types/query-keys';

enum Tabs {
  PricingSetup,
  OccupancyPricing,
  PMSMapping,
  DerivedRates
}

export const EditRoom: React.FC<{
  defaults: Partial<z.infer<typeof editSchema>>;
  isOccupancyBasedPricing: boolean;
  isDerivedRates: boolean;
  roomInPmsOptions: { label: string; value: number }[] | undefined;
  baseRateInPmsOptions: (pmsRoom: number) => { label: string; value: number }[] | undefined;
  roomOccupancyPricingOptions: (pmsRoom: number) => { label: string; value: number }[] | undefined;
  referenceRoomDefaultMin: number | undefined;
  referenceRoomDefaultMax: number | undefined;
  referenceRoomBasePrice: number | undefined;
  onDrawerClose: () => void;
}> = ({
  defaults,
  isOccupancyBasedPricing,
  isDerivedRates,
  roomInPmsOptions,
  baseRateInPmsOptions,
  roomOccupancyPricingOptions,
  referenceRoomDefaultMax,
  referenceRoomDefaultMin,
  referenceRoomBasePrice,
  onDrawerClose
}) => {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { addNotification } = useNotificationsStore();
  const { createWarning } = useWarnings();
  const { newBasePrice, newMinPrice, newMaxPrice, isAbsoluteAdjustmentToReferenceRoom } =
    useRoomSetupStore();
  const { pmsList } = useGetHotelPmsList();
  const getConnectedPms = pmsList?.find((item) => item.primary);
  const isIntegrationV2 = getConnectedPms?.use_v2_integration;
  const {
    pricingSettings,
    pricingSettingsQuery: { refetch: refetchPricingSettings }
  } = usePricingSettings();
  const { hotelPmsDataMap } = useHotelPmsDataMap();
  const [activeTab, setActiveTab] = useState(Tabs.PricingSetup);
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [currentAverageModalOpen, setCurrentAverageModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const { hotelDetails } = useHotelDetails();
  const { mutateAsync: saveDerivedRates, isPending: isSaveDerivedRates } = useMutation({
    mutationKey: [PricingQueryKeys.SAVE_DERIVED_RATES],
    mutationFn: saveHotelPricePerRate
  });
  const { mutateAsync: updatePricingSettingsMutation, isPending: isUpdatePricingSettings } =
    useMutation({
      mutationKey: [PricingQueryKeys.UPDATE_PRICING_SETTINGS],
      mutationFn: savePricingSettings,
      onSuccess: () => {
        setIsFormSuccess(true);
        setTimeout(() => {
          setIsFormSuccess(false);
        }, 2000);
        queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
        queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.GET_HOTEL_ROOMS_LIST] });
        queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
        queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
        queryClient.invalidateQueries({
          queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_RATE, defaults.pmsMapping?.roomInPms]
        });
        onDrawerClose();
        addNotification('success', 'Successfully updated room');
      }
    });
  const { mutateAsync: updateHotelRoomMutation, isPending: isUpdateHotelRoom } = useMutation({
    mutationKey: [RoomsQueryKeys.UPDATE_HOTEL_ROOM],
    mutationFn: updateHotelRoom
  });
  const {
    mutateAsync: saveHotelPricePerOccupancyMutation,
    isPending: isSaveHotelPricePerOccupancy
  } = useMutation({
    mutationKey: [PricingQueryKeys.SAVE_HOTEL_PRICE_PER_OCCUPANCY],
    mutationFn: saveHotelPricePerOccupancy,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_OCCUPANCY, defaults.pmsMapping?.roomId]
      });
    }
  });
  const { view } = useViewStore();

  const schema = features?.includes(6) ? editSchema : editSchemaWithoutMinMax;

  const methods = useForm<z.infer<typeof editSchema>>({
    mode: 'onSubmit',
    defaultValues: {
      pmsMapping: {
        roomInPms: defaults.pmsMapping?.roomInPms || null,
        baseRateInPms: defaults.pmsMapping?.baseRateInPms || null,
        priceType: defaults.pmsMapping?.priceType || 'unit_based',
        roomOccupancyForPricing: defaults.pmsMapping?.roomOccupancyForPricing || null,
        roomName: defaults.pmsMapping?.roomName || '',
        numberOfRooms: defaults.pmsMapping?.numberOfRooms || 0,
        virtualRoomType: defaults.pmsMapping?.virtualRoomType || false,
        numberOfBedsPerPhysicalRoom: defaults.pmsMapping?.numberOfBedsPerPhysicalRoom || undefined,
        roomId: defaults.pmsMapping?.roomId || 0
      },
      pricingSetup: {
        basePrice: defaults.pricingSetup?.basePrice || 0,
        defaultMinPrice: defaults.pricingSetup?.defaultMinPrice || 0,
        defaultMaxPrice: defaults.pricingSetup?.defaultMaxPrice || 0,
        derivationFromReferenceRoom: Math.round(
          defaults.pricingSetup?.derivationFromReferenceRoom || 0
        ),
        dontCountUploads: defaults.pricingSetup?.dontCountUploads || false
      },
      occupancyPricing: {
        defaultOccupancy: defaults.occupancyPricing?.defaultOccupancy || 0,
        additionalChildPrice: defaults.occupancyPricing?.additionalChildPrice || 0,
        additionalAdultPrice: defaults.occupancyPricing?.additionalAdultPrice || 0
      }
    },
    resolver: zodResolver(schema)
  });

  const CurrentTabContentComponent = useMemo(() => {
    switch (activeTab) {
      case Tabs.PMSMapping:
        return PMSMappingEdit;
      case Tabs.PricingSetup:
        return PricingSetup;
      case Tabs.OccupancyPricing:
        return OccupancyPricing;
      case Tabs.DerivedRates:
        return DerivedRates;
      default:
        return null;
    }
  }, [activeTab]);

  const handleDerivedMinMaxCalculation = () => {
    const { pricingSetup } = methods.getValues();
    const { derivationFromReferenceRoom, basePrice } = pricingSetup;
    if (
      !derivationFromReferenceRoom ||
      !referenceRoomDefaultMin ||
      !referenceRoomDefaultMax ||
      !basePrice
    )
      return;

    const minPercentage = 0.7; // 70%
    const maxPercentage = 2.0; // 200%

    // Calculate minimum and maximum prices
    let calculatedMinPrice = isAbsoluteAdjustmentToReferenceRoom
      ? +referenceRoomDefaultMin + +derivationFromReferenceRoom
      : +referenceRoomDefaultMin * (1 + +derivationFromReferenceRoom / 100);
    let calculatedMaxPrice = isAbsoluteAdjustmentToReferenceRoom
      ? +referenceRoomDefaultMax + +derivationFromReferenceRoom
      : +referenceRoomDefaultMax * (1 + +derivationFromReferenceRoom / 100);

    // Adjust minimum price if below 70% of the base price
    calculatedMinPrice = Math.max(calculatedMinPrice, basePrice * minPercentage);

    // Adjust maximum price if below 200% of the base price
    calculatedMaxPrice = Math.max(calculatedMaxPrice, basePrice * maxPercentage);

    // Set the values in the form
    methods.setValue('pricingSetup.defaultMinPrice', Math.floor(calculatedMinPrice));
    methods.setValue('pricingSetup.defaultMaxPrice', Math.floor(calculatedMaxPrice));
  };

  const handleCloseCurrentAverageModal = (ok: boolean) => {
    const newDerivation = isAbsoluteAdjustmentToReferenceRoom
      ? newBasePrice - (referenceRoomBasePrice || 0)
      : Math.round(
          ((newBasePrice - (referenceRoomBasePrice || 0)) / (referenceRoomBasePrice || 0)) * 100
        );

    if (!ok) {
      methods.setValue('pricingSetup.basePrice', Math.round(newBasePrice));
      methods.setValue('pricingSetup.derivationFromReferenceRoom', newDerivation);
      setCurrentAverageModalOpen(false);
      return;
    }

    methods.setValue('pricingSetup.basePrice', Math.round(newBasePrice));
    methods.setValue('pricingSetup.derivationFromReferenceRoom', newDerivation);
    methods.setValue('pricingSetup.defaultMinPrice', newMinPrice);
    methods.setValue('pricingSetup.defaultMaxPrice', newMaxPrice);
    setCurrentAverageModalOpen(false);
  };

  const handleSubmit = async () => {
    const { pmsMapping, pricingSetup, occupancyPricing, derivedRates } = methods.getValues();
    const derivedRatesRequest: HotelPricePerRateRequest[] =
      derivedRates
        ?.map((rate) => ({
          id: rate.id,
          derivation: rate.derivation || 0,
          is_absolute: !rate.percentDerivation || false,
          is_product_derivation: rate.productDerivation || false,
          pms_rate: rate.derivedRateInPms || null,
          hotel: hotelDetails?.id || 0,
          pms_room: defaults.pmsMapping?.roomInPms || null
        }))
        .filter((item) => item.pms_rate !== null) || [];

    const submitFn = async () => {
      if (derivedRates) {
        await saveDerivedRates({ id: pmsMapping?.roomInPms, data: derivedRatesRequest });
      }

      await updateHotelRoomMutation({
        room_id: defaults.pmsMapping?.roomId,
        data: {
          name: pmsMapping.roomName || '',
          default_occupancy: occupancyPricing.defaultOccupancy || 0,
          configurations: isIntegrationV2
            ? {
                price_type: pmsMapping.priceType || 'unit_based'
              }
            : {},
          extra: !isIntegrationV2
            ? pmsMapping.priceType
            : hotelPmsDataMap?.mapped_data.find((value) => value.id === pmsMapping.roomInPms)
                ?.extra || null,
          extra_child: occupancyPricing.additionalChildPrice || null,
          discount_per_person: occupancyPricing.additionalAdultPrice ?? 0,
          ignore_upload_count: pricingSetup.dontCountUploads || false,
          pms_price_applicable_occupancy_id: pmsMapping.roomOccupancyForPricing || null,
          pms_rate_id:
            hotelPmsDataMap?.mapped_data
              .find((value) => value.id === pmsMapping.roomInPms)
              ?.rates.find((rate) => rate.id === pmsMapping.baseRateInPms)?.id || null,
          pms_room_id: pmsMapping.roomInPms || null,
          // deprecated field (used to be used for Beds24(old) PMS integration)
          use_prices_from: '0'
        }
      });

      if (occupancyPricing.occupancyDerivation) {
        await saveHotelPricePerOccupancyMutation({
          id: defaults.pmsMapping?.roomId,
          data: Object.values(occupancyPricing.occupancyDerivation).map(
            (item) =>
              ({
                occupancy: +item.occupancy,
                derivation: +item.derivation,
                room: +item.room
              } as HotelPricePerOccupancyRequest)
          )
        });
      }

      await refetchPricingSettings();

      if (!defaults.pmsMapping?.roomId) return;

      await updatePricingSettingsMutation(
        JSON.stringify({
          ...pricingSettings,
          hotel: {
            ...pricingSettings?.hotel,
            min_price: 0
          },
          rooms: {
            ...pricingSettings?.rooms,
            derived: {
              ...pricingSettings?.rooms?.derived,
              [defaults.pmsMapping?.roomId]: {
                name: pmsMapping.roomName || '',
                number_of_rooms: +(pmsMapping.numberOfRooms || 0),
                virtual_room_type: pmsMapping.virtualRoomType || false,
                number_of_beds_per_physical_room: pmsMapping.numberOfBedsPerPhysicalRoom
                  ? pmsMapping.virtualRoomType
                    ? +pmsMapping.numberOfBedsPerPhysicalRoom
                    : undefined
                  : undefined,
                variable_cost_per_room: 0
              }
            }
          },
          default: {
            ...pricingSettings?.default,
            [defaults.pmsMapping?.roomId]: {
              ...pricingSettings?.default?.[defaults.pmsMapping?.roomId],
              avg_price: +(pricingSetup.basePrice || 0),
              max_price: features?.includes(6)
                ? +(pricingSetup.defaultMaxPrice || 0)
                : pricingSettings?.default?.[defaults.pmsMapping?.roomId]?.max_price ?? undefined,
              min_price: features?.includes(6)
                ? +(pricingSetup.defaultMinPrice || 0)
                : pricingSettings?.default?.[defaults.pmsMapping?.roomId]?.min_price ?? undefined,
              adjustment_to_reference_room: +(pricingSetup.derivationFromReferenceRoom || 0)
            }
          }
        })
      );
    };

    const warningsToProcess: Promise<void>[] = [];

    const addWarningIfNeeded = ({
      condition,
      warningMessage,
      confirmationMessage,
      title
    }: {
      condition: boolean;
      warningMessage: string;
      confirmationMessage: string;
      title: string;
    }) => {
      if (condition) {
        warningsToProcess.push(
          createWarning({
            message: t(warningMessage),
            ignore: false,
            ignoreLabel: t('Cancel') as string,
            dismissLabel: t(confirmationMessage) as string,
            title: t(title) as string
          })
        );
      }
    };

    // Check if the base price is more than 110% or less than 90% of the default base price.
    // If this is true, add a warning message. This covers the case when the price is outside the range of 90% to 110% of the default base price.
    addWarningIfNeeded({
      condition:
        pricingSetup.basePrice > (defaults.pricingSetup?.basePrice || 0) * 1.1 ||
        pricingSetup.basePrice < (defaults.pricingSetup?.basePrice || 0) * 0.9,
      warningMessage:
        'We recommend NOT changing the base price unless you are totally sure you know how it affects prices. Changing the base price has the largest effect on your pricing of any setting. Message us if you are not sure, or just use the monthly settings to change shorter periods.',
      confirmationMessage: 'Make the Change to Base Price',
      title: 'Base Price'
    });

    // Check if the base price is within the range of 90% to 110% of the default base price, inclusive.
    // If this is true, add a different warning message. This covers the case when the price is within the range of 90% to 110% of the default base price.
    addWarningIfNeeded({
      condition:
        pricingSetup.basePrice !== (defaults.pricingSetup?.basePrice || 0) &&
        pricingSetup.basePrice <= (defaults.pricingSetup?.basePrice || 0) * 1.1 &&
        pricingSetup.basePrice >= (defaults.pricingSetup?.basePrice || 0) * 0.9 &&
        (pricingSetup.basePrice >= (defaults.pricingSetup?.basePrice || 0) * 1.02 ||
          pricingSetup.basePrice <= (defaults.pricingSetup?.basePrice || 0) * 0.98),
      warningMessage:
        'This change can move all of your pricing.  Please only use this if you are completely sure of the effect. Message us if you need help.',
      confirmationMessage: 'I want to do it anyway',
      title: 'Base Price'
    });

    if (features?.includes(6)) {
      addWarningIfNeeded({
        condition:
          pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.5 &&
          pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.3,
        warningMessage:
          'This is unusually low for a minimum price - normally we would set this to around 60% of the base price',
        confirmationMessage: 'Confirm',
        title: 'Default Minimum Price'
      });

      addWarningIfNeeded({
        condition: pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.3,
        warningMessage:
          'We would normally recommend not setting a minimum price this much lower than your base price. Are you sure?',
        confirmationMessage: 'Confirm',
        title: 'Default Minimum Price'
      });

      addWarningIfNeeded({
        condition:
          pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.8 &&
          pricingSetup.defaultMinPrice < (pricingSetup.basePrice || 0) * 0.9,
        warningMessage:
          'This is unusually high for a minimum price, compared to your base price. We would recommend going lower to allow more bookings in low-demand times.',
        confirmationMessage: 'Confirm',
        title: 'Default Minimum Price'
      });

      addWarningIfNeeded({
        condition: pricingSetup.defaultMinPrice > (pricingSetup.basePrice || 0) * 0.9,
        warningMessage:
          'We would strongly recommend a lower minimum price when you have this base price set. Are you sure?',
        confirmationMessage: 'Confirm',
        title: 'Default Minimum Price'
      });

      addWarningIfNeeded({
        condition: pricingSetup.defaultMaxPrice < (pricingSetup.basePrice || 0) * 1.2,
        warningMessage:
          'We would strongly recommend a higher maximum price when you have this base price set. Are you sure?',
        confirmationMessage: 'Confirm',
        title: 'Default Maximum Price'
      });

      addWarningIfNeeded({
        condition:
          pricingSetup.defaultMaxPrice < (pricingSetup.basePrice || 0) * 1.4 &&
          pricingSetup.defaultMaxPrice >= (pricingSetup.basePrice || 0) * 1.2,
        warningMessage:
          'This is unusually low for a maximum price, compared to your base price. We would recommend going higher to allow more profit in busy times.',
        confirmationMessage: 'Confirm',
        title: 'Default Maximum Price'
      });

      addWarningIfNeeded({
        condition: pricingSetup.defaultMaxPrice > (pricingSetup.basePrice || 0) * 10,
        warningMessage:
          'This is unusually high for a maximum price and may lead to unhappy clients. Could you try a lower maximum but put a Min Stay restriction in instead?',
        confirmationMessage: 'Confirm',
        title: 'Default Maximum Price'
      });
    }

    const processWarnings = async () => {
      if (warningsToProcess.length > 0) {
        try {
          await warningsToProcess.shift();
          processWarnings();
        } catch (error) {
          console.log('User cancelled warning');
        }
      } else {
        submitFn();
      }
    };

    await processWarnings();
  };

  return (
    <FormProvider {...methods}>
      <form
        key="edit-room"
        className="relative h-full"
        onSubmit={methods.handleSubmit(handleSubmit)}>
        <FormWrapper>
          <FormHeader>
            <Typography variant="h5" element="h3" color="darkGrey">
              {t('Edit')} {defaults.pmsMapping?.roomName}
            </Typography>

            <div className="mt-3">
              <TabBar
                onTabClick={setActiveTab}
                options={[
                  { label: t('Pricing Setup'), value: Tabs.PricingSetup },
                  ...(isOccupancyBasedPricing ||
                  methods.watch('pmsMapping.priceType') === 'occupancy_based'
                    ? [{ label: t('Occupancy Pricing'), value: Tabs.OccupancyPricing }]
                    : []),
                  {
                    label: hotelDetails?.is_channel_manager
                      ? t('Channel Manager Mapping')
                      : t('PMS Mapping'),
                    value: Tabs.PMSMapping
                  },
                  ...(isDerivedRates
                    ? [{ label: t('Derived Rates'), value: Tabs.DerivedRates }]
                    : [])
                ]}
                activeTab={activeTab}
              />
            </div>
          </FormHeader>

          <FormContent>
            {CurrentTabContentComponent && (
              <CurrentTabContentComponent
                baseRateInPmsOptions={
                  baseRateInPmsOptions(methods.watch('pmsMapping.roomInPms') || 0) || []
                }
                roomInPmsOptions={roomInPmsOptions || []}
                referenceRoomBasePrice={referenceRoomBasePrice}
                roomOccupancyPricingOptions={
                  roomOccupancyPricingOptions(methods.watch('pmsMapping.roomInPms') || 0) || []
                }
              />
            )}
          </FormContent>

          <FormFooter>
            <Button type="button" intent="text" className="text-darkGrey" onClick={onDrawerClose}>
              {t('Cancel')}
            </Button>
            {activeTab === Tabs.PricingSetup ? (
              <>
                {view === 'admin' ? (
                  <Button
                    type="button"
                    intent="outline"
                    onClick={() => setCurrentAverageModalOpen(true)}>
                    {t('Get Current Average')}
                  </Button>
                ) : null}
                {features?.includes(6) ? (
                  <Button
                    type="button"
                    intent="outline"
                    onClick={() => handleDerivedMinMaxCalculation()}>
                    {t('Derived Min/Max')}
                  </Button>
                ) : null}
              </>
            ) : null}
            <Button
              intent="primary"
              type="submit"
              isLoading={
                isSaveDerivedRates ||
                isSaveHotelPricePerOccupancy ||
                isUpdateHotelRoom ||
                isUpdatePricingSettings
              }
              isSuccess={isFormSuccess}>
              {t('Save')}
            </Button>
          </FormFooter>
        </FormWrapper>
      </form>

      <Modal
        className="z-[9999]"
        open={currentAverageModalOpen}
        size="full"
        onCloseModal={() => setCurrentAverageModalOpen(false)}
        onClose={handleCloseCurrentAverageModal}
        cancelText="Apply Base Price"
        okText="Apply Base, Min & Max Price">
        <CurrentAverageChart roomId={defaults.pmsMapping?.roomId} />
      </Modal>
    </FormProvider>
  );
};
