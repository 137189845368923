import { Icon } from '@common/components/foundations/icons';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styles from '@common/components/atoms/Select/SelectDropdown.module.css';
import { Typography } from '@common/components/foundations/Typography';
import { find, isEmpty, result } from 'lodash-es';
import { DefaultTFuncReturn, t } from 'i18next';
import { cn } from '@common/utils/cn';

interface SelectDropdownProps<TValue = unknown> {
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;

  value?: TValue;

  onChange?: (value: TValue) => void;
  hint?: string;
  fullWidth?: boolean;
  options?: {
    label: string;
    value: TValue;
  }[];
  global?: boolean;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  inputClassName?: string;
  background?: 'grey' | 'white';
  customOptions?: React.ReactNode;
  error?: boolean;
  disableOptionsValue?: TValue[];
}

export const SelectDropdown = <T,>({
  prefixIcon,
  suffixIcon,
  value: initialValue,
  options,
  fullWidth,
  hint,
  onChange,
  global,
  name,
  placeholder,
  disabled,
  inputClassName,
  background = 'white',
  customOptions,
  disableOptionsValue,
  error
}: SelectDropdownProps<T>) => {
  const [value, setValue] = useState(initialValue);
  const form = useFormContext();

  const selectedOption = useMemo(() => {
    return (
      options?.find((option) => option.value === value) || {
        label: placeholder || 'Select',
        value: ''
      }
    );
  }, [options, value]);

  const handleOnChange: SelectDropdownProps<T>['onChange'] = (value) => {
    setValue(value);

    if (global) {
      form.setValue(name, value, { shouldDirty: true });
    }

    onChange?.(value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Listbox value={value} onChange={handleOnChange} disabled={disabled}>
      {({ open, value }) => (
        <div className={`relative ${fullWidth ? 'w-full' : 'max-w-[260px]'}`}>
          {hint && <Typography className="hint mb-1 text-meta-1 text-grey">{hint}</Typography>}
          <Listbox.Button
            className={classNames(
              styles.input,
              open && styles.dropdownOpened,
              background === 'grey' && styles.inputGrey,
              inputClassName,
              hint ? 'mt-1' : null,
              'group',
              error ? 'ring-error' : null
            )}>
            <span className="flex items-center gap-x-2 truncate">
              {prefixIcon}
              <span
                className={classNames(
                  !value &&
                    'py-1 text-[13px] leading-[15px] -tracking-[0.02rem] text-grey md:text-[15px]',
                  'leading-5'
                )}>
                {selectedOption?.label}
              </span>
            </span>
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="pointer-events-none">
                <span
                  className={cn(
                    background === 'white'
                      ? 'bg-lightGrey group-hover:bg-grey-reduced'
                      : 'bg-white group-hover:bg-grey-reduced',
                    'inline-flex select-none items-center justify-center gap-x-1 whitespace-nowrap rounded-[200px] p-1 font-sans transition-colors disabled:cursor-not-allowed disabled:text-opacity-30'
                  )}>
                  <Icon.ChevronDown className="h-3 w-3" aria-hidden="true" />
                </span>
              </span>
              {suffixIcon && <span className="ml-3">{suffixIcon}</span>}
            </div>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className={styles.dropdownOptionsWrapper}>
              <Listbox.Options className={styles.dropdownOptions}>
                {options?.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    title={option.label}
                    disabled={disableOptionsValue?.includes(option.value)}
                    className={
                      'relative cursor-pointer select-none py-2 pl-8 pr-4 text-grey hover:bg-lightGrey '
                    }
                    value={option.value}>
                    {({ selected }) => (
                      <>
                        <Typography
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate leading-tight',
                            disableOptionsValue?.includes(option.value)
                              ? 'cursor-not-allowed text-grey'
                              : ''
                          )}>
                          {option.label}
                        </Typography>

                        {selected ? (
                          <Typography
                            className={classNames(
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}>
                            <Icon.CheckList className="h-5 w-5" aria-hidden="true" />
                          </Typography>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
                {customOptions ? <div className="w-full p-2">{customOptions}</div> : null}
              </Listbox.Options>
            </div>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

interface SelectDropdownBaseOption {
  id?: string;
  value: unknown;
  label: string;
}

interface SelectDropdownInputProps<T> {
  fullWidth?: boolean;
  background?: 'grey' | 'white';
  hint?: string;
  inputClassName?: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  options?: T[];
  name: string;
  fallbackLabel?: string | DefaultTFuncReturn;
  valueKey?: string;
}

export function SelectDropdownInput<T extends SelectDropdownBaseOption>({
  background = 'white',
  fullWidth = false,
  hint,
  inputClassName,
  prefixIcon,
  suffixIcon,
  options,
  name,
  fallbackLabel,
  valueKey = 'value'
}: SelectDropdownInputProps<T>) {
  const { control, watch, formState } = useFormContext();
  const { field } = useController({ name, control });
  const value = watch(name);
  const isDisabled = formState?.isSubmitting || !isEmpty(formState?.errors);

  return (
    <Listbox value={value} onChange={(val: string) => field.onChange(val)} disabled={isDisabled}>
      {({ open, value }) => (
        <div className={cn('relative', fullWidth ? 'w-full' : 'max-w-[260px]')}>
          {hint && <Typography className="hint mb-1 text-meta-1 text-grey">{hint}</Typography>}
          <Listbox.Button
            className={classNames(
              styles.input,
              open && styles.dropdownOpened,
              background === 'grey' && styles.inputGrey,
              inputClassName,
              hint ? 'mt-1' : null,
              'group',
              isDisabled ? 'cursor-not-allowed' : 'cursor-default'
            )}>
            <span className="flex items-center gap-x-2 truncate">
              {prefixIcon}
              <span
                className={classNames(
                  !value &&
                    'py-1 text-[13px] leading-[15px] -tracking-[0.02rem] text-grey md:text-[15px]',
                  'leading-5'
                )}>
                {find(options, [valueKey, value])?.label || fallbackLabel || t('Select')}
              </span>
            </span>
            <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="pointer-events-none">
                <span
                  className={cn(
                    background === 'white'
                      ? 'bg-lightGrey group-hover:bg-grey-reduced'
                      : 'bg-white group-hover:bg-grey-reduced',
                    'inline-flex select-none items-center justify-center gap-x-1 whitespace-nowrap rounded-[200px] p-1 font-sans transition-colors disabled:cursor-not-allowed disabled:text-opacity-30'
                  )}>
                  <Icon.ChevronDown className="h-3 w-3" aria-hidden="true" />
                </span>
              </span>
              {suffixIcon && <span className="ml-3">{suffixIcon}</span>}
            </div>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className={styles.dropdownOptionsWrapper}>
              <Listbox.Options className={styles.dropdownOptions}>
                {options?.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    title={option.label}
                    className={
                      'relative cursor-pointer select-none py-2 pl-8 pr-4 text-grey hover:bg-lightGrey'
                    }
                    value={result(option, `${valueKey}`)}>
                    {({ selected }) => (
                      <>
                        <Typography
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate leading-tight'
                          )}>
                          {option.label}
                        </Typography>

                        {selected ? (
                          <Typography
                            className={classNames(
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}>
                            <Icon.CheckList className="h-5 w-5" aria-hidden="true" />
                          </Typography>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
