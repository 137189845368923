import { Checkbox } from '@mantine/core';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { LANGUAGES } from '@common/constants/language';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import {
  useEnableIframe,
  useEnableIframeStatus
} from '@pages/Client/Account/Integrations/hooks/useEnableIframe';
import { useOwnerDetails } from '@pages/Client/Account/User/hooks/useUser';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const schema = z.object({
  iframe_token: z.string().trim().min(1, { message: 'Required' }),
  iframe_language: z.string().trim().min(1, { message: 'Required' }).nullable().optional(),
  enable: z.boolean().optional()
});

export const EnableIframeModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelIframeId } = useAuthStore();
  const { enableIframe } = useEnableIframe();
  const { ownerDetails } = useOwnerDetails();
  const { iframe, isLoading } = useEnableIframeStatus();

  const { handleSubmit, control, formState, setValue } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (!hotelIframeId) return;
    if (iframe && !isLoading) {
      setValue('iframe_language', iframe?.data?.language || ownerDetails?.language);
      setValue('enable', iframe?.data?.enabled);
      setValue('iframe_token', hotelIframeId);
    }
  }, [iframe, isLoading]);

  const onSubmit = (data: any) => {
    enableIframe(data);
  };

  return (
    <Modal
      onClose={onClose}
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!formState.isValid}
      okText={`${t('Save')}`}
      size={'xl'}
      open={isOpen}
      children={
        <div className="min-w-[320px]">
          <div className="flex flex-col items-start gap-4">
            <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
              {t(`Manage Iframe`)}
            </Typography>
            <div className="flex w-full flex-col gap-4">
              <div>
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Language')}*
                </Typography>
                <Controller
                  control={control}
                  name="iframe_language"
                  render={({ field: { value, name, onChange } }) => (
                    <SelectDropdown
                      fullWidth
                      placeholder="Select language"
                      name={name}
                      key={name}
                      value={value || ownerDetails?.language}
                      options={LANGUAGES}
                      onChange={(value) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
                <div className="mt-1">
                  {formState.errors && (
                    <div className="flex items-center gap-2 text-error">
                      {formState.errors.iframe_language ? (
                        <Icon.WarningOutline className="w-4" />
                      ) : (
                        ''
                      )}
                      <Typography element="span" color="error" variant="meta-2">
                        {formState.errors.iframe_language
                          ? formState.errors?.iframe_language?.message?.toString()
                          : ''}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <Controller
                  control={control}
                  name="enable"
                  render={({ field: { value, onChange, name } }) => (
                    <Checkbox
                      label={t(`Enable Iframe`)}
                      checked={value}
                      onChange={onChange}
                      name={name}
                    />
                  )}
                />
                <div className="mt-1">
                  {formState.errors && (
                    <div className="flex items-center gap-2 text-error">
                      {formState.errors.enable ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {formState.errors.enable
                          ? formState.errors?.enable?.message?.toString()
                          : ''}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
