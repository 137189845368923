import { ResultErrorTicket } from '@common/api/errors/types';
import { Badge } from '@common/components/atoms/Badge';

import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { DateRangePicker } from '@common/components/molecules/DateRangePicker/DateRangePicker';
import { Modal } from '@common/components/molecules/Modal';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import { Table } from '@common/components/molecules/Table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { API_DATE_FORMAT } from '@common/constants/date';
import { DEVELOPER_OPTIONS } from '@common/constants/developer-options';
import { pmsList } from '@common/constants/pmsList';
import { useModal } from '@common/hooks/useModal';
import { browserTimezone } from '@common/utils/browserTimezone';

import { TICKET_STATUS } from '@pages/Admin/ErrorTickets/common/constants/ticket-status';
import { getBadgeColor, getStatus } from '@pages/Admin/ErrorTickets/common/utils/status';
import { UpdateErrorTicketModal } from '@pages/Admin/ErrorTickets/components/UpdateErrorTicketModal';
import { UpdateMultipleErrorTicketsModal } from '@pages/Admin/ErrorTickets/components/UpdateMultipleErrorTicketsModal';
import { useErrorTicketsTags } from '@pages/Admin/ErrorTickets/hooks/useErrorTicketsTags';
import { useErrorTicketList } from '@pages/Admin/ErrorTickets/hooks/useErrorsTickets';
import { useUpdateMultipleTicketStore } from '@pages/Admin/ErrorTickets/store/updateMultiple';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { capitalize } from 'lodash-es';
import { ActionIcon, Button, Checkbox } from '@mantine/core';
import { DateRange } from 'react-day-picker';
import { isNil } from 'lodash-es';

dayjs.extend(utc);
dayjs.extend(timezone);

const schema = z.object({
  page: z.number(),
  ticket_status: z.number().optional(),
  ticket_type: z.number().optional(),
  ticket_tag: z.number().optional(),
  search: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  developer: z.string().optional(),
  provider: z.number().optional(),
  ordering: z.string().optional()
});

interface HeaderProps {
  id: string;
  desc: boolean;
}
type Params = z.infer<typeof schema>;

const filterEmptyValues = (params: Params): Params => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_key, value]) => value !== null && value !== undefined && value !== null
    )
  ) as Params;
};

// Main Function
export const TicketsPage = ({ ticketType }: { ticketType: number }) => {
  const defaultValues = {
    page: 1,
    ticket_status: 1,
    ticket_type: ticketType,
    search: undefined,
    start_date: undefined,
    end_date: undefined,
    developer: undefined,
    ticket_tag: undefined,
    provider: undefined,
    ordering: undefined
  };

  const { watch, reset, control, getValues, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });
  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, filterEmptyValues, defaultValues]
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
  };

  const [selectionTicket, setSelectionTicket] = useState<ResultErrorTicket>();
  const [selectedTickets, setSelectedTickets] = useState<number[]>([]);
  const [starDateFilter, setStartDateFilter] = useState<string | undefined>();
  const [endDateFilter, setEndDateFilter] = useState<string | undefined>();

  const {
    isOpen: filterDateOpen,
    openModal: filterDateClick,
    closeModal: filterDateClose
  } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const {
    isOpen: editMultipleOpen,
    openModal: editMultipleClick,
    closeModal: editMultipleClose
  } = useModal();
  const { t } = useTranslation();

  const {
    errorTicketList,
    query: { isLoading, refetch, isFetching }
  } = useErrorTicketList(
    filterEmptyValues({
      page: watch('page'),
      search: getValues('search'),
      start_date: watch('start_date'),
      end_date: watch('end_date'),
      ticket_status: watch('ticket_status'),
      ticket_type: watch('ticket_type'),
      ticket_tag: watch('ticket_tag'),
      provider: watch('provider'),
      developer: watch('developer'),
      ordering: watch('ordering')
    })
  );
  const { errorTicketsTags } = useErrorTicketsTags({ ticket_type: ticketType });
  const { IsUpdateMultipleTicketSuccess, setUpdateMultipleTicketSuccess } =
    useUpdateMultipleTicketStore();

  const ROWS_PER_PAGE = 20;

  const startIndex = errorTicketList?.current
    ? (errorTicketList.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    errorTicketList?.current && errorTicketList.results
      ? startIndex + (errorTicketList?.results?.length || 0) - 1
      : 0;

  const handleNext = () => {
    if (!errorTicketList?.next) return;
    const params = new URLSearchParams(errorTicketList.next.split('?')[1]);
    const page = params.get('page');
    const search = params.get('search');
    const start_date = params.get('start_date');
    const end_date = params.get('end_date');
    const ticket_status = params.get('ticket_status');
    const ticket_type = params.get('ticket_type');
    const ticket_tag = params.get('ticket_tag');
    const provider = params.get('provider');
    const developer = params.get('developer');
    if (!page) return;

    reset(
      filterEmptyValues({
        page: parseInt(page),
        search: search ? search : undefined,
        start_date: start_date ? start_date : undefined,
        end_date: end_date ? end_date : undefined,
        ticket_status: ticket_status ? parseInt(ticket_status) : undefined,
        ticket_type: ticket_type ? parseInt(ticket_type) : undefined,
        ticket_tag: ticket_tag ? parseInt(ticket_tag) : undefined,
        provider: provider ? parseInt(provider) : undefined,
        developer: developer ? developer : undefined
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: previousPage
      })
    );
  };

  const handleCheckboxChange = (isChecked: boolean, id: number) => {
    // If the checkbox is checked and the ID is not already in the array, add it
    if (isChecked && !selectedTickets.includes(id)) {
      setSelectedTickets((prev) => [...prev, id]);
    }
    // If the checkbox is unchecked and the ID is in the array, remove it
    else if (!isChecked && selectedTickets.includes(id)) {
      setSelectedTickets((prev) => prev.filter((ticketId) => ticketId !== id));
    }
  };

  useEffect(() => {
    if (IsUpdateMultipleTicketSuccess) {
      setTimeout(() => {
        setSelectedTickets([]);
        setUpdateMultipleTicketSuccess(false);
        refetch();
      }, 800);
    }
  }, [IsUpdateMultipleTicketSuccess]);

  const columns = [
    {
      id: 'select',
      header: ({ table }: any) => {
        return (
          <Checkbox
            checked={
              (table?.getIsAllPageRowsSelected() || table?.getIsSomePageRowsSelected()) &&
              selectedTickets?.length > 0
            }
            onChange={(e) => {
              const value = e.target.checked;
              if (value) {
                const allRowIds = table?.options?.data?.map((r: any) => r.id);
                setSelectedTickets(allRowIds);
              } else {
                setSelectedTickets([]);
              }
              table.toggleAllPageRowsSelected(value);
            }}
            indeterminate={table.getIsSomePageRowsSelected() && selectedTickets?.length > 0}
            aria-label="Select all"
          />
        );
      },

      cell: ({ row }: any) => {
        return (
          <div>
            <Checkbox
              checked={row?.getIsSelected() && selectedTickets?.includes(row?.original?.id)}
              onChange={(e) => {
                const value = e.target.checked;
                handleCheckboxChange(value, row?.original?.id);
                row?.toggleSelected(value);
              }}
              onClick={editClose}
              aria-label="Select row"
            />
          </div>
        );
      }
    },
    {
      header: 'ID',
      accessorKey: 'hotel',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Hotel',
      accessorKey: 'hotel_name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Tag',
      accessorKey: 'tag',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Count',
      accessorKey: 'count',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'PMS/CM',
      accessorKey: 'pms_name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey" className="capitalize">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Responsible Person',
      accessorKey: 'responsible_person',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Reseller',
      accessorKey: 'reseller',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Developer',
      accessorKey: 'developer_name',
      cell: (row: any) => {
        const name = row.getValue() as string;
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {capitalize(name)}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Date Modified',
      accessorKey: 'modified_at',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {dayjs.utc(row?.getValue()).tz(browserTimezone).format('YYYY-MM-DD HH:mm')}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Badge background={`${getBadgeColor(row?.getValue())}`}>
              {getStatus(row?.getValue())}
            </Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Notes',
      accessorKey: 'note',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <div>{row?.getValue() ? <Icon.Comment color="" /> : '-'}</div>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Create Ticket',
      accessorKey: 'actions',
      cell: (row: any) => {
        const data = row?.row?.original;
        const jiraTicketUrl =
          'https://roompricegenie.atlassian.net/secure/CreateIssueDetails!init.jspa?issuetype=10009&pid=10015' +
          '&summary=Hotel+Id%3A+' +
          encodeURIComponent(data?.hotel) +
          '+-+' +
          encodeURIComponent(data?.pms_name) +
          '+-+' +
          '&description=' +
          'Hotel+ID%3A+' +
          encodeURIComponent(data?.hotel) +
          '%0AHotel+Name%3A+' +
          encodeURIComponent(data?.hotel_name) +
          '%0AResponsible+Person%3A+' +
          encodeURIComponent(data?.responsible_person) +
          '%0AReseller%3A+' +
          encodeURIComponent(data?.reseller) +
          '%0APMS%2FCM+Name%3A+' +
          encodeURIComponent(data?.pms_name) +
          `&labels=${(data?.pms_name as string)
            ?.split(' ')
            ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            ?.join('')}`;
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <ActionIcon
                    onClick={() => {
                      window.open(jiraTicketUrl, '_blank');
                      setTimeout(() => {
                        editClose();
                      }, 500);
                    }}>
                    <Icon.Add />
                  </ActionIcon>
                </TooltipTrigger>
                <TooltipContent side="top" className="bg-white text-darkGrey">
                  Create Jira Ticket
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const headerTitles: { [key: string]: string } = {
    '1': 'Support Critical Error Tickets',
    '2': 'Support Other Error Tickets',
    '3': 'Dev Critical Error Tickets',
    '4': 'Dashboard Critical Error Tickets',
    '5': 'Dashboard Other Error Tickets'
  };

  const getHeaderTitle = (type: number) => {
    return headerTitles[type.toString()] || 'Support Critical Error Tickets'; // Default value
  };

  const handleDateRangeChange = (dates?: DateRange) => {
    const { from, to } = dates ?? {};
    if (isNil(from) || isNil(to)) return;
    setStartDateFilter(dayjs(from).format(API_DATE_FORMAT));
    setEndDateFilter(dayjs(to).format(API_DATE_FORMAT));
  };

  const TableHeader = () => {
    return (
      <>
        {selectedTickets.length > 1 ? (
          <div className="flex w-full justify-between py-3">
            <Typography variant="h6" element="h6" className="font-medium" color="darkGrey">
              {selectedTickets.length} tickets selected
            </Typography>
          </div>
        ) : (
          <div className="flex w-full justify-between py-3">
            <Typography variant="h6" element="h6" className="font-medium" color="darkGrey">
              {getHeaderTitle(ticketType)}
            </Typography>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <Table
        isHover
        isRowClickable
        columns={columns}
        skeletonCount={20}
        isFetching={isLoading || isFetching}
        data={!isLoading ? (errorTicketList?.results as any) : []}
        headerComponent={<TableHeader />}
        next={handleNext}
        isNext={!!errorTicketList?.next}
        previous={handlePrevious}
        isPrevious={watch('page') > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={errorTicketList?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        manualSorting={true}
        onSortingChange={(value) => {
          const values = value as HeaderProps[];
          if (
            values[0].id === 'hotel' ||
            values[0].id === 'tag' ||
            values[0].id === 'count' ||
            values[0].id === 'status' ||
            values[0].id === 'actions' ||
            values[0].id === 'modified_at' ||
            values[0].id === 'developer_name'
          )
            return;
          setValue('ordering', values[0].id);
        }}
        sorting={[
          {
            id: 'hotel_name',
            desc: watch('ordering') === 'hotel_name'
          },
          {
            id: 'pms_name',
            desc: watch('ordering') === 'pms_name'
          },
          {
            id: 'responsible_person',
            desc: watch('ordering') === 'responsible_person'
          },
          {
            id: 'reseller',
            desc: watch('ordering') === 'reseller'
          }
        ]}
        onClickRow={(row) => {
          if (row.column.id === 'select') return;
          editClick();
          setSelectionTicket(row?.row?.original);
        }}
        actionComponents={
          selectedTickets.length > 1 ? (
            <div className="flex items-center justify-center gap-5">
              <Button
                onClick={() => {
                  editMultipleClick();
                }}>
                Update
              </Button>
            </div>
          ) : (
            <div className="flex items-center justify-center gap-5">
              <div>
                <Controller
                  name="search"
                  control={control}
                  render={({ field: { value, name, onChange } }) => (
                    <Input
                      placeholder="Search"
                      background="grey"
                      name={name}
                      id={name}
                      type="text"
                      value={value}
                      onChange={(e) => {
                        handleSearch(e.target.value);
                        onChange(e);
                      }}
                      onClear={() => {
                        reset(defaultValues);
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <RPGPopover>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <RPGPopover.Button as={ActionIcon}>
                          <Icon.FilterList />
                        </RPGPopover.Button>
                      </TooltipTrigger>
                      <TooltipContent side="top" className="bg-white text-darkGrey">
                        Filter
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <RPGPopover.Panel>
                    <div className="mb-2 flex items-center gap-3">
                      <Typography className="text-meta-1-medium" color="darkGrey">
                        {t('Filters')}
                      </Typography>
                      <Button type="button" size="compact-xs" onClick={() => reset(defaultValues)}>
                        {t('Reset')}
                      </Button>
                    </div>
                    <div className="grid grid-cols-1 gap-2">
                      <div>
                        <Controller
                          control={control}
                          name="ticket_tag"
                          render={({ field: { value, name, onChange } }) => (
                            <SelectDropdown
                              fullWidth
                              hint="Ticket Tag"
                              placeholder="Select Ticket Tag"
                              name={name}
                              key={name}
                              value={value || ''}
                              options={errorTicketsTags || []}
                              onChange={(value) => {
                                onChange(value);
                                setValue('page', 1);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Controller
                          control={control}
                          name="ticket_status"
                          render={({ field: { value, name, onChange } }) => (
                            <SelectDropdown
                              fullWidth
                              hint="Ticket Status"
                              placeholder="Select Ticket Status"
                              name={name}
                              key={name}
                              value={value || ''}
                              options={TICKET_STATUS}
                              onChange={(value) => {
                                onChange(value);
                                setValue('page', 1);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Controller
                          control={control}
                          name="developer"
                          render={({ field: { value, name, onChange } }) => (
                            <SelectDropdown
                              fullWidth
                              hint="Developer"
                              placeholder="Select Developer"
                              name={name}
                              key={name}
                              value={value || ''}
                              options={DEVELOPER_OPTIONS}
                              onChange={(value) => {
                                onChange(value);
                                setValue('page', 1);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Controller
                          control={control}
                          name="provider"
                          render={({ field: { value, name, onChange } }) => (
                            <SelectDropdown
                              fullWidth
                              hint="PMS/CM"
                              background="grey"
                              name={name}
                              key={name}
                              value={value || ''}
                              options={[
                                { label: 'All', value: '' },
                                ...pmsList.map((item) => ({
                                  label: item.label,
                                  value: item.value
                                }))
                              ]}
                              onChange={(value) => {
                                onChange(value);
                                setValue('page', 1);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </RPGPopover.Panel>
                </RPGPopover>
              </div>
              <div>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <ActionIcon onClick={filterDateClick}>
                        <Icon.Calendar />
                      </ActionIcon>
                    </TooltipTrigger>
                    <TooltipContent side="top" className="bg-white text-darkGrey">
                      Filter by date
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          )
        }
      />
      <Modal
        onClick={() => {
          setValue('start_date', dayjs(starDateFilter).format(API_DATE_FORMAT));
          setValue('end_date', dayjs(endDateFilter).format(API_DATE_FORMAT));
          setValue('page', 1);
          refetch();
        }}
        open={filterDateOpen}
        okText="Search"
        onClose={filterDateClose}
        size={'lg'}
        children={
          <div className="flex flex-col items-start gap-4">
            <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
              Filter By Date
            </Typography>
            <div className="mt-4 flex justify-between gap-4">
              <DateRangePicker
                allowPastDates
                startDate={dayjs(starDateFilter).toDate()}
                endDate={dayjs(endDateFilter).toDate()}
                onDateChange={(date) => handleDateRangeChange(date)}
              />
            </div>
          </div>
        }
      />
      <UpdateErrorTicketModal
        data={selectionTicket as ResultErrorTicket}
        isOpen={editOpen}
        onClose={editClose}
      />
      <UpdateMultipleErrorTicketsModal
        isOpen={editMultipleOpen}
        onClose={editMultipleClose}
        ticketsIds={selectedTickets}
      />
    </>
  );
};
