import { Navigate, RouteObject } from 'react-router-dom';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import CalendarPricingPage from '@pages/Client/Calendar/pages/Pricing';
import CalendarOccupancyPage from '@pages/Client/Calendar/pages/Occupancy';
import CalendarPickupPage from '@pages/Client/Calendar/pages/Pickup';
import CalendarRevenuePage from '@pages/Client/Calendar/pages/Revenue';

export const calendarRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="pricing" replace /> },
  {
    path: 'pricing',
    element: (
      <SecureRoute>
        <CalendarPricingPage />
      </SecureRoute>
    )
  },
  {
    path: 'occupancy',
    element: (
      <SecureRoute>
        <CalendarOccupancyPage />
      </SecureRoute>
    )
  },
  {
    path: 'pickup',
    element: (
      <SecureRoute feature={30}>
        <CalendarPickupPage />
      </SecureRoute>
    )
  },
  {
    path: 'revenue',
    element: (
      <SecureRoute feature={25}>
        <CalendarRevenuePage />
      </SecureRoute>
    )
  }
];
