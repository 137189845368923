import { Switcher } from '@common/components/atoms/Switcher';
import {
  useSetHotelState,
  useSetToTestMode
} from '@pages/Client/Account/Subscription/hooks/usePayment';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

export const DemoHotelSwitcher = () => {
  const setToTestHotel = useSetToTestMode();
  const setDemoHotel = useSetHotelState();
  const { hotelDetails: hotel } = useHotelDetails();

  return (
    <div className="flex items-end justify-between">
      <div>
        <Switcher
          label="Demo Hotel"
          size="large"
          checked={hotel?.state === 8}
          onChange={(value) => {
            if (value) {
              setToTestHotel();
              setDemoHotel({
                state: 8
              });
            } else {
              setDemoHotel({
                state: 0
              });
            }
          }}
        />
      </div>
    </div>
  );
};
